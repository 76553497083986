import React from "react";
import {
    Input,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";

export default class IspCutsheetTableData {
    static generateHops = (siteGroup, hopType, parentLinkType) => {
        const data = [];
        const totalHops = {
            r2r: 5, e2e: 5, m2m: 5, mmr: 1
        };

        const colors = ["#e6f2ff", "#e6f7e0", "#f0f8ff", "#ffebf2", "#f3e3f2"];
        const mapHopTypeToLabel = {
            e2e: "Lever",
            r2r: "Router",
            m2m: "Mux",
            mmr: "MMR"
        };

        for (let i = 1; i <= totalHops[hopType]; i += 1) {
            const color = colors[i - 1];

            const hop = {
                label: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - PP ${i} ${mapHopTypeToLabel[hopType]}`,
                labelA: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} Room A ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - Patch Panel ${i} Room A ${mapHopTypeToLabel[hopType]}`,
                labelZ: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} Room Z ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - Patch Panel ${i} Room Z ${mapHopTypeToLabel[hopType]}`,
                labelPort: `Site ${siteGroup.toUpperCase()} - Port`,
                id_pp_a: `${siteGroup}_hop_${i}_isp_panel_a_location_${parentLinkType}`,
                id_pp_z: `${siteGroup}_hop_${i}_isp_panel_z_location_${parentLinkType}`,
                id_port_a: `${siteGroup}_hop_${i}_isp_panel_a_port_${parentLinkType}`,
                id_port_z: `${siteGroup}_hop_${i}_isp_panel_z_port_${parentLinkType}`,
                id_pp_a_error: `${siteGroup}_hop_${i}_isp_panel_a_location_error_${parentLinkType}`,
                id_port_a_error: `${siteGroup}_hop_${i}_isp_panel_a_port_error_${parentLinkType}`,
                id_port_z_error: `${siteGroup}_hop_${i}_isp_panel_z_port_error_${parentLinkType}`,
                siteGroup,
                value: `${siteGroup}_hop_${i}_${parentLinkType}`,
                id: `${siteGroup}_hop_${i}_${parentLinkType}`,
                color
            };

            data.push(hop);
        }

        return data;
    };

    static LINE_COLUMN_DEFINITIONS = [
        // First two should be sticky (I would recommend to make them collapsable)
        {
            id: "a_line_dwdm_device",
            header: "a_line_dwdm_device",
            downloadableValue: item => item.a_line_dwdm_device,
            downloadableColumnHeader: "a_line_dwdm_device",
            minWidth: 200,
            cell: item => item.a_line_dwdm_device,
            isRowHeader: true
        },
        {
            id: "a_line_dwdm_port_1",
            header: "a_line_dwdm_port_1",
            downloadableValue: item => item.a_line_dwdm_port_1,
            downloadableColumnHeader: "a_line_dwdm_port_1",
            minWidth: 200,
            cell: item => item.a_line_dwdm_port_1,
            isRowHeader: true
        },
        {
            id: "a_line_dwdm_port_2",
            header: "a_line_dwdm_port_2",
            downloadableValue: item => item.a_line_dwdm_port_2,
            downloadableColumnHeader: "a_line_dwdm_port_2",
            minWidth: 200,
            cell: item => item.a_line_dwdm_port_2
        },
        // Editable (link to be updated MuxToMuxLinkInstanceId)
        {
            id: "a_line_dwdm_device_location",
            header: "a_line_dwdm_device_location",
            downloadableValue: item => item.a_line_dwdm_device_location,
            downloadableColumnHeader: "a_line_dwdm_device_location",
            minWidth: 276,
            cell: item => item.a_line_dwdm_device_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_line_dwdm_device_location)}
                        autoFocus
                        placeholder="Enter Line DWDM location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: "a_ops_dwdm_port",
            header: "a_ops_dwdm_port",
            downloadableValue: item => item.a_ops_dwdm_port,
            downloadableColumnHeader: "a_ops_dwdm_port",
            minWidth: 176,
            cell: item => item.a_ops_dwdm_port
        },
        {
            id: "a_rfts_filter",
            header: "a_rfts_filter",
            downloadableValue: item => item.a_rfts_filter,
            downloadableColumnHeader: "a_rfts_filter",
            minWidth: 176,
            cell: item => item.a_rfts_filter,
            isRowHeader: true
        },
        {
            id: "a_rfts_in_port_1",
            header: "a_rfts_in_port_1",
            downloadableValue: item => item.a_rfts_in_port_1,
            downloadableColumnHeader: "a_rfts_in_port_1",
            minWidth: 176,
            cell: item => item.a_rfts_in_port_1,
            isRowHeader: true
        },
        {
            id: "a_rfts_out_port_1",
            header: "a_rfts_out_port_1",
            downloadableValue: item => item.a_rfts_out_port_1,
            downloadableColumnHeader: "a_rfts_out_port_1",
            minWidth: 176,
            cell: item => item.a_rfts_out_port_1,
            isRowHeader: true
        },
        {
            id: "a_rfts_in_port_2",
            header: "a_rfts_in_port_2",
            downloadableValue: item => item.a_rfts_in_port_2,
            downloadableColumnHeader: "a_rfts_in_port_2",
            minWidth: 176,
            cell: item => item.a_rfts_in_port_2,
            isRowHeader: true
        },
        {
            id: "a_rfts_out_port_2",
            header: "a_rfts_out_port_2",
            downloadableValue: item => item.a_rfts_out_port_2,
            downloadableColumnHeader: "a_rfts_out_port_2",
            minWidth: 176,
            cell: item => item.a_rfts_out_port_2,
            isRowHeader: true
        },
        {
            id: "a_pluggable",
            header: "a_pluggable",
            downloadableValue: item => item.a_pluggable,
            downloadableColumnHeader: "a_pluggable",
            minWidth: 176,
            cell: item => item.a_pluggable,
            isRowHeader: true
        },
        {
            id: "z_pluggable",
            header: "z_pluggable",
            downloadableValue: item => item.z_pluggable,
            downloadableColumnHeader: "z_pluggable",
            minWidth: 176,
            cell: item => item.z_pluggable,
            isRowHeader: true
        },
        {
            id: "z_rfts_out_port_2",
            header: "z_rfts_out_port_2",
            downloadableValue: item => item.z_rfts_out_port_2,
            downloadableColumnHeader: "z_rfts_out_port_2",
            minWidth: 176,
            cell: item => item.z_rfts_out_port_2,
            isRowHeader: true
        },
        {
            id: "z_rfts_in_port_2",
            header: "z_rfts_in_port_2",
            downloadableValue: item => item.z_rfts_in_port_2,
            downloadableColumnHeader: "z_rfts_in_port_2",
            minWidth: 176,
            cell: item => item.z_rfts_in_port_2,
            isRowHeader: true
        },
        {
            id: "z_rfts_out_port_1",
            header: "z_rfts_out_port_1",
            downloadableValue: item => item.z_rfts_out_port_1,
            downloadableColumnHeader: "z_rfts_out_port_1",
            minWidth: 176,
            cell: item => item.z_rfts_out_port_1,
            isRowHeader: true
        },
        {
            id: "z_rfts_in_port_1",
            header: "z_rfts_in_port_1",
            downloadableValue: item => item.z_rfts_in_port_1,
            downloadableColumnHeader: "z_rfts_in_port_1",
            minWidth: 176,
            cell: item => item.z_rfts_in_port_1,
            isRowHeader: true
        },
        {
            id: "z_rfts_filter",
            header: "z_rfts_filter",
            downloadableValue: item => item.z_rfts_filter,
            downloadableColumnHeader: "z_rfts_filter",
            minWidth: 176,
            cell: item => item.z_rfts_filter,
            isRowHeader: true
        },
        {
            id: "z_ops_dwdm_port",
            header: "z_ops_dwdm_port",
            downloadableValue: item => item.z_ops_dwdm_port,
            downloadableColumnHeader: "z_ops_dwdm_port",
            minWidth: 176,
            cell: item => item.z_ops_dwdm_port
        },
        // Editable (link to be updated MuxToMuxLinkInstanceId)
        {
            id: "z_line_dwdm_device_location",
            header: "z_line_dwdm_device_location",
            downloadableValue: item => item.z_line_dwdm_device_location,
            downloadableColumnHeader: "z_line_dwdm_device_location",
            minWidth: 276,
            cell: item => item.z_line_dwdm_device_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_line_dwdm_device_location)}
                        autoFocus
                        placeholder="Enter DWDM location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: "z_line_dwdm_port_2",
            header: "z_line_dwdm_port_2",
            downloadableValue: item => item.z_line_dwdm_port_2,
            downloadableColumnHeader: "z_line_dwdm_port_2",
            minWidth: 176,
            cell: item => item.z_line_dwdm_port_2
        },
        {
            id: "z_line_dwdm_port_1",
            header: "z_line_dwdm_port_1",
            downloadableValue: item => item.z_line_dwdm_port_1,
            downloadableColumnHeader: "z_line_dwdm_port_1",
            minWidth: 176,
            cell: item => item.z_line_dwdm_port_1,
            isRowHeader: true
        },
        {
            id: "z_line_dwdm_device",
            header: "z_line_dwdm_device",
            downloadableValue: item => item.z_line_dwdm_device,
            downloadableColumnHeader: "z_line_dwdm_device",
            minWidth: 176,
            cell: item => item.z_line_dwdm_device,
            isRowHeader: true
        },
        // Make the above two sticky for z site
        {
            id: "mux_to_mux_link_instance_id",
            header: "MuxToMuxLinkInstanceId",
            minWidth: 176,
            cell: item => item.mux_to_mux_link_instance_id,
            isRowHeader: true
        }
    ];

    static CLIENT_COLUMN_DEFINITIONS = [
        // Below two columns should be sticky (collapsable)
        {
            id: "fiber_path",
            header: "fiber_path",
            minWidth: 176,
            downloadableValue: item => item.fiber_path,
            downloadableColumnHeader: "fiber_path",
            cell: item => item.fiber_path,
            isRowHeader: true
        },
        {
            id: "a_hostname",
            header: "a_hostname",
            minWidth: 150,
            downloadableValue: item => item.a_hostname,
            downloadableColumnHeader: "a_hostname",
            cell: item => item.a_hostname,
            isRowHeader: true
        },
        {
            id: "a_interface",
            header: "a_interface",
            minWidth: 176,
            downloadableValue: item => item.a_interface,
            downloadableColumnHeader: "a_interface",
            cell: item => item.a_interface,
            isRowHeader: true
        },
        // editable (RouterToRouterLinkInstanceID)
        {
            id: "a_location",
            header: "a_location",
            minWidth: 276,
            downloadableValue: item => item.a_location,
            downloadableColumnHeader: "a_location",
            cell: item => item.a_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_location)}
                        autoFocus
                        placeholder="Enter DWDM location"
                    />
                )
            }
        },
        {
            id: "a_optic_type",
            header: "a_optic_type",
            minWidth: 176,
            downloadableValue: item => item.a_optic_type,
            downloadableColumnHeader: "a_optic_type",
            cell: item => item.a_optic_type,
            isRowHeader: true
        },
        {
            id: "a_connector_type",
            header: "a_connector_type",
            minWidth: 176,
            downloadableValue: item => item.a_connector_type,
            downloadableColumnHeader: "a_connector_type",
            cell: item => item.a_connector_type,
            isRowHeader: true
        },
        {
            id: "a_fiber_type",
            header: "a_fiber_type",
            minWidth: 176,
            downloadableValue: item => item.a_fiber_type,
            downloadableColumnHeader: "a_fiber_type",
            cell: item => item.a_fiber_type,
            isRowHeader: true
        },
        {
            id: "a_port_speed",
            header: "a_port_speed",
            minWidth: 176,
            downloadableValue: item => item.a_port_speed,
            downloadableColumnHeader: "a_port_speed",
            cell: item => item.a_port_speed,
            isRowHeader: true
        },
        // Editable (Link to be updated EncryptionToEncryptionLinkInstanceID)
        {
            id: "a_lever_location",
            header: "a_lever_location",
            minWidth: 276,
            downloadableValue: item => item.a_lever_location,
            downloadableColumnHeader: "a_lever_location",
            cell: item => item.a_lever_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        disabled={!item.encryption_to_encryption_link_instance_id}
                        onFocus={() => setValue(item.a_lever_location)}
                        autoFocus
                        placeholder="Enter A lever location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: "a_lever_hostname",
            header: "a_lever_hostname",
            minWidth: 176,
            downloadableValue: item => item.a_lever_hostname,
            downloadableColumnHeader: "a_lever_hostname",
            cell: item => item.a_lever_hostname,
            isRowHeader: true
        },
        {
            id: "a_client_lever_interface",
            header: "a_client_lever_interface",
            minWidth: 176,
            downloadableValue: item => item.a_client_lever_interface,
            downloadableColumnHeader: "a_client_lever_interface",
            cell: item => item.a_client_lever_interface,
            isRowHeader: true
        },
        {
            id: "a_client_lever_optic_type",
            header: "a_client_lever_optic_type",
            minWidth: 176,
            downloadableValue: item => item.a_client_lever_optic_type,
            downloadableColumnHeader: "a_client_lever_optic_type",
            cell: item => item.a_client_lever_optic_type,
            isRowHeader: true
        },
        {
            id: "a_trunk_lever_interface",
            header: "a_trunk_lever_interface",
            minWidth: 176,
            downloadableValue: item => item.a_trunk_lever_interface,
            downloadableColumnHeader: "a_trunk_lever_interface",
            cell: item => item.a_trunk_lever_interface,
            isRowHeader: true
        },
        {
            id: "a_trunk_lever_optic_type",
            header: "a_trunk_lever_optic_type",
            minWidth: 176,
            downloadableValue: item => item.a_trunk_lever_optic_type,
            downloadableColumnHeader: "a_trunk_lever_optic_type",
            cell: item => item.a_trunk_lever_optic_type,
            isRowHeader: true
        },
        {
            id: "a_osp_panel_location",
            header: "a_osp_panel_location",
            minWidth: 400,
            cell: item => (
                <div>
                    {!!item.validation_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.a_osp_panel_location}
                </div>
            ),
            downloadableValue: item => item.a_osp_panel_location,
            downloadableColumnHeader: "a_osp_panel_location",
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.validation_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_osp_panel_location)}
                        autoFocus
                        placeholder="Enter Consumed Passive Patch Panel"
                    />
                )
            }
        },
        {
            id: "a_osp_panel_ports",
            header: "a_osp_panel_ports",
            minWidth: 200,
            cell: item => (
                <div>
                    {!!item.validation_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.a_osp_panel_ports}
                </div>
            ),
            downloadableValue: item => item.a_osp_panel_ports,
            downloadableColumnHeader: "a_osp_panel_ports",
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.validation_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_osp_panel_ports)}
                        autoFocus
                        placeholder="Enter Consumed Passive Port"
                    />
                )
            }
        },
        {
            id: "z_osp_panel_location",
            header: "z_osp_panel_location",
            minWidth: 400,
            downloadableValue: item => item.z_osp_panel_location,
            downloadableColumnHeader: "z_osp_panel_location",
            cell: item => item.z_osp_panel_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_osp_panel_location)}
                        autoFocus
                        placeholder="Enter Consumed Passive Patch Panel"
                    />
                )
            }
        },
        {
            id: "z_osp_panel_ports",
            header: "z_osp_panel_ports",
            minWidth: 200,
            downloadableValue: item => item.z_osp_panel_ports,
            downloadableColumnHeader: "z_osp_panel_ports",
            cell: item => item.z_osp_panel_ports,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_osp_panel_ports)}
                        autoFocus
                        placeholder="Enter Consumed Passive Port"
                    />
                )
            }
        },
        // Dynamic Columns For *_client_dwdm_port_Site* and *_trunk_dwdm_port_site should be after this...
        {
            id: "z_trunk_lever_optic_type",
            header: "z_trunk_lever_optic_type",
            minWidth: 176,
            downloadableValue: item => item.z_trunk_lever_optic_type,
            downloadableColumnHeader: "z_trunk_lever_optic_type",
            cell: item => item.z_trunk_lever_optic_type,
            isRowHeader: true
        },
        {
            id: "z_trunk_lever_interface",
            header: "z_trunk_lever_interface",
            minWidth: 176,
            downloadableValue: item => item.z_trunk_lever_interface,
            downloadableColumnHeader: "z_trunk_lever_interface",
            cell: item => item.z_trunk_lever_interface,
            isRowHeader: true
        },
        {
            id: "z_client_lever_optic_type",
            header: "z_client_lever_optic_type",
            minWidth: 176,
            downloadableValue: item => item.z_client_lever_optic_type,
            downloadableColumnHeader: "z_client_lever_optic_type",
            cell: item => item.z_client_lever_optic_type,
            isRowHeader: true
        },
        {
            id: "z_client_lever_interface",
            header: "z_client_lever_interface",
            minWidth: 176,
            downloadableValue: item => item.z_client_lever_interface,
            downloadableColumnHeader: "z_client_lever_interface",
            cell: item => item.z_client_lever_interface,
            isRowHeader: true
        },
        {
            id: "z_lever_hostname",
            header: "z_lever_hostname",
            minWidth: 176,
            downloadableValue: item => item.z_lever_hostname,
            downloadableColumnHeader: "z_lever_hostname",
            cell: item => item.z_lever_hostname,
            isRowHeader: true
        },
        // Editable (Link to be updated EncryptionToEncryptionLinkInstanceID)
        {
            id: "z_lever_location",
            header: "z_lever_location",
            minWidth: 276,
            downloadableValue: item => item.z_lever_location,
            downloadableColumnHeader: "z_lever_location",
            cell: item => item.z_lever_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_lever_location)}
                        disabled={!item.encryption_to_encryption_link_instance_id}
                        autoFocus
                        placeholder="Enter Z Lever location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: "z_port_speed",
            header: "z_port_speed",
            minWidth: 176,
            downloadableValue: item => item.z_port_speed,
            downloadableColumnHeader: "z_port_speed",
            cell: item => item.z_port_speed,
            isRowHeader: true
        },
        {
            id: "z_fiber_type",
            header: "z_fiber_type",
            minWidth: 176,
            downloadableValue: item => item.z_fiber_type,
            downloadableColumnHeader: "z_fiber_type",
            cell: item => item.z_fiber_type,
            isRowHeader: true
        },
        {
            id: "z_connector_type",
            header: "z_connector_type",
            minWidth: 176,
            downloadableValue: item => item.z_connector_type,
            downloadableColumnHeader: "z_connector_type",
            cell: item => item.z_connector_type,
            isRowHeader: true
        },
        {
            id: "z_optic_type",
            header: "z_optic_type",
            minWidth: 176,
            downloadableValue: item => item.z_optic_type,
            downloadableColumnHeader: "z_optic_type",
            cell: item => item.z_optic_type,
            isRowHeader: true
        },
        // Editable (link to be updated RouterToRouterLinkInstanceID)
        {
            id: "z_location",
            header: "z_location",
            minWidth: 276,
            downloadableValue: item => item.z_location,
            downloadableColumnHeader: "z_location",
            cell: item => item.z_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_location)}
                        autoFocus
                        placeholder="Enter Z location"
                    />
                )
            }
        },
        // Below two columns should be sticky
        {
            id: "z_interface",
            header: "z_interface",
            minWidth: 176,
            downloadableValue: item => item.z_interface,
            downloadableColumnHeader: "z_interface",
            cell: item => item.z_interface,
            isRowHeader: true
        },
        {
            id: "z_hostname",
            header: "z_hostname",
            minWidth: 176,
            downloadableValue: item => item.z_hostname,
            downloadableColumnHeader: "z_hostname",
            cell: item => item.z_hostname,
            isRowHeader: true
        },
        {
            id: "router_to_router_link_instance_id",
            header: "RouterToRouterLinkInstanceID",
            minWidth: 176,
            cell: item => item.router_to_router_link_instance_id,
            isRowHeader: true
        },
        {
            id: "encryption_to_encryption_link_instance_id",
            header: "EncryptionToEncryptionLinkInstanceID",
            minWidth: 176,
            cell: item => item.encryption_to_encryption_link_instance_id,
            isRowHeader: true
        }
    ]
}