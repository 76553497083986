import React, { Component } from "react";
import { Box } from "@amzn/awsui-components-react/polaris";
import { ClipLoader } from "react-spinners";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import CircuitDesignTabInformation from "circuitDesign/CircuitDesignTabInformation";

/**
 * CircuitDesignTab acts as the circuit design info landing page and displays all of the circuit design
 * information and its related info.
 */
class CircuitDesignTab extends Component {
    state = {
        selectedId: "circuitTable",
        circuitDesignObjectsStatic: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
        this.generateCircuitItems();
    };

    onChange = (evt) => {
        this.setState({
            selectedId: evt.detail.selectedId
        });
    };

    /**
     * This function fetches all of the given circuit's revisions.
     */
    getCircuitRevisions = (circuitObject, circuitRevisionsMap) => {
        const mappedCircuitObjectRevisions = [];
        circuitObject[Constants.ATTRIBUTES.previousCircuitDesignRevisionsIdList].forEach((previousCircuitDesignId) => {
            if (circuitRevisionsMap.get(previousCircuitDesignId)) {
                mappedCircuitObjectRevisions.push(circuitRevisionsMap.get(previousCircuitDesignId));
            }
        });
        circuitObject[Constants.ATTRIBUTES.futureCircuitDesignRevisionsIdList].forEach((futureCircuitDesignId) => {
            if (circuitRevisionsMap.get(futureCircuitDesignId)) {
                mappedCircuitObjectRevisions.push(circuitRevisionsMap.get(futureCircuitDesignId));
            }
        });
        return mappedCircuitObjectRevisions;
    };

    getParentCircuit = (consumedByCircuitId) => {
        const idAndDisplayValueArray = [];
        if (consumedByCircuitId &&
            this.props.componentIdToObjectMap[consumedByCircuitId] !== undefined) {
            idAndDisplayValueArray.push({
                displayValue: this.props.componentIdToObjectMap[consumedByCircuitId].circuitDesignNumber,
                id: consumedByCircuitId
            });
        }
        return idAndDisplayValueArray;
    }

    getParentCircuitsPath = (consumedByCircuitId) => {
        const idAndDisplayValueArray = [];
        if (consumedByCircuitId &&
            this.props.componentIdToObjectMap[consumedByCircuitId] !== undefined &&
            this.props.componentIdToObjectMap[consumedByCircuitId].pathId) {
            idAndDisplayValueArray.push({
                displayValue: this.props.componentIdToObjectMap[this.props.componentIdToObjectMap[consumedByCircuitId]
                    .pathId].pathName,
                id: consumedByCircuitId
            });
        }
        return idAndDisplayValueArray;
    }

    getCircuitDesignRevisionPropertyValue = (circuitDesignRevisionsMap, circuitDesignId, property) => {
        if (circuitDesignRevisionsMap.get(circuitDesignId)) {
            return circuitDesignRevisionsMap.get(circuitDesignId)[property];
        }
        return "";
    }

    generateCircuitItems = async () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects, [], null, null, null, null, [], null
        );

        if (circuitItemsObjects.static.length > 0) {
            const circuitDesignRevisionsMap = await this.generateCircuitRevisionsMap(circuitItemsObjects.static);
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.ATTRIBUTES.siteAId]: HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteA,
                        Constants.ATTRIBUTES.siteId
                    ),
                    [Constants.ATTRIBUTES.siteZId]: HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteZ,
                        Constants.ATTRIBUTES.siteId
                    ),
                    [Constants.COMPONENT_NAMES.siteA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteA
                    ),
                    [Constants.COMPONENT_NAMES.siteZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteZ
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.COMPONENT_NAMES.crossConnectA]: HelperFunctions.getCrossConnectDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.crossConnectA
                    ),
                    providerCircuitId: HelperFunctions.getProviderCircuitDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.demarcAndCfaA]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.demarcAndCfaZ]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaZ,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    parentCircuitDisplayInfoList:
                        this.getParentCircuit(staticCircuitDesign.consumedByCircuitIdForNewRevision ?
                            staticCircuitDesign.consumedByCircuitIdForNewRevision :
                            staticCircuitDesign.consumedByCircuitId),
                    parentPath: this.getParentCircuitsPath(staticCircuitDesign.consumedByCircuitIdForNewRevision ?
                        staticCircuitDesign.consumedByCircuitIdForNewRevision :
                        staticCircuitDesign.consumedByCircuitId),
                    maxLatencyProtectState: this.getCircuitDesignRevisionPropertyValue(
                        circuitDesignRevisionsMap, staticCircuitDesign.circuitDesignId,
                        Constants.ATTRIBUTES.maxLatencyProtectState
                    ),
                    maxLatencySteadyState: this.getCircuitDesignRevisionPropertyValue(
                        circuitDesignRevisionsMap, staticCircuitDesign.circuitDesignId,
                        Constants.ATTRIBUTES.maxLatencySteadyState
                    ),
                    subStatus: Object.values(staticCircuitDesign.subStatusMap),
                    circuitRevisions: this.getCircuitRevisions(staticCircuitDesign, circuitDesignRevisionsMap)
                }));
        }
        this.setState({
            circuitDesignObjectsStatic: circuitItemsObjects.static
        });
    }

    /**
     * This function fetches all of the given revisions info for all the circuits present in an order.
     */
    generateCircuitRevisionsMap = async (circuitItemsObjects) => {
        let circuitDesignRevisionIds = [];
        circuitItemsObjects.forEach((circuitDesignObject) => {
            circuitDesignRevisionIds = circuitDesignRevisionIds.concat(
                circuitDesignObject[Constants.ATTRIBUTES.previousCircuitDesignRevisionsIdList]
            );
            circuitDesignRevisionIds = circuitDesignRevisionIds.concat(
                circuitDesignObject[Constants.ATTRIBUTES.futureCircuitDesignRevisionsIdList]
            );
            circuitDesignRevisionIds = circuitDesignRevisionIds.concat(circuitDesignObject.circuitDesignId);
        });
        const circuitDesignRevisionsResponse = await new FremontBackendClient().getBatchCircuitDesignInfo(
            circuitDesignRevisionIds,
            this.props.auth
        );
        const circuitRevisionsMap = new Map();
        circuitDesignRevisionsResponse.circuitDesigns.map(circuitDesign =>
            circuitRevisionsMap.set(circuitDesign[Constants.ATTRIBUTES.circuitDesignId], circuitDesign));
        return circuitRevisionsMap;
    };

    generateSegmentOptions = () => {
        const options = this.props.circuitDesignObjects.map(
            circuitDesign => ({
                text: circuitDesign.circuitDesignNumber,
                id: circuitDesign.circuitDesignId
            })
        );
        options.unshift({
            text: "Circuit Table",
            id: "circuitTable"
        });
        return options;
    };

    render() {
        if (this.props.loading) {
            return (
                <div className="centerCircuitDesignTabSpinner">
                    <ClipLoader
                        color={Constants.SPINNER_ORANGE_COLOR}
                        loading
                        size={Constants.SPINNER_SIZES.loadingPage}
                    />
                </div>
            );
        }
        if (this.props.circuitDesignObjects.length === 0) {
            return (
                <span>
                    <Box variant="h2">No circuits created yet.</Box>
                </span>
            );
        }
        // If the order is completed or cancelled (both part of props.isOrderCompleted), of if its a decom order,
        // we need to disable the edit functionality on the circuit designs tab
        let disabledEditMode = false;
        if (this.props.isOrderCompleted || Constants.ORDER_TYPES.DECOMMISSION === this.props.order.orderType) {
            disabledEditMode = true;
        }
        return (
            <div>
                <CircuitDesignTabInformation
                    auth={this.props.auth}
                    selectedId={this.state.selectedId}
                    generateSegmentOptions={this.generateSegmentOptions()}
                    handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                    onChange={this.onChange}
                    editMode={!disabledEditMode}
                    componentIdToObjectMap={this.props.componentIdToObjectMap}
                    updateComponentIdToObjectMap={this.props.updateComponentIdToObjectMap}
                    order={this.props.order}
                    circuitDesignObjects={this.state.circuitDesignObjectsStatic}
                    loadData={this.props.loadData}
                    allProviders={this.props.allProviders}
                    allProvidersLoading={this.props.allProvidersLoading}
                />
            </div>
        );
    }
}

export default CircuitDesignTab;