import React from "react";
import {
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontSelect,
    FremontTextarea,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import { determineAsnPlaceholderText } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

const getPlaceHolderText = (props, defaultPlaceholder) => {
    if (props.allFieldsDisabled) {
        return "";
    } else if (!props.order.providerName) {
        return "Select a Provider first";
    }
    return defaultPlaceholder;
};

const getSiteAHintText = (props) => {
    if (props.hasSubmittedOnce || !!props.orderErrorTexts.siteAId) {
        return "";
    }
    // We are serviceType is interconnect and it is not an install order, we modify the hintText
    // to explain which site they should be choosing
    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order.serviceType)
        && Constants.ORDER_TYPES.INSTALL !== props.order.orderType) {
        return "This is the site from the port reservation stage, not the Provider Site.";
    }
    return "Don't see the Site you want? Click on the button to create a new one.";
};

const getProviderContactPlaceHolderText = (props) => {
    if (props.allFieldsDisabled) {
        return "";
    } else if (!props.order.providerName) {
        return "Select a Provider first";
    } else if (!props.order.serviceType) {
        return "Select a Service Type first";
    }
    return "Enter a Provider Contact";
};

const renderCustomerFabric = props => (
    props.order[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.FABRIC_MIGRATION
        ?
        (
            <ColumnLayout columns={2}>
                <FormField
                    label={requiredLabel("Source Customer/Fabric:")}
                    errorText={props.orderErrorTexts.originalCustomerFabric}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.originalCustomerFabric}
                        options={HelperFunctions.createSelectedOptionsWithDescriptions(
                            Object.values(Constants.CUSTOMER_FABRICS),
                            Constants.CUSTOMER_FABRICS_DESCRIPTIONS
                        )}
                        disabled
                        selectedOption={HelperFunctions.createSelectedOption(
                            props.order[Constants.ATTRIBUTES.originalCustomerFabric]
                        )}
                        onChange={props.handleOrderInputChange}
                    />
                </FormField>
                <FormField
                    label={requiredLabel("Destination Customer/Fabric:")}
                    errorText={props.orderErrorTexts.customerFabric}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.customerFabric}
                        options={HelperFunctions.createSelectedOptionsWithDescriptions(
                            Object.values(Constants.CUSTOMER_FABRICS),
                            Constants.CUSTOMER_FABRICS_DESCRIPTIONS
                        )}
                        disabled
                        selectedOption={HelperFunctions.createSelectedOption(
                            props.order[Constants.ATTRIBUTES.customerFabric]
                        )}
                        onChange={props.handleOrderInputChange}
                    />
                </FormField>
            </ColumnLayout>
        )
        :
        (
            <FormField
                label={requiredLabel("Customer/Fabric:")}
                errorText={props.orderErrorTexts.customerFabric}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.customerFabric}
                    placeholder={getPlaceHolderText(props, "Select a Customer/Fabric")}
                    options={HelperFunctions.createSelectedOptionsWithDescriptions(
                        Object.values(Constants.CUSTOMER_FABRICS),
                        Constants.CUSTOMER_FABRICS_DESCRIPTIONS
                    )}
                    disabled={props.allFieldsDisabled}
                    selectedOption={HelperFunctions.createSelectedOption(
                        props.order[Constants.ATTRIBUTES.customerFabric]
                    )}
                    onChange={props.handleOrderInputChange}
                />
            </FormField>
        )
);

// We have this function because backbone has different possible order types than interconnect
const getOrderTypeOptions = props => (
    props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE
        ? HelperFunctions.createSelectedOptionsWithFrontEndLabels(
            Object.values(Constants.ORDER_TYPES)
        )
        : HelperFunctions.createSelectedOptionsWithFrontEndLabels(
            [Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE, Constants.ORDER_TYPES.DECOMMISSION]
        )
);

const getResourceTypeOptions = (resourceMap, resourceType) =>
    (resourceMap[resourceType] ?
        resourceMap[resourceType].map(resource =>
            ({ value: resource.resourceName, label: resource.resourceName })) : []);

const OrderForm = (props) => {
    // Prepare all the resource options so its easier to read the components, also sort them
    const provisionerOptions = HelperFunctions.sortObjectsByField(
        getResourceTypeOptions(props.resourceMap, Constants.RESOURCE_TYPES.provisioner), "label"
    );

    const engineerResourceType = props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE
        ? Constants.RESOURCE_TYPES.backBoneEngineer : Constants.RESOURCE_TYPES.ipEngineer;
    const engineerOptions = HelperFunctions.sortObjectsByField(
        getResourceTypeOptions(props.resourceMap, engineerResourceType), "label"
    );

    return (
        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
            <Form
                header={
                    <Header
                        variant="h1"
                        description="Fill out the required fields in this form to create an order."
                    >
                        Order Creation Form
                    </Header>
                }
                actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="s"
                    >
                        <Link href={fetchCallBackLink(props.providerName)}>
                            <FremontButton
                                variant="normal"
                                disabled={props.allFieldsDisabled || props.isSubmissionInProgress}
                            >
                                Cancel
                            </FremontButton>
                        </Link>
                        <FremontButton
                            variant="primary"
                            id="orderFormSubmitButton"
                            disabled={props.allFieldsDisabled || props.isSubmissionInProgress}
                            onClick={props.handleOrderSubmit}
                        >
                            {props.isSubmissionInProgress ?
                                Constants.SUBMISSION_STRINGS.submitInProgress :
                                Constants.SUBMISSION_STRINGS.readyToSubmit}
                        </FremontButton>
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header variant="h2">
                            General Information
                        </Header>
                    }
                >
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={requiredLabel("Provider:")}
                            errorText={props.hasSubmittedOnce ? props.orderErrorTexts.providerName : ""}
                        >
                            <ColumnLayout columns={2}>
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.providerName}
                                    placeholder={props.allFieldsDisabled ? ""
                                        : "Select a Provider Name"}
                                    statusType={props.providersLoading ? Constants.LOADING :
                                        Constants.FINISHED}
                                    loadingText={Constants.STATUS_TEXTS.loadingProviders}
                                    options={HelperFunctions.sortObjectsByField(
                                        props.providerOptions, "label"
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.order[Constants.ATTRIBUTES.providerName]
                                    )}
                                    empty="No Providers found."
                                    disabled={props.allFieldsDisabled}
                                    onChange={props.handleOrderInputChange}
                                    filteringType="auto"
                                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                />
                                <FremontButton
                                    variant="primary"
                                    id="createProviderButton"
                                    disabled={props.allFieldsDisabled || props.providersLoading
                                        || props.isSubmissionInProgress}
                                    loading={props.providersLoading}
                                    onClick={props.handleCreateProvider}
                                >
                                    Add a new Provider
                                </FremontButton>
                            </ColumnLayout>
                        </FormField>
                        <FormField
                            label={requiredLabel("Service Type:")}
                            errorText={props.orderErrorTexts.serviceType}
                            constraintText={!props.orderErrorTexts.providerName ? "Don't see the Service Type " +
                                    "for this Provider? Click on the button to add it." : ""}
                        >
                            <ColumnLayout columns={2}>
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.serviceType}
                                    placeholder={getPlaceHolderText(props, "Select a Service Type")}
                                    disabled={props.allFieldsDisabled || !props.order.providerName}
                                    options={props.providerServiceOptions}
                                    selectedOption={HelperFunctions.createSelectedOption(props.order.serviceType)}
                                    onChange={props.handleOrderInputChange}
                                    statusType={props.providersLoading || props.servicesLoading
                                        ? Constants.LOADING : Constants.FINISHED}
                                    loadingText={Constants.STATUS_TEXTS.loadingServices}
                                    empty={`No service types exist for ${props.order.providerName}.
                                            Please create one if needed.`}
                                />
                                <FremontButton
                                    variant="primary"
                                    id="createProviderServiceButton"
                                    disabled={props.allFieldsDisabled || props.servicesLoading
                                        || !!props.orderErrorTexts.providerName
                                        || props.isSubmissionInProgress}
                                    loading={props.servicesLoading && !!props.order.providerName}
                                    onClick={props.showProviderServiceModal}
                                >
                                    Add Service for Provider
                                </FremontButton>
                            </ColumnLayout>
                        </FormField>
                        <FormField
                            label={requiredLabel("Order Type:")}
                            errorText={props.orderErrorTexts.orderType}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.orderType}
                                placeholder={getPlaceHolderText(props, "Select an Order Type")}
                                options={getOrderTypeOptions(props)}
                                disabled={props.allFieldsDisabled || !!props.orderErrorTexts.providerName}
                                selectedOption={HelperFunctions.createSelectedOptionWithFrontEndLabel(
                                    props.order.orderType
                                )}
                                onChange={props.handleOrderInputChange}
                            />
                        </FormField>
                        {(props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) &&
                            renderCustomerFabric(props)
                        }
                        <FormField
                            label={requiredLabel(Constants.INTERCONNECT_SERVICE_TYPES.includes(
                                props.order.serviceType
                            )
                                && Constants.ORDER_TYPES.INSTALL === props.order.orderType
                                ? "Provider Site:" : "Site A:")
                            }
                            errorText={props.orderErrorTexts.siteAId}
                            constraintText={getSiteAHintText(props)}
                        >
                            <ColumnLayout columns={2}>
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.siteAId}
                                    placeholder={props.allFieldsDisabled ? "" : "Select a Site"}
                                    selectedOption={!props.siteOptions.find(option =>
                                        option.value === props.order.siteAId)
                                        ? "" : props.siteOptions.find(option =>
                                            option.value === props.order.siteAId)}
                                    statusType={props.providersLoading
                                        || props.sitesLoading ? Constants.LOADING : Constants.FINISHED}
                                    loadingText={Constants.STATUS_TEXTS.loadingSites}
                                    options={HelperFunctions.groupOptions(
                                        props.siteOptions, Constants.ATTRIBUTES.geographicRegion
                                    )}
                                    empty={Constants.DESCRIPTION_TEXTS.noSites}
                                    disabled={props.allFieldsDisabled}
                                    onChange={props.handleOrderInputChange}
                                    filteringType="auto"
                                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                />
                                <div>
                                    <FremontButton
                                        variant="primary"
                                        id="createSiteButton"
                                        disabled={props.allFieldsDisabled || props.sitesLoading
                                            || props.isSubmissionInProgress}
                                        loading={props.sitesLoading}
                                        onClick={props.handleCreateSite}
                                    >
                                        Create New Site
                                    </FremontButton>
                                </div>
                            </ColumnLayout>
                        </FormField>
                        {/* Dynamic fields (based on things like serviceType and orderType) */}
                        {(props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) &&
                            <FormField
                                label={requiredLabel("Site Z:")}
                                errorText={props.orderErrorTexts.siteZId}
                                constraintText={(!props.hasSubmittedOnce)
                                || !props.orderErrorTexts.siteZId ? "Don't see the Site for this" +
                                    " Provider? Go to the site details page and add the provider to it." +
                                    " Or, click on the button to create a new one." : ""}
                            >
                                <ColumnLayout columns={2}>
                                    <FremontSelect
                                        id={Constants.ATTRIBUTES.siteZId}
                                        placeholder={props.allFieldsDisabled ? "" : "Select a Site"}
                                        selectedOption={props.siteOptions.find(option =>
                                            option.value === props.order.siteZId)}
                                        statusType={props.providersLoading
                                        || props.sitesLoading ? Constants.LOADING : Constants.FINISHED}
                                        loadingText={Constants.STATUS_TEXTS.loadingSites}
                                        options={HelperFunctions.groupOptions(
                                            props.siteOptions, Constants.ATTRIBUTES.geographicRegion
                                        )}
                                        empty={Constants.DESCRIPTION_TEXTS.noSites}
                                        disabled={props.allFieldsDisabled}
                                        onChange={props.handleOrderInputChange}
                                        filteringType="auto"
                                        noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                    />
                                    <div>
                                        <FremontButton
                                            variant="primary"
                                            id="createSiteButton"
                                            disabled={props.allFieldsDisabled || props.sitesLoading
                                            || !!props.orderErrorTexts.providerName
                                            || props.isSubmissionInProgress}
                                            loading={props.sitesLoading}
                                            onClick={props.handleCreateSite}
                                        >
                                            Create New Site
                                        </FremontButton>
                                    </div>
                                </ColumnLayout>
                            </FormField>
                        }
                        {props.order.orderType !== Constants.ORDER_TYPES.CHANGE &&
                        <FormField
                            label={requiredLabel("Provider Contact:")}
                            errorText={props.orderErrorTexts.contactId}
                            constraintText={!props.orderErrorTexts.providerName ?
                                "Don't see an existing Contact? Click the button to add it."
                                : ""}
                        >
                            <ColumnLayout columns={2}>
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.contactId}
                                    placeholder={getProviderContactPlaceHolderText(props)}
                                    options={props.contactOptions}
                                    selectedOption={!props.contactOptions.find(option =>
                                        option.value === props.order.contactId)
                                        ? "" : props.contactOptions.find(option =>
                                            option.value === props.order.contactId)}
                                    empty="No Contacts for this Service Type."
                                    onChange={props.handleOrderInputChange}
                                    disabled={props.allFieldsDisabled
                                            || !!props.orderErrorTexts.providerName
                                            || !props.order.serviceType}
                                    filteringType="auto"
                                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                />
                                <SpaceBetween
                                    direction="horizontal"
                                    size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                >
                                    <FremontButton
                                        variant="primary"
                                        disabled={props.allFieldsDisabled
                                        || !!props.orderErrorTexts.providerName
                                        || !props.order.serviceType}
                                        onClick={props.handleCreateContact}
                                    >
                                        New Contact
                                    </FremontButton>
                                    <FremontButton
                                        variant="primary"
                                        disabled={props.allFieldsDisabled
                                        || !!props.orderErrorTexts.providerName
                                        || !props.order.serviceType}
                                        onClick={props.handleAddServiceToContact}
                                    >
                                        Add Service
                                    </FremontButton>
                                </SpaceBetween>
                            </ColumnLayout>
                        </FormField>
                        }
                        <FormField
                            label={requiredLabel("Business Need:")}
                            errorText={props.orderErrorTexts.businessNeed}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.businessNeed}
                                placeholder={props.allFieldsDisabled ? "" : "Select a Business Need"}
                                disabled={props.allFieldsDisabled
                                    || OrderValidation.getBusinessNeedOptions(
                                        props.order.orderType, props.order.serviceType
                                    ).length <= 1}
                                options={OrderValidation.getBusinessNeedOptions(
                                    props.order.orderType, props.order.serviceType
                                )}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.order.businessNeed
                                )}
                                onChange={props.handleOrderInputChange}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Required Completion Date:")}
                            errorText={props.orderErrorTexts.requiredCompletionDate}
                        >
                            <FremontDatePicker
                                id={Constants.ATTRIBUTES.requiredCompletionDate}
                                placeholder={Constants.DATE_PICKER_FORMAT}
                                disabled={props.allFieldsDisabled}
                                value={props.order.requiredCompletionDate}
                                onChange={props.handleOrderInputChange}
                            />
                        </FormField>
                        {Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order.serviceType) &&
                                props.order[Constants.ATTRIBUTES.orderType] !== Constants.ORDER_TYPES.CHANGE &&
                                <FormField
                                    label={requiredLabel("Is this order part of an Install & Decommission:")}
                                    errorText={props.orderErrorTexts.installAndDecommission}
                                >
                                    <FremontSelect
                                        id={Constants.ATTRIBUTES.installAndDecommission}
                                        placeholder={props.allFieldsDisabled ?
                                            "" : "Select whether Install & Decommission"}
                                        disabled={props.allFieldsDisabled}
                                        options={Constants.GENERAL_YES_NO_OPTIONS}
                                        selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                            props.order.installAndDecommission
                                        )}
                                        onChange={props.handleOrderInputChange}
                                    />
                                </FormField>
                        }
                        {Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order.serviceType) &&
                                props.order[Constants.ATTRIBUTES.orderType]
                                    === Constants.ORDER_TYPES.DECOMMISSION &&
                                props.order[Constants.ATTRIBUTES.installAndDecommission] &&
                                <FormField
                                    label={requiredLabel("Install Order ID:")}
                                    errorText={props.orderErrorTexts.relatedOrderId}
                                >
                                    <FremontInput
                                        id={Constants.ATTRIBUTES.relatedOrderId}
                                        disabled={props.allFieldsDisabled}
                                        value={props.order.relatedOrderId}
                                        onInput={props.handleOrderInputChange}
                                    />
                                </FormField>
                        }
                        {Constants.ASN_SERVICE_TYPES.includes(props.order.serviceType) &&
                            <FormField
                                label={requiredLabel("ASN:")}
                                errorText={props.hasSubmittedOnce ? props.orderErrorTexts.asnId : ""}
                                constraintText="Don't see an existing ASN here?
                                    Go to the ASN details page and add the service to it."
                            >
                                <ColumnLayout columns={2}>
                                    <FremontSelect
                                        id={Constants.ATTRIBUTES.asnId}
                                        placeholder={determineAsnPlaceholderText(props.allFieldsDisabled,
                                            props.order.providerName, false, props.asnOptions)}
                                        statusType={props.providersLoading || props.servicesLoading
                                        || props.asnLoading ? Constants.LOADING : Constants.FINISHED}
                                        loadingText={Constants.STATUS_TEXTS.loadingAsns}
                                        options={props.asnOptions}
                                        selectedOption={!props.asnOptions.find(option =>
                                            option.value === props.order.asnId)
                                            ? "" : props.asnOptions.find(option =>
                                                option.value === props.order.asnId)}
                                        disabled={props.allFieldsDisabled
                                        || !!props.orderErrorTexts.providerName
                                        || props.asnOptions.length === 0}
                                        onChange={props.handleOrderInputChange}
                                        filteringType="auto"
                                        noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                    />
                                    <div>
                                        <FremontButton
                                            variant="primary"
                                            id="createAsn"
                                            disabled={props.allFieldsDisabled
                                            || props.isSubmissionInProgress}
                                            onClick={props.handleCreateAsn}
                                        >
                                            Create New ASN
                                        </FremontButton>
                                    </div>
                                </ColumnLayout>
                            </FormField>
                        }
                        {(props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) &&
                            <FormField
                                label={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                    >
                                        <span>
                                            Geographic Region:
                                        </span>
                                        {
                                            HelperFunctions.renderCustomTooltip(null,
                                                Constants.TOOLTIP_STRINGS.geographicRegionFieldExplanation)
                                        }
                                    </SpaceBetween>
                                }
                            >
                                <FremontInput
                                    value={props.order.geographicRegion}
                                    disabled
                                />
                            </FormField>
                        }
                        {props.order.serviceType === Constants.SERVICE_TYPES.IP_TRANSIT
                            && props.order.orderType === Constants.ORDER_TYPES.INSTALL &&
                            <FormField
                                label={requiredLabel("Transit Type:")}
                                errorText={props.orderErrorTexts.transitType}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.transitType}
                                    placeholder={props.allFieldsDisabled ? "" : "Select a Transit Type"}
                                    disabled={props.allFieldsDisabled}
                                    options={HelperFunctions.createSelectedOptions(Constants.TRANSIT_TYPES)}
                                    selectedOption={HelperFunctions
                                        .createSelectedOption(props.order.transitType)}
                                    onChange={props.handleOrderInputChange}
                                />
                            </FormField>
                        }
                        {props.order.serviceType === Constants.SERVICE_TYPES.INTERNET_EXCHANGE
                            && props.order.orderType === Constants.ORDER_TYPES.INSTALL &&
                            <FormField
                                label={requiredLabel("Internet Exchange Name:")}
                                errorText={props.orderErrorTexts.peeringDBInternetExchangeName}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.peeringDBInternetExchangeName}
                                    placeholder={props.allFieldsDisabled ? ""
                                        : "Select an Internet Exchange Name"}
                                    disabled={props.allFieldsDisabled}
                                    options={HelperFunctions.createSelectedOptions(
                                        Constants.INTERNET_EXCHANGE_NAMES
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.order[Constants.ATTRIBUTES.peeringDBInternetExchangeName]
                                    )}
                                    onChange={props.handleOrderInputChange}
                                    filteringType="auto"
                                    noMatch="No Internet Exchanges matching your search"
                                />
                            </FormField>
                        }
                        {props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION ?
                            props.order.serviceType !== Constants.SERVICE_TYPES.SFP &&
                                <FormField
                                    label={requiredLabel("Business Operations:")}
                                >
                                    <FremontSelect
                                        id={Constants.ATTRIBUTES.businessOperationsId}
                                        selectedOption={HelperFunctions.createSelectedOption(
                                            props.order[Constants.ATTRIBUTES.businessOperationsId]
                                        )}
                                        disabled
                                    />
                                </FormField> :
                            <FormField
                                label={requiredLabel("Provisioner:")}
                                errorText={props.orderErrorTexts[Constants.ATTRIBUTES.provisionerId]}
                                constraintText={provisionerOptions.length > 1
                                        && HelperFunctions.isEmpty(props.selectedResourceOptions[
                                            Constants.RESOURCE_TYPES.provisioner]) ?
                                    "There are multiple provisioners that match assignment rules. "
                                        + "Please select one manually." : ""}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.provisionerId}
                                    options={provisionerOptions}
                                    selectedOption={
                                        props.selectedResourceOptions[Constants.RESOURCE_TYPES.provisioner]
                                    }
                                    disabled={props.allFieldsDisabled || props.isProvisionerFieldDisabled
                                            || props.isResourcesLoading || provisionerOptions.length <= 1}
                                    onChange={props.handleOrderInputChange}
                                />
                            </FormField>
                        }
                        {(Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order[Constants.ATTRIBUTES.serviceType])
                            || Constants.PATH_CUSTOMER_FABRICS.includes(
                                props.order[Constants.ATTRIBUTES.customerFabric]
                            )) &&
                            <FormField
                                label={requiredLabel("Engineer:")}
                                errorText={props.orderErrorTexts[Constants.ATTRIBUTES.engineerId]}
                                constraintText={engineerOptions.length > 1
                                && HelperFunctions.isEmpty(props.selectedResourceOptions.engineer) ?
                                    "There are multiple engineers that match assignment rules. " +
                                    "Please select one manually." : ""}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.engineerId}
                                    options={engineerOptions}
                                    selectedOption={props.selectedResourceOptions.engineer}
                                    disabled={props.allFieldsDisabled || props.isEngineerFieldDisabled
                                    || props.isResourcesLoading || engineerOptions.length <= 1}
                                    onChange={props.handleOrderInputChange}
                                />
                            </FormField>
                        }
                        <FormField
                            label={optionalLabel("Description:")}
                            errorText={props.orderErrorTexts[Constants.ATTRIBUTES.description]}
                        >
                            <FremontTextarea
                                id={Constants.ATTRIBUTES.description}
                                disabled={props.allFieldsDisabled}
                                value={props.order[Constants.ATTRIBUTES.description]}
                                onInput={props.handleOrderInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
            </Form>
        </div>
    );
};

export default OrderForm;