import React, { Component } from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Grid,
    Header,
    Link,
    Icon,
    SpaceBetween,
    Tabs,
    Popover
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    ConfirmationModal,
    FremontAlert,
    FremontButton,
    FremontExpandableSection,
    FremontSelect
} from "utils/CommonComponents";
import { withRouter } from "react-router-dom";
import AttachmentHandler from "attachment/AttachmentHandler";
import AttachmentTab from "attachment/AttachmentTab";
import AuditTab from "audit/AuditTab";
import CircuitDesignTab from "circuitDesign/CircuitDesignTab";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontErrorPage from "common/FremontErrorPage";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import NoteTab from "note/NoteTab";
import OrderBlockerModalHandler from "order/OrderBlockerModalHandler";
import OrderBusinessDeveloperTab from "order/OrderBusinessDeveloperTab";
import OrderBusinessOperationsTab from "order/OrderBusinessOperationsTab";
import OrderCapacityEngineeringTab from "order/OrderCapacityEngineeringTab";
import OrderCapacityProvisioningTab from "order/OrderCapacityProvisioningTab";
import OrderInformation from "order/OrderInformation";
import OrderSidebar from "order/OrderSidebar";
import OrderValidation from "order/OrderValidation";
import TagHandler from "tag/TagHandler";
import TemplateGeneratorHandler from "templateGenerator/TemplateGeneratorHandler";
import PolarisUtils from "utils/PolarisUtils";
import OrderProviderChangeHandler from "order/OrderProviderChangeHandler";

/**
 * OrderDetailsPage acts as the order info landing page and displays all
 * of the order information and its related info.
 */

class OrderDetailsPage extends Component {
    state = {
        activeTabId: "details",
        // This attribute only applies to the Order Creator, Business Developer, Business Operations,
        // Capacity Engineering, and Capacity Provisioning tabs. All other tabs are editable by default
        isTabEditable: false,
        flashbar: {
            type: "",
            text: ""
        },
        tagItems: [],
        blockers: [],
        clickedBlockerId: "",
        blockerNotes: [],
        loading: true,
        isUserManager: !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT]
            || !!this.props.user.permissions[Constants.POSIX_GROUPS.NEST]
            || !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BD]
            || !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS]
            || !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG]
            || !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG]
            || HelperFunctions.isLocalHost(),
        isEditingPriority: false,
        isEditingSiteA: false,
        isEditingSiteZ: false,
        isPrioritySubmissionInProgress: false,
        isSiteSubmissionInProgress: false,
        isUpdateOrderInfoInProgress: false,
        isUpdateOrderInfoEditClicked: false,
        order: {
            tagIdList: [],
            workflow: { stages: {} }
        },
        orderContact: "",
        orderContactEmail: "",
        isOrderCompleted: false,
        isOrderCancelled: false,
        isNCISOrder: false,
        tabMap: {},
        asnLoading: false,
        asn: {},
        siteNames: {
            siteAName: "",
            siteZName: ""
        },
        siteOptions: [],
        newOrderSiteAOption: {},
        newOrderSiteZOption: {},
        siteOptionsLoading: false,
        circuitDesignObjects: [],
        circuitDesignOptions: [],
        componentIdToObjectMap: {},
        workOrder: {},
        span: {},
        circuitDesignsLoading: false,
        orderAttachments: [],
        circuitDesignAttachments: [],
        orderAttachmentsLoading: false,
        circuitDesignAttachmentsLoading: false,
        attachmentFormToReactRefMap: {},
        circuitDesignAttachmentsClone: [], // This is what is modified before we actually submit the attachments
        loadingResources: false,
        bizDevOptions: [],
        bizOpsResources: [],
        provisionerOptions: [],
        engineerOptions: [],
        contactOptions: [],
        isDownloadingAttachment: false,
        isModalClicked: {
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.tag]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.completion]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.cancel]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange]: false,
            [OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange]: false
        },
        hasOrderCompletionModalBeenShownOnce: false,
        isOrderCompletionInProgress: false,
        loadingComponentIdToObjectMap: false,
        isOrderCancelInProgress: false,
        isAnyEditModeEnabled: false,
        isTagSubmissionInProgress: false,
        tagsLoading: false,
        blockersLoading: false,
        auth: this.props.auth,
        isDataLoaded: false,
        firstTabChange: false,
        firstOrderEditClick: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.loadData(true, true, true);
            document.title = HelperFunctions.deepClone(this.state.order.orderId)
                .replace("ORDER", "O");

            this.createTabMap();

            this.setState({
                loading: false,
                isOrderCompleted: HelperFunctions.isOrderCompleted(this.state.order.stageStatusMap),
                isOrderCancelled: HelperFunctions.isOrderCancelled(this.state.order.stageStatusMap),
                isNCISOrder: HelperFunctions.isNCISOrder(this.state.order)
            });

            // Load some non-critical information in the background, its not needed to render data
            // unless the user is modifying the order information, so its safe to load lazily. Also we don't need
            // to load this on every loadData() call
            this.loadDataOnOriginalPageLoad();
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    /**
     * This functions is used by tab level components
     */
    setIsAnyEditModeEnabled = (isAnyEditModeEnabled) => {
        this.setState({ isAnyEditModeEnabled });
    };

    /** This function fetches every tag item that currently exists in Fremont so that the user can choose from
     * a list of tags rather than trying to manually type in the name
     */
    getAllTagItems = async () => {
        this.setState({ tagsLoading: true });
        try {
            const output = await HelperFunctions.getAllTagItems(this.props.auth);

            this.setState({ tagItems: output, tagsLoading: false });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { tagsLoading: false });
        }
    };

    getOrder = async () => {
        this.setState({ isDataLoaded: false });
        // This function is
        try {
            const orderResponse = await this.FremontBackendClient.getOrderInfo(
                this.props.match.params.orderId, this.props.auth
            );
            this.setState({
                order: HelperFunctions.appendMetaToItem(orderResponse.order, Constants.NCIS_ROUTES.order),
                isDataLoaded: true
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isDataLoaded: true });
        }
    };

    getResourceByType = async (resourceType) => {
        // Decide which state field to update. By default this field is engineers
        let stateFieldToUpdate = "engineerOptions";
        if (resourceType === Constants.RESOURCE_TYPES.bizops) {
            stateFieldToUpdate = "bizOpsResources";
        }
        if (resourceType === Constants.RESOURCE_TYPES.businessDeveloper) {
            stateFieldToUpdate = "bizDevOptions";
        }
        if (resourceType === Constants.RESOURCE_TYPES.provisioner) {
            stateFieldToUpdate = "provisionerOptions";
        }

        // If we are testing this in local host, we should just populate ourselves as options. Comment this out
        // if you are testing the functionality with beta rather than your dev stack
        if (HelperFunctions.isLocalHost()) {
            this.setState({
                [stateFieldToUpdate]: [this.props.auth.userId]
            });
            return;
        }

        const response = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
            resourceType, this.props.auth
        );

        this.setState({ [stateFieldToUpdate]: response.resourceNames.sort() });
    };

    getComponentsForCircuits = async (circuitDesigns) => {
        // Here we pull all of the consumed circuit IDs that exist in the positionMap and the consumingCircuitsIdList,
        // dedupe them in a set, and then put them back into an array so we can fetch those circuits and their
        // dependencies. This allows us to display the components of the child circuits correct on the circuitDesign tab
        const additionalCircuitIdsToFetch = Array.from(new Set(circuitDesigns.flatMap(circuitDesign =>
            [...HelperFunctions.getConsumedCircuitIdsFromPositionMap(
                circuitDesign[Constants.ATTRIBUTES.positionMap]
            ), ...circuitDesign[Constants.ATTRIBUTES.consumingCircuitsIdList]])));

        const childCircuitIdsToFetch = Array.from(new Set(circuitDesigns.flatMap(circuitDesign =>
            [...HelperFunctions.getConsumedCircuitIdsFromPositionMap(
                circuitDesign[Constants.ATTRIBUTES.positionMap]
            ), ...circuitDesign[Constants.ATTRIBUTES.consumedByCircuitId]])));

        const consumedByCircuitIdForNewRevision = Array.from(new Set(circuitDesigns.flatMap(circuitDesign =>
            [...HelperFunctions.getConsumedCircuitIdsFromPositionMap(
                circuitDesign[Constants.ATTRIBUTES.positionMap]
            ), ...circuitDesign[Constants.ATTRIBUTES.consumedByCircuitIdForNewRevision]])));

        const combinedCircuitIdsToFetch = additionalCircuitIdsToFetch.concat(childCircuitIdsToFetch);
        const allCircuitIds = combinedCircuitIdsToFetch.concat(consumedByCircuitIdForNewRevision);
        const input = {};
        input.circuitDesignIds = HelperFunctions.deepClone([
            ...circuitDesigns.map(circuitDesign => circuitDesign.circuitDesignId),
            ...allCircuitIds.filter(circuitDesignId => !!circuitDesignId)
        ]);
        input.fremontBackendClient = this.FremontBackendClient;
        input.auth = this.props.auth;

        return OrderValidation.getAllAssociatedComponentObjects(input,
            this.handleFlashBarMessagesFromChildTabs);
    };

    /**
     * This method is used for fetching site information and updating the siteNames for the order we are on
     */
    fetchOrderSiteInfoAndUpdateSiteNames = async () => {
        try {
            const order = HelperFunctions.deepClone(this.state.order);
            let siteA = this.state.siteOptions.find(site => site.value === order.siteAId);
            let siteZ = this.state.siteOptions.find(site => site.value === order.siteZId);

            // If we cannot locate the order's sites from the siteOptions, we fetch those sites directly
            if (!siteA || (Constants.SERVICE_TYPES.BACKBONE === this.state.order.serviceType && !siteZ)) {
                const siteObjectResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.SITE,
                    [this.state.order.siteAId, this.state.order.siteZId].filter(siteId => !!siteId), this.props.auth
                );

                siteA = siteObjectResponse.sites.find(siteObject => siteObject.siteId === order.siteAId);
                siteZ = siteObjectResponse.sites.find(siteObject => siteObject.siteId === order.siteZId);
            }

            this.setState({
                siteNames: {
                    siteAName: siteA ? siteA.siteName : "",
                    siteZName: siteZ ? siteZ.siteName : ""
                },
                newOrderSiteAOption: {
                    label: siteA ? siteA.siteName : "",
                    value: this.state.order.siteAId,
                    geographicRegion: siteA ? siteA.geographicRegion : "",
                    siteName: siteA ? siteA.siteName : "",
                    site: siteA
                },
                newOrderSiteZOption: {
                    label: siteZ ? siteZ.siteName : "",
                    value: this.state.order.siteZId,
                    geographicRegion: siteZ ? siteZ.geographicRegion : "",
                    siteName: siteZ ? siteZ.siteName : "",
                    site: siteZ
                }
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    };

    /**
     * This functions handles loading data that we only want to load on the original page load and do not
     * want to reload every time a backend is called is made over the course of an order. If a user wants
     * to see the updated data for the information they load here, they will need to refresh the page.
     * Data that is loaded only on original page load:
     *     Site(s)
     *     Tags
     *     ASN
     *     Contact
     */
    loadDataOnOriginalPageLoad = async () => {
        this.fetchOrderSiteInfoAndUpdateSiteNames();
        this.getAllTagItems();
        if (this.state.order.contactId) this.fetchContactInfo();
        if (this.state.order.asnId) this.fetchAsnInformation();
    }

    loadAllResources = async () => {
        // Here we fetch all the contacts for this orders provider so that a new one can be selected
        await this.fetchBatchContactInfo();
        // If the user is not a manager, we don't need to load anything else
        if (!this.state.isUserManager) {
            return;
        }
        // Loading all the resources based on order and service type. We always need bizDevs for changing owners
        this.setState({ loadingResources: true });
        await HelperFunctions.handleAsynchronousCalls([
            this.getResourceByType(Constants.RESOURCE_TYPES.businessDeveloper),
            ...(this.state.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
                ? [this.getResourceByType(Constants.RESOURCE_TYPES.bizops)] : []),
            ...(this.state.order.orderType !== Constants.ORDER_TYPES.DECOMMISSION
                ? [this.getResourceByType(Constants.RESOURCE_TYPES.provisioner)] : []),
            ...(this.state.order.serviceType === Constants.SERVICE_TYPES.BACKBONE
                ? [this.getResourceByType(Constants.RESOURCE_TYPES.backBoneEngineer)] : []),
            ...(this.state.order.serviceType !== Constants.SERVICE_TYPES.BACKBONE
                ? [this.getResourceByType(Constants.RESOURCE_TYPES.ipEngineer)] : [])
        ], this.handleFlashBarMessagesFromChildTabs);
        this.setState({ loadingResources: false });
    };

    /**
     * This function handles loading all of the data required for all of the components
     * on the orderDetails page to work correctly.
     */
    loadData = async (runWorkflow, runComponents, useFremontRequestor) => {
        this.setState({
            isDataLoaded: false,
            isAnyEditModeEnabled: true
        });

        // This functions runs the workflow API which returns both the order and all related circuitObjects.
        // We have to run this first so that we have the order and circuitDesign objects before making any other calls
        if (runWorkflow) {
            await this.updateAllOrderAndCircuitInformation(useFremontRequestor);
        }

        // If the call only wants to run the workflow, we do not load the attachments or components
        if (runComponents) {
            // Here we call a helper function which handles making multiple asynchronous calls at once
            await HelperFunctions.handleAsynchronousCalls([
                this.fetchAllAttachments(),
                this.generateComponentIdToObjectMap()
            ], this.handleFlashBarMessagesFromChildTabs);
        }

        const isOrderInProgress = !this.state.isOrderCancelled && !this.state.isOrderCompleted;
        const isOrderHeaderInEdit =
            this.state.isEditingPriority || this.state.isEditingSiteA || this.state.isEditingSiteZ;
        this.setState({
            isDataLoaded: true,
            isAnyEditModeEnabled: !isOrderInProgress || isOrderHeaderInEdit
        });
    };

    /**
     * This helper function runs the workflow for order and all circuitDesigns
     */
    updateAllOrderAndCircuitInformation = async (useFremontRequestor) => {
        this.setState({ circuitDesignsLoading: true });
        try {
            // The first time we call the workflow on a page, we want to set the requestor as "fremont". This ensures
            // that any audits attributed to the workflow are not attached to the user who loaded onto the page, but
            // rather the system itself. While there should never be a case where an order has its workflow updated
            // on page load, this is the fail-safe to ensure no confusing audits are created
            const authToPass = HelperFunctions.deepClone(this.props.auth);
            if (useFremontRequestor) {
                authToPass.userId = Constants.FREMONT;
            }
            const updateWorkflowResponse = await this.FremontBackendClient.updateWorkflowInfo(
                this.props.match.params.orderId, authToPass
            );
            // Here we load the blockers and related notes. We need to load the blockers here because we determine
            // which circuits are currently blocked below
            const { order } = updateWorkflowResponse;
            if (order.blockerIdList.length > 0) {
                await this.fetchBlockersAndRelatedNotes(order.blockerIdList);
            }

            HelperFunctions.sortCircuitDesigns(updateWorkflowResponse.circuitDesigns);
            const circuitDesignOptions = [];
            updateWorkflowResponse.circuitDesigns
                .forEach((circuitDesign) => {
                    circuitDesignOptions
                        .push({
                            [PolarisUtils.OPTION_VALUE_KEY]: circuitDesign.circuitDesignId,
                            [PolarisUtils.OPTION_LABEL_KEY]: circuitDesign.circuitDesignNumber
                        });
                });

            this.setState({
                order: HelperFunctions.appendMetaToItem(order, Constants.NCIS_ROUTES.order),
                circuitDesignObjects: updateWorkflowResponse.circuitDesigns,
                circuitDesignsLoading: false,
                circuitDesignOptions,
                isOrderCompleted: HelperFunctions.isOrderCompleted(order.stageStatusMap),
                isOrderCancelled: HelperFunctions.isOrderCancelled(order.stageStatusMap),
                isNCISOrder: HelperFunctions.isNCISOrder(order)
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { circuitDesignsLoading: false, invalidOrder: true });
        }
    };

    /**
     * This function fetches every blocker item that gets returned from order in Fremont
     * so that only those necessary blockers are rendered.
     */
    fetchBlockersAndRelatedNotes = async (blockerIdList) => {
        this.setState({ blockersLoading: true });
        try {
            const blockersResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.BLOCKER, blockerIdList, this.props.auth
            );
            const { blockers } = blockersResponse; // Destructuring to keep lint happy

            // Now that we have the blockers, we can fetch the related notes
            let blockerNotes = [];
            const allNoteIds = blockers.reduce((noteList, blocker) => noteList.concat(blocker.noteIdList), []);
            if (allNoteIds.length > 0) {
                const notesResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.NOTE, allNoteIds, this.props.auth
                );
                blockerNotes = notesResponse.notes;
            }
            this.setState({
                blockers, blockerNotes, blockersLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { blockersLoading: false });
        }
    };

    /**
     * This method is used for fetching contact information
     */
    fetchContactInfo = async () => {
        try {
            const response = await this.FremontBackendClient.getContactInfo(
                this.state.order.contactId, this.props.auth
            );
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                orderContact: `${response.contact.firstName} ${response.contact.lastName}`,
                orderContactEmail: response.contact.email
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    };

    /**
     * This method is used for fetching batch contact information. We use this to fetch all contacts
     * for a provider when a user enters edit mode that they can select a new contact from that provider.
     */
    fetchBatchContactInfo = async () => {
        try {
            const getProviderResponse = await this.FremontBackendClient.getProviderInfo(this.state.order.providerName,
                this.props.auth);

            // Obtain all of the contact objects linked to the provider
            const getBatchContactResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, getProviderResponse.provider.contactIdList, this.props.auth
            );
            const activeContacts = getBatchContactResponse.contacts
                .filter(contact => contact.providerServiceIdList.includes(this.state.order.providerServiceId))
                .filter(contact => Constants.STATUS.active === contact.status);

            // Set the necessary states to display the reformatted response in the order edit mode
            this.setState({
                // When we display contact options, we can only display those who currently support the
                // providerService this order is attached to. Once we have located those contact, we create the
                // appropriate options
                contactOptions: activeContacts.map(contact => ({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact.contactId,
                    email: contact.email
                }))
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching asn information based upon an asn number
     */
    fetchAsnInformation = async () => {
        this.setState({ asnLoading: true });
        try {
            const asnObjectResponse = await this.FremontBackendClient.getAsnInfo(this.state.order.asnId,
                this.props.auth);
            this.setState({ asn: asnObjectResponse.asn, asnLoading: false });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { asnLoading: false });
        }
    };

    fetchAllAttachments = async () => {
        const attachmentIdSet = new Set();

        const circuitDesignAttachments = [];
        const orderAttachments = [];

        // Here we add all of the attachmentIds present on the order
        Object.values(this.state.order.attachmentIdMap).forEach((attachmentIdList) => {
            attachmentIdList.forEach((attachmentId) => {
                attachmentIdSet.add(attachmentId);
            });
        });

        // While attachments have a list of entities on them, we don't know which attachments we need. So we need all
        // the unique attachment ids on our circuit designs
        this.state.circuitDesignObjects.forEach((circuitDesign) => {
            Object.keys(circuitDesign.attachmentIdMap).forEach(attachmentType =>
                circuitDesign.attachmentIdMap[attachmentType]
                    .forEach((attachmentId) => { attachmentIdSet.add(attachmentId); }));
        });

        // Add attachments to our state if we got any back for the circuits. Otherwise we have an empty list
        if (attachmentIdSet.size > 0) {
            try {
                this.setState({ circuitDesignAttachmentsLoading: true });

                const batchAttachmentObjectResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.ATTACHMENT, Array.from(attachmentIdSet.keys()), this.props.auth
                );
                // Here we iterate through each attachment and apply the necessary fields based on whether it
                // is an order or circuitDesign level attachment
                batchAttachmentObjectResponse.attachments.forEach((attachment, index) => {
                    const attachmentClone = HelperFunctions.deepClone(attachment);
                    attachmentClone.hasAttachmentBeenModified = false; // To help isolate attachments to submit
                    // The only two kinds of attachments that are loaded on the orderDetails page are order
                    // attachments and circuitDesign attachments
                    if (attachment[Constants.ATTRIBUTES.entityType] === Constants.ORDER_ENTITY_TYPE) {
                        attachmentClone.errorTexts = OrderValidation.EMPTY_ATTACHMENT_ERROR_TEXTS;
                        attachmentClone.attachmentFormKey = `attachmentForm${index}`;
                        orderAttachments.push(attachmentClone);
                    } else {
                        attachmentClone.selectedOptions = this.state.circuitDesignOptions
                            .filter(option => attachmentClone.entityIdList.includes(option.value));
                        attachmentClone.errorTexts = OrderValidation.EMPTY_ATTACHMENT_ERROR_TEXTS;
                        circuitDesignAttachments.push(attachmentClone);
                    }
                });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, {
                    circuitDesignAttachmentsLoading: false,
                    orderAttachmentsLoading: false
                });
            }
        }

        // Sort the attachments by attachment type so they are grouped in the modal and are easier to follow
        // Also add the react Ref so that we can upload a file.
        const attachmentFormToReactRefMap = {};
        HelperFunctions.sortObjectsByField(circuitDesignAttachments, "attachmentType");
        circuitDesignAttachments
            .forEach((attachment, index) => {
                Object.assign(attachment, { attachmentFormKey: `attachmentForm${index}` });
                attachmentFormToReactRefMap[attachment.attachmentFormKey] = React.createRef();
            });

        // Keep this outside of the try/catch block just in case there are no attachments
        this.setState({
            circuitDesignAttachments,
            orderAttachments,
            attachmentFormToReactRefMap,
            circuitDesignAttachmentsClone: HelperFunctions.deepClone(circuitDesignAttachments),
            circuitDesignAttachmentsLoading: false,
            orderAttachmentsLoading: false
        });
    };

    /**
     * This method fetches every unique item stored in the position map of every circuitDesign. It then creates a
     * map that contains all of the unique IDs from the position maps and connects them to their object. This
     * should be called after every component submit
     */
    generateComponentIdToObjectMap = async () => {
        this.setState({ loadingComponentIdToObjectMap: true });

        const circuitDesigns = this.state.circuitDesignObjects;
        const componentIdToObjectMap = await this.getComponentsForCircuits(circuitDesigns);

        this.setState({
            componentIdToObjectMap
        });

        // Along with getting all the components, we'll load in the work order and the span separately so that we
        // can easily pass it to the child components without requiring it to make any calls
        let workOrder = {};
        let span = {};
        if (circuitDesigns.some(circuitDesign => circuitDesign[Constants.ATTRIBUTES.billingSegmentId])) {
            try {
                // Get all the billing segments on the circuit
                const billingSegmentIds = circuitDesigns
                    .filter(circuit => !!circuit[Constants.ATTRIBUTES.billingSegmentId])
                    .map(circuit => circuit[Constants.ATTRIBUTES.billingSegmentId]);
                const billingSegmentResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.BILLING_SEGMENT, Array.from(billingSegmentIds), this.props.auth
                );

                // Get the span and work order from the component map
                const spanIdsOnBillingSegments = Array.from(new Set(billingSegmentResponse.billingSegments
                    .map(billingSegment => billingSegment[Constants.ATTRIBUTES.spanId])));
                const spanId = spanIdsOnBillingSegments.find(Boolean);
                span = HelperFunctions.deepClone(componentIdToObjectMap[spanId]);
                const workOrderId = span[Constants.ATTRIBUTES.workOrderId];
                workOrder = HelperFunctions.deepClone(componentIdToObjectMap[workOrderId]);

                // Set the state so child components can use this information
                this.setState({
                    workOrder,
                    span
                });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, {
                    loadingComponentIdToObjectMap: false
                });
            }
        }

        this.setState({
            loadingComponentIdToObjectMap: false
        });
    };

    updateComponentIdToObjectMap = async (updatedCircuitDesign) => {
        this.setState({ loadingComponentIdToObjectMap: true });
        let componentIdToObjectMap = HelperFunctions.deepClone(this.state.componentIdToObjectMap);

        const updatedCircuitComponentIdToObjectMap = await this.getComponentsForCircuits([updatedCircuitDesign]);
        componentIdToObjectMap = Object.assign(componentIdToObjectMap, updatedCircuitComponentIdToObjectMap);

        this.setState({
            loadingComponentIdToObjectMap: false,
            componentIdToObjectMap
        });
    };

    /**
     * This function creates the tab map based upon the stages for the order
     */
    createTabMap = () => {
        // The tab map holds each tab found in the workflow map. Inside of each tab, each stage to be displayed
        // is stored, along with the order in the tab the stage should be displayed
        const tabMap = {};
        // For each stage entry, we add the stage and its order in the tab to the tab map
        Object.values(this.state.order.workflow.stages).forEach(stage => (
            // If the tab already has a value in the tab map, we append it with the current stage and the stage's
            // order in the tab
            tabMap[stage.tab] ?
                Object.assign(tabMap[stage.tab], { [stage.orderInTab]: stage.stageName })
                // If the tab does not yet have a value in the tab map, we create the tab with the current stage
                // and the stage's order in the tab
                :
                Object.assign(tabMap, { [stage.tab]: { [stage.orderInTab]: stage.stageName } })));
        this.setState({
            tabMap
        });
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this);
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        const tabId = evt.detail.activeTabId;
        // We only load components if the tab ID is not the note or audit tab
        if (!this.state.firstTabChange && ![Constants.ORDER_TAB_IDS.AUDIT_TAB_ID, Constants.ORDER_TAB_IDS.NOTE_TAB_ID
        ].includes(evt.detail.activeTabId)) {
            // We've already run the workflow, so all we need to do now is fetch the components
            this.loadData(false, true);
            this.setState({
                firstTabChange: true
            });
        }
        HelperFunctions.dismissFlashbar(this, { activeTabId: tabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        }

        const isOrderInProgress = !this.state.isOrderCancelled && !this.state.isOrderCompleted;
        const isOrderHeaderInEdit =
            this.state.isEditingPriority || this.state.isEditingSiteA || this.state.isEditingSiteZ;
        if (Object.keys(Constants.TAB_TO_POSIX_GROUP_MAP).includes(evt.detail.activeTabId)) {
            this.setState({
                isTabEditable: HelperFunctions.determineIfTabIsEditable(this.props.user.permissions, tabId),
                isAnyEditModeEnabled: !isOrderInProgress || !this.state.isDataLoaded || isOrderHeaderInEdit,
                isUpdateOrderInfoEditClicked: false
            });
        }
    };

    goToComponent = (tableComponent, workflow, stageName) => {
        // The tableComponent passed in is the component we want to navigate to
        // We are also ignoring the no-param-reassign rule since this is changing the styling of a DOM element
        this.handleTabChange({
            detail: {
                activeTabId: workflow.stages[stageName].tab
            }
        });

        // For some reason newer version of react is doing things a little differently,
        // we must wait for the rendering to finish in order to access the ref's div
        window.requestAnimationFrame(() => {
            // Scroll to the section
            tableComponent.ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });

            // The animation for some reason doesn't reset correctly when I try to reflow with react (unsure why)
            // https://stackoverflow.com/a/45036752/4392915
            // So as a hack I'm just changing the state of the component to make sure it re-renders the animation
            // (yes toggling this twice is intentional since I don't actually want to the change the value)

            // Commenting this out for now, as it is causing weird rendering issues with new React update.
            // this.setState({ loading: !this.state.loading });
            // this.setState({ loading: !this.state.loading });

            // This calls the `animate-color` @keyframes animation defined in index.css
            // Basically its starts at a color (yellow) and moves to another (white) in 3 seconds (if we wanted white
            // to yellow, we would use `backwards` as opposed to `forwards`). Check out index.css for more information
            // eslint-disable-next-line no-param-reassign
            tableComponent.ref.current.style.animation = "animate-color 3s forwards";
        });
    };

    /**
     * This function creates a sort of progress bar with clickable buttons to all the stages
     * @returns Progress buttons or Completion Alert
     */
    displayStages = () => {
        if (this.state.isOrderCompleted) {
            return (
                <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <FremontAlert header="Order Completed!" type="success"/>
                    {this.displayCircuitRevisionInfo()}
                </SpaceBetween>
            );
        }
        if (this.state.isOrderCancelled) {
            return (
                <FremontAlert header="Order Cancelled" type="error"/>
            );
        }
        const completedStages = [];
        const inProgressStages = [];
        const notStartedStages = [];

        const stageStatusMap = Object.assign({}, this.state.order.stageStatusMap);
        // We do not want to show the complete order stage in the progress bar, so we delete it here
        delete stageStatusMap[Constants.STAGE_NAMES.completeOrder];
        const workflow = Object.assign({}, this.state.order.workflow);

        // Perform a topological sort of workflow
        // https://www.tutorialspoint.com/Topological-sorting-using-Javascript-DFS
        const topologicalSortHelper = (stageName, explored, sortedStages) => {
            explored.add(stageName);
            // Marks this stage as visited and goes on to the stages
            // that are dependent on this stage, the edge is stageName ----> nextStageName
            if (workflow.stages[stageName].nextStages) {
                workflow.stages[stageName].nextStages.forEach((nextStageName) => {
                    if (!explored.has(nextStageName)) {
                        topologicalSortHelper(nextStageName, explored, sortedStages);
                    }
                });
            }
            // All dependencies are resolved for this node, we can now add
            // This to the stack.
            sortedStages.unshift(stageName);
        };
        const sortedStages = [workflow.stages.length];
        const explored = new Set();
        Object.keys(workflow.stages).forEach((stageName) => {
            if (!explored.has(stageName)) {
                topologicalSortHelper(stageName, explored, sortedStages);
            }
        });

        if (Constants.INTERNAL_AMAZON_PROVIDER === this.state.order.providerName) {
            delete sortedStages[sortedStages.findIndex(stageName =>
                stageName === Constants.STAGE_NAMES.workOrderExecution)];
        }
        sortedStages.forEach((stage) => {
            if (HelperFunctions.isStageCompleted(stageStatusMap[stage])) {
                completedStages.push((
                    <FremontButton
                        key={stage}
                        iconName="status-positive"
                        onClick={() =>
                            this.goToComponent(Constants.DEPENDENCY_LINK_REFERENCES[stage],
                                this.state.order.workflow, stage)
                        }
                    >
                        {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stage]}
                    </FremontButton>
                ));
            }
            if (HelperFunctions.isStageInProgress(stageStatusMap[stage])) {
                inProgressStages.push((
                    <FremontButton
                        key={stage}
                        iconName="status-in-progress"
                        onClick={() =>
                            this.goToComponent(Constants.DEPENDENCY_LINK_REFERENCES[stage],
                                this.state.order.workflow, stage)
                        }
                    >
                        {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stage]}
                    </FremontButton>
                ));
            }
            if (HelperFunctions.isStageNotStarted(stageStatusMap[stage])) {
                notStartedStages.push((
                    <FremontButton
                        key={stage}
                        iconName="status-pending"
                        onClick={() =>
                            this.goToComponent(Constants.DEPENDENCY_LINK_REFERENCES[stage],
                                this.state.order.workflow, stage)
                        }
                    >
                        {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stage]}
                    </FremontButton>
                ));
            }
        });
        if (inProgressStages.length !== 0) {
            return (
                <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <div>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Completed:</span>{completedStages}
                            </SpaceBetween>
                        </Box>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>In Progress:</span>{inProgressStages}
                            </SpaceBetween>
                        </Box>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Not Started:</span>{notStartedStages}
                            </SpaceBetween>
                        </Box>
                    </div>
                </SpaceBetween>
            );
        }
        // If there are no more in progress stages but the order is not completed, we display an alert for the user
        // to click which will allow them to complete the order.
        return (
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <FremontExpandableSection
                    variant="container"
                    header={<Header variant="h2">Stages</Header>}
                    defaultExpanded={false}
                >
                    <div>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Completed: </span>{completedStages}
                            </SpaceBetween>
                        </Box>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>In Progress: </span>{inProgressStages}
                            </SpaceBetween>
                        </Box>
                        <Box variant="h2">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Not Started: </span>{notStartedStages}
                            </SpaceBetween>
                        </Box>
                    </div>
                </FremontExpandableSection>
                {HelperFunctions.hasActiveBlocker(this.state.blockers) ?
                    <FremontAlert
                        header="Blocked Order"
                        type="error"
                    >
                        This order has active blockers, please resolve them before completing the order.
                    </FremontAlert>
                    :
                    <FremontAlert
                        header="Complete Order"
                        type="error"
                        action={(
                            <FremontButton
                                id={OrderValidation.ORDER_PAGE_MODAL_TYPES.completion}
                                onClick={this.showModal}
                            >
                                Complete Order
                            </FremontButton>
                        )}
                    >
                        This order is still in progress. Click here to complete it.
                    </FremontAlert>
                }
            </SpaceBetween>
        );
    };

    /**
     * This method handles adding a tag to an order
     */
    handleAddTagToOrder = async (evt) => {
        try {
            // If a selectedOption is not in the event, there is no tag to act upon, so we exit this function
            if (!evt.detail.selectedOption) {
                return;
            }
            // add this orderId to tag's orderIdList
            // get this tag from tagItems
            const tagToAdd = HelperFunctions.deepClone(this.state.tagItems.find(tag =>
                tag.tagId === evt.detail.selectedOption.value));
            // if orderId already in tag's orderIdList, then return.
            if (tagToAdd.orderIdList.includes(this.state.order.orderId)) {
                return;
            }

            const tagToSubmit = {
                [Constants.ATTRIBUTES.tagId]: tagToAdd.tagId,
                [Constants.ATTRIBUTES.orderIdListToAddFromRequest]: [this.state.order.orderId]
            };

            this.setState({ isTagSubmissionInProgress: true, isAnyEditModeEnabled: true });
            await this.FremontBackendClient.modifyTag([tagToSubmit], this.props.auth);

            // Reload all the data to avoid stale data exceptions (since the order has been updated)
            await this.getAllTagItems();
            await this.getOrder();
            this.setState({ isTagSubmissionInProgress: false, isAnyEditModeEnabled: false });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isTagSubmissionInProgress: false,
                isAnyEditModeEnabled: false
            });
        }
    };

    /**
     * This method handles removing a tag from an order
     */
    handleRemoveTagFromOrder = async (evt) => {
        this.setState({ isTagSubmissionInProgress: true, isAnyEditModeEnabled: true });
        try {
            const tagIdToRemove = this.state.order.tagIdList[evt.detail.itemIndex];
            // Since we have the tagId, all we need to do is provide the orderId
            // to remove in the orderIdListToRemoveFromRequest
            const tagToSubmit = {
                [Constants.ATTRIBUTES.tagId]: tagIdToRemove,
                [Constants.ATTRIBUTES.orderIdListToRemoveFromRequest]: [this.state.order.orderId]
            };

            await this.FremontBackendClient.modifyTag([tagToSubmit], this.props.auth);

            // Reload all the data to avoid stale data exceptions (since the order has been updated)
            await this.getAllTagItems();
            await this.getOrder();
            this.setState({ isTagSubmissionInProgress: false, isAnyEditModeEnabled: false });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isTagSubmissionInProgress: false,
                isAnyEditModeEnabled: false
            });
        }
    };

    /**
     * This function determines whether or not the orderCompletionModal will pop up or not
     */
    handleOrderCompletionModalPopup = () => {
        // need to prevent the popup if the blockers are being loaded or being submitted
        // otherwise weird user experience
        if (!this.state.isDataLoaded || this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker]) {
            return this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.completion];
        }
        if (!HelperFunctions.hasActiveBlocker(this.state.blockers) &&
            HelperFunctions.isStageInProgress(
                this.state.order[Constants.ATTRIBUTES.stageStatusMap][Constants.STAGE_NAMES.completeOrder]
            ) && !this.state.hasOrderCompletionModalBeenShownOnce
        ) {
            this.showModal({ target: { id: OrderValidation.ORDER_PAGE_MODAL_TYPES.completion } });
        }
        return this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.completion];
    };

    /**
     * This function determines whether or not the site change confirmation modal will pop up or not
     */
    handleOrderSiteChangeModalPopup = async () => {
        // If the site is not changing, then we do not need to send out a request
        const { order } = this.state;
        const oldSiteAId = order[Constants.ATTRIBUTES.siteAId];
        const newSiteAId = this.state.newOrderSiteAOption.value;

        const oldSiteZId = order[Constants.ATTRIBUTES.siteZId];
        const newSiteZId = this.state.newOrderSiteZOption.value;

        if (oldSiteAId === newSiteAId && oldSiteZId === newSiteZId) {
            this.resetEditingSiteLoadingVariables();
            return;
        }

        // We only show the site change modal for backbone span orders that have a parent circuit for one of the circuit
        // on the order
        if (HelperFunctions.isOrderSpanOrder(order)
            && HelperFunctions.doCircuitsHaveParents(this.state.circuitDesignObjects)) {
            this.showModal({ target: { id: OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange } });
        } else {
            await this.submitOrderSiteChange();
        }
    };

    /**
     * This function handles submitting an order for completion. We set the order to completed but also update each
     * circuit design to have an lifeCycleStage of "Active" as the circuits are now active on the network
     */
    handleSubmitOrderCompletion = async () => {
        this.setState({ isOrderCompletionInProgress: true });
        const updatedOrder = HelperFunctions.deepClone(this.state.order);
        updatedOrder[Constants.ATTRIBUTES.isOrderCompletedFromRequest] = true;

        try {
            // Here we make a backend call to update the order
            const updateOrderResponse = await this.FremontBackendClient.updateOrderInfo(
                updatedOrder, this.state.order, this.props.auth
            );

            await this.loadData(true, true);
            // Set the state of the completed order. Since nothing will be editable we don't need to reload data here
            this.setState({
                order: HelperFunctions.appendMetaToItem(updateOrderResponse, Constants.NCIS_ROUTES.order),
                isOrderCompleted: HelperFunctions.isOrderCompleted(updateOrderResponse.stageStatusMap)
            });
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                isOrderCompletionInProgress: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isOrderCompletionInProgress: false });
        }
        this.hideModal({
            target: {
                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.completion
            }
        });
    };

    /**
     * This function handles submitting an order for cancel.
     */
    handleSubmitOrderCancel = async () => {
        this.setState({ isOrderCancelInProgress: true });
        try {
            // Here we make a backend call to update the order
            await this.FremontBackendClient.cancelOrder([this.state.order], this.props.auth);

            await this.loadData(true, true);
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                isOrderCancelInProgress: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isOrderCancelInProgress: false });
        }
        this.hideModal({
            target: {
                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.cancel
            }
        });
    };

    /**
     * This method handles completing stage toggle button.
     */
    handleToggleCompleteStage = async (evt) => {
        const stage = evt.target.id;
        // convert the first letter of `stage` to -> `Stage` and make it like this -> hasStageBeenCompleted
        const orderField = `has${stage.charAt(0).toUpperCase() + stage.slice(1)}BeenCompleted`;

        // Here we update the parent property isAnyEditModeEnabled and set it to true.
        // This is to ensure that a user who toggles the button cannot
        // quickly navigate to another stage and submit, causing parallel submissions
        this.setIsAnyEditModeEnabled(true);
        const updatedOrder = HelperFunctions.deepClone(this.state.order);
        // Here we switch the boolean orderField value
        updatedOrder[orderField] = !updatedOrder[orderField];

        try {
            // Here we submit a request to update the order
            await this.FremontBackendClient.updateOrderInfo(
                updatedOrder, this.state.order, this.props.auth
            );
            this.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false);
        } catch (error) {
            // Display error message
            this.handleFlashBarMessagesFromChildTabs(false, error, false);
        }

        await this.loadData(true, false);
    };

    showModal = (evt) => {
        const uniqueId = evt.target.id.split(Constants.SEPARATOR).find(el =>
            el.includes(Constants.UUID_SEPARATOR));
        const attributeId = evt.target.id.split(Constants.SEPARATOR).find(el =>
            !el.includes(Constants.UUID_SEPARATOR));
        const isModalClicked = HelperFunctions.deepClone(this.state.isModalClicked);
        if (attributeId === OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment) {
            const circuitDesignAttachmentsClone = HelperFunctions.deepClone(this.state.circuitDesignAttachments);
            this.setState({
                circuitDesignAttachmentsClone
            });
        }
        if (attributeId === OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker) {
            this.setState({
                clickedBlockerId: uniqueId
            });
        }
        if (attributeId === OrderValidation.ORDER_PAGE_MODAL_TYPES.completion) {
            this.setState({
                hasOrderCompletionModalBeenShownOnce: true
            });
        }
        this.setState({
            isModalClicked: Object.assign(isModalClicked, {
                [attributeId]: true
            })
        });
    }

    hideModal = (evt) => {
        const attributeId = evt.target.id;
        const isModalClicked = HelperFunctions.deepClone(this.state.isModalClicked);
        this.setState({
            isModalClicked: Object.assign(isModalClicked, {
                [attributeId]: false
            }),
            // If we are hiding the modal for the order site change, we also need to unset the edit button so that other
            // parts of the order details page are editable
            isEditingSiteA: false,
            isEditingSiteZ: false,
            isAnyEditModeEnabled: false
        });
    }

    fetchSiteOptions = async () => {
        const siteOptions = HelperFunctions.deepClone(this.state.siteOptions);

        if (siteOptions.length === 0) {
            this.setState({
                siteOptionsLoading: true
            });
            try {
                const allSiteItems = await HelperFunctions.getAllSiteItems(this.props.auth);

                // Here we add all the sites to the list of site options
                allSiteItems.forEach(siteOption =>
                    siteOptions.push(Object.assign(siteOption, {
                        geographicRegion: siteOption.site.geographicRegion,
                        siteName: siteOption.site.siteName
                    })));
                HelperFunctions.sortObjectsByField(siteOptions, "label");

                this.setState({
                    siteOptions
                });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error);
            }

            // Here we set the siteOptionsLoading to false whether the call succeeded or failed
            this.setState({
                siteOptionsLoading: false
            });
        }
    }

    downloadAttachment = async (attachmentId) => {
        this.setState({ isDownloadingAttachment: true });
        try {
            const getAttachmentResponse = await this.FremontBackendClient.getAttachmentInfo(attachmentId,
                this.props.auth);

            // I wanted to use XMLHttpRequest, since that is what we use to upload the files, but even the presence of
            // the Content-Disposition didn't help in downloading the file. So the workaround here is creating an <a>
            // element and "clicking" it to download in the browser. The <a> element is simply an anchor element that
            // contains and href element. We assign our presigned url as the href of this newly created element and
            // thats all it takes to download it.
            // More on it not working with XMLHttpRequest: https://stackoverflow.com/a/22738657
            fetch(getAttachmentResponse.presignedUrl).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.download = getAttachmentResponse.attachment.fileName;
                    anchorElement.click();
                    anchorElement.remove();

                    this.setState({ isDownloadingAttachment: false });
                });
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this,
                { message: "Unable to download the attachment." }, { isDownloadingAttachment: false });
        }
    };

    showOrderPrioritySelectionOptions = () => {
        this.setState({
            isEditingPriority: true,
            newOrderPriority: this.state.order.priorityType,
            isAnyEditModeEnabled: true
        });
    };

    handlePriorityChangeEvent = (evt) => {
        const newOrderPriority = evt.detail.selectedOption[PolarisUtils.OPTION_VALUE_KEY];

        this.setState({ newOrderPriority });
    };

    showOrderSiteSelectionOptions = async (evt) => {
        if (evt.target.id === Constants.ATTRIBUTES.siteAId) {
            this.setState({
                isEditingSiteA: true
            });
        } else {
            this.setState({
                isEditingSiteZ: true
            });
        }

        this.setState({ isAnyEditModeEnabled: true });
        await this.fetchSiteOptions();
        this.resetNewSiteOptions(); // We should clear out the site options so that any errors will reset the state
    };

    resetNewSiteOptions = () => {
        // Reset the site options
        const siteA = this.state.siteOptions.find(site => site.value === this.state.order.siteAId);
        const siteZ = this.state.siteOptions.find(site => site.value === this.state.order.siteZId);

        // Reset the options here
        this.setState({
            newOrderSiteAOption: {
                label: siteA ? siteA.siteName : "",
                value: this.state.order.siteAId,
                geographicRegion: siteA ? siteA.geographicRegion : "",
                siteName: siteA ? siteA.siteName : "",
                site: siteA
            },
            newOrderSiteZOption: {
                label: siteZ ? siteZ.siteName : "",
                value: this.state.order.siteZId,
                geographicRegion: siteZ ? siteZ.geographicRegion : "",
                siteName: siteZ ? siteZ.siteName : "",
                site: siteZ
            }
        });
    };

    handleSiteChangeEvent = (evt) => {
        if (evt.target.id === Constants.ATTRIBUTES.siteAId) {
            this.setState({ newOrderSiteAOption: evt.detail.selectedOption });
        } else {
            this.setState({ newOrderSiteZOption: evt.detail.selectedOption });
        }
    };

    displaySiteName = (siteName) => {
        if (Constants.ATTRIBUTES.siteAId === siteName) {
            return this.state.siteNames.siteAName ?
                <Link
                    href={`${Constants.ROUTES.site}/${
                        this.state.order.siteAId
                    }`}
                >
                    {this.state.siteNames.siteAName}
                </Link>
                :
                "-";
        }
        return this.state.siteNames.siteZName ?
            <Link
                href={`${Constants.ROUTES.site}/${
                    this.state.order.siteZId
                }`}
            >
                {this.state.siteNames.siteZName}
            </Link>
            :
            "-";
    };

    displayCircuitRevisionInfo = () => {
        // If any of the circuits are deprecated, there is a future active revision
        if (this.state.circuitDesignObjects.some(circuit =>
            circuit[Constants.ATTRIBUTES.lifeCycleStage] === Constants.LIFECYCLE_STAGES.deprecated)) {
            return (
                <FremontAlert
                    header={
                        <div>
                            <Box fontWeight="bold">
                                {Constants.CIRCUIT_HAS_FUTURE_ACTIVE_REVISIONS}
                            </Box>
                            <Box>
                                See the Circuit Design tab for the latest revisions.
                            </Box>
                        </div>
                    }
                    type="info"
                />
            );
        }
        return (
            <FremontAlert
                header={
                    <div>
                        <Box fontWeight="bold">
                            {Constants.CIRCUIT_HAS_NO_FUTURE_ACTIVE_REVISIONS}
                        </Box>
                        <Box>
                            {
                                // If some of the circuits have future revisions, we let user know there are
                                // in progress revisions of the circuit out
                                this.state.circuitDesignObjects.some(circuit =>
                                    circuit[Constants.ATTRIBUTES.futureCircuitDesignRevisionsIdList].length > 0)
                                    ? "Some circuits on the order have future revisions that are in progress or"
                                    + " cancelled. They can be seen in the Circuit Design tab."
                                    : "See the Circuit Design tab for the previous revisions (if any)."
                            }
                        </Box>
                    </div>
                }
                type="success"
            />
        );
    };

    loadAllResourcesCb = async () => {
        if (!this.state.firstOrderEditClick) {
            await this.loadAllResources();
            this.setState({
                firstOrderEditClick: true,
                loadingResources: false
            });
        }
    };

    handleUpdateOrderEdit = async () => {
        this.handleFlashbarClose();
        this.setState({
            isUpdateOrderInfoEditClicked: !this.state.isUpdateOrderInfoEditClicked,
            isAnyEditModeEnabled: !this.state.isUpdateOrderInfoEditClicked,
            updatedOrder: this.state.order
        }, this.loadAllResourcesCb);
    };

    /**
     * This function handles change of inputs to the order information container fields.
     */
    handleOrderInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        // We need to provide the errorTexts to the validateInput function even though
        // we do not display any error messages to avoid an NPE
        input.orderErrorTexts = {};
        const output = OrderValidation.validateInput(input);
        this.setState({
            updatedOrder: output.order
        });
    }

    handleSubmitUpdateOrderInfo = async () => {
        try {
            this.setState({ isUpdateOrderInfoInProgress: true });
            // Update the order and fetch all its related entities again
            const updatedOrder = await this.FremontBackendClient.updateOrderInfo(
                this.state.updatedOrder, this.state.order, this.props.auth
            );

            if (updatedOrder.contactId) { // Change orders do not have contacts
                const newContact = this.state.contactOptions.find(contactOption =>
                    contactOption.value === updatedOrder.contactId);
                this.setState({
                    orderContact: newContact.label,
                    orderContactEmail: newContact.email
                });
            }
            await this.loadData(true, true);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isUpdateOrderInfoInProgress: false,
            isUpdateOrderInfoEditClicked: false,
            isAnyEditModeEnabled: false
        });
    }

    submitOrderPriorityChange = async () => {
        const updatedOrder = HelperFunctions.deepClone(this.state.order);
        updatedOrder[Constants.ATTRIBUTES.priorityType] = this.state.newOrderPriority;
        try {
            this.setState({ isPrioritySubmissionInProgress: true });
            // Update the order and fetch all its related entities again
            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.state.order, this.props.auth);
            await this.loadData(true, true);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isPrioritySubmissionInProgress: false,
            isEditingPriority: false,
            isAnyEditModeEnabled: false
        });
    };

    submitOrderSiteChange = async () => {
        const updatedOrder = HelperFunctions.deepClone(this.state.order);
        updatedOrder[Constants.ATTRIBUTES.siteAId] = this.state.newOrderSiteAOption.value;
        updatedOrder[Constants.ATTRIBUTES.siteZId] = this.state.newOrderSiteZOption.value;
        try {
            this.setState({ isSiteSubmissionInProgress: true });

            // Update the order and fetch all its related entities again
            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.state.order, this.props.auth);
            await this.loadData(true, true);

            // In case the confirmation modal for the site change is up, we want to hide it
            this.hideModal({
                target: {
                    id: OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange
                }
            });

            this.fetchOrderSiteInfoAndUpdateSiteNames();
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.resetEditingSiteLoadingVariables();
    };

    resetEditingSiteLoadingVariables = () => {
        this.setState({
            isSiteSubmissionInProgress: false,
            isEditingSiteA: false,
            isEditingSiteZ: false,
            isAnyEditModeEnabled: false
        });

        // Incase the confirmation modal for the site change is up, we want to hide it
        this.hideModal({
            target: {
                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange
            }
        });
    };

    /**
     * Helper method to determine when an order's cancellation button should be disabled
     */
    isOrderCancellationDisabled = () => this.state.isOrderCancelInProgress
            || !this.state.isDataLoaded || this.state.isAnyEditModeEnabled
            || (
                this.state.circuitDesignObjects.some(circuit =>
                    circuit[Constants.ATTRIBUTES.lifeCycleStage]
                        === Constants.LIFECYCLE_STAGES.decommissioned)
            )

    canShowProviderChangeModalEditButton = () =>
        !this.state.isOrderCompleted
        && !this.state.isOrderCancelled
        && !this.state.isAnyEditModeEnabled
        && !HelperFunctions.isProviderAmazonInternal(this.state.order.providerName)
        && !HelperFunctions.isOrderDecomOrder(this.state.order);

    /**
     *  Adds sidebar to tab content
     */
    tabContent = tab => (
        <Grid gridDefinition={[{ colspan: { default: 10 } }, { colspan: { default: 2 } }]}>
            <div>
                {tab}
            </div>
            <OrderSidebar
                orderTagIdList={this.state.order.tagIdList}
                handleAddTagToOrder={this.handleAddTagToOrder}
                handleRemoveTag={this.handleRemoveTagFromOrder}
                tagItems={this.state.tagItems}
                tagsLoading={this.state.tagsLoading}
                isTagSubmissionInProgress={this.state.isTagSubmissionInProgress}
                blockers={this.state.blockers}
                isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                blockersLoading={this.state.blockersLoading}
                loading={this.state.loading}
                isOrderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                showModal={this.showModal}
                hideModal={this.hideModal}
                circuitDesignsLoading={this.state.circuitDesignsLoading}
            />
        </Grid>
    );

    updateTabs = () => {
        const tabs = [
            {
                label: "Details",
                id: Constants.ORDER_TAB_IDS.INFO_TAB_ID,
                content: this.tabContent(<OrderInformation
                    order={this.state.order}
                    loadingResources={this.state.loadingResources}
                    bizDevOptions={this.state.bizDevOptions}
                    bizOpsResources={this.state.bizOpsResources}
                    provisionerOptions={this.state.provisionerOptions}
                    engineerOptions={this.state.engineerOptions}
                    contactOptions={this.state.contactOptions}
                    isUserManager={this.state.isUserManager}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    isOrderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    updatedOrder={this.state.updatedOrder}
                    orderContact={this.state.orderContact}
                    isUpdateOrderInfoEditClicked={this.state.isUpdateOrderInfoEditClicked}
                    isUpdateOrderInfoInProgress={this.state.isUpdateOrderInfoInProgress}
                    handleUpdateOrderEdit={this.handleUpdateOrderEdit}
                    handleOrderInputChange={this.handleOrderInputChange}
                    handleSubmitUpdateOrderInfo={this.handleSubmitUpdateOrderInfo}
                />)
            }
        ];
        // Here we add the business developer tab to the to the page if it is present in the tabMap
        if (this.state.tabMap[Constants.ORDER_TAB_IDS.BUSINESS_DEVELOPER_TAB_ID]) {
            tabs.push({
                label: "Business Developer",
                id: Constants.ORDER_TAB_IDS.BUSINESS_DEVELOPER_TAB_ID,
                content: this.tabContent(<OrderBusinessDeveloperTab
                    order={this.state.order}
                    auth={this.state.auth}
                    asn={this.state.asn}
                    asnLoading={this.state.asnLoading}
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    circuitDesignsLoading={this.state.circuitDesignsLoading}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.state.loadingComponentIdToObjectMap}
                    loadData={this.loadData}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    goToComponentAction={this.goToComponent}
                    siteNames={this.state.siteNames}
                    user={this.props.user}
                    isTabEditable={this.state.isTabEditable}
                    stages={this.state.tabMap[Constants.ORDER_TAB_IDS.BUSINESS_DEVELOPER_TAB_ID]}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    blockers={this.state.blockers}
                    setIsAnyEditModeEnabled={this.setIsAnyEditModeEnabled}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    showAttachmentModal={() => this.showModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment
                        }
                    })}
                    attachmentsLoading={this.state.attachmentsLoading}
                    downloadAttachment={this.downloadAttachment}
                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                    isDataLoaded={this.state.isDataLoaded}
                    showTemplateGeneratorModal={() => this.showModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator
                        }
                    })}
                    workOrder={this.state.workOrder}
                    span={this.state.span}
                    siteOptions={this.state.siteOptions}
                    fetchSiteOptions={this.fetchSiteOptions}
                    handleToggleCompleteStage={this.handleToggleCompleteStage}
                />)
            });
        }
        // Here we add the change order creator tab to the to the page if it is present in the tabMap
        if (this.state.tabMap[Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID]) {
            tabs.push({
                label: "Order Creator",
                id: Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID,
                content: this.tabContent(<OrderBusinessDeveloperTab
                    order={this.state.order}
                    auth={this.state.auth}
                    asn={this.state.asn}
                    asnLoading={this.state.asnLoading}
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    circuitDesignsLoading={this.state.circuitDesignsLoading}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.state.loadingComponentIdToObjectMap}
                    loadData={this.loadData}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    goToComponentAction={this.goToComponent}
                    siteNames={this.state.siteNames}
                    user={this.props.user}
                    isTabEditable={this.state.isTabEditable}
                    stages={this.state.tabMap[Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID]}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    blockers={this.state.blockers}
                    setIsAnyEditModeEnabled={this.setIsAnyEditModeEnabled}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    showAttachmentModal={() => this.showModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment
                        }
                    })}
                    attachmentsLoading={this.state.attachmentsLoading}
                    downloadAttachment={this.downloadAttachment}
                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                    isDataLoaded={this.state.isDataLoaded}
                />)
            });
        }
        // Here we add the capacity provisioning tab to the page if it is present in the tabMap
        if (this.state.tabMap[Constants.ORDER_TAB_IDS.CAPACITY_PROVISIONING_TAB_ID]) {
            tabs.push({
                label: "Capacity Provisioning",
                id: Constants.ORDER_TAB_IDS.CAPACITY_PROVISIONING_TAB_ID,
                content: this.tabContent(<OrderCapacityProvisioningTab
                    order={this.state.order}
                    asn={this.state.asn}
                    auth={this.state.auth}
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.state.loadingComponentIdToObjectMap}
                    circuitDesignsLoading={this.state.circuitDesignsLoading}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    goToComponentAction={this.goToComponent}
                    stages={this.state.tabMap[Constants.ORDER_TAB_IDS.CAPACITY_PROVISIONING_TAB_ID]}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    loadData={this.loadData}
                    siteNames={this.state.siteNames}
                    user={this.props.user}
                    isTabEditable={this.state.isTabEditable}
                    blockers={this.state.blockers}
                    setIsAnyEditModeEnabled={this.setIsAnyEditModeEnabled}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    showAttachmentModal={() => this.showModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment
                        }
                    })}
                    attachmentsLoading={this.state.circuitDesignAttachmentsLoading}
                    downloadAttachment={this.downloadAttachment}
                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                    isDataLoaded={this.state.isDataLoaded}
                    showTemplateGeneratorModal={() => this.showModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator
                        }
                    })}
                    handleToggleCompleteStage={this.handleToggleCompleteStage}
                />)
            });
        }
        // Here we add the capacity engineering tab to the page if it is present in the tabMap
        if (this.state.tabMap[Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID]) {
            tabs.push({
                label: "Capacity Engineering",
                id: Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID,
                content: this.tabContent(<OrderCapacityEngineeringTab
                    order={this.state.order}
                    siteNames={this.state.siteNames}
                    siteOptions={this.state.siteOptions}
                    fetchSiteOptions={this.fetchSiteOptions}
                    auth={this.state.auth}
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.state.loadingComponentIdToObjectMap}
                    circuitDesignsLoading={this.state.circuitDesignsLoading}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    goToComponentAction={this.goToComponent}
                    loadData={this.loadData}
                    user={this.props.user}
                    isTabEditable={this.state.isTabEditable}
                    stages={this.state.tabMap[Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID]}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    blockers={this.state.blockers}
                    isDataLoaded={this.state.isDataLoaded}
                    setIsAnyEditModeEnabled={this.setIsAnyEditModeEnabled}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    handleToggleCompleteStage={this.handleToggleCompleteStage}
                />)
            });
        }
        // Here we add the business operations tab to the page if it is present in the tabMap
        if (this.state.tabMap[Constants.ORDER_TAB_IDS.BUSINESS_OPERATIONS_TAB_ID]) {
            tabs.push({
                label: "Business Operations",
                id: Constants.ORDER_TAB_IDS.BUSINESS_OPERATIONS_TAB_ID,
                content: this.tabContent(<OrderBusinessOperationsTab
                    order={this.state.order}
                    auth={this.state.auth}
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.state.loadingComponentIdToObjectMap}
                    circuitDesignsLoading={this.state.circuitDesignsLoading}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    goToComponentAction={this.goToComponent}
                    loadData={this.loadData}
                    user={this.props.user}
                    isTabEditable={this.state.isTabEditable}
                    stages={this.state.tabMap[Constants.ORDER_TAB_IDS.BUSINESS_OPERATIONS_TAB_ID]}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    blockers={this.state.blockers}
                    isDataLoaded={this.state.isDataLoaded}
                    setIsAnyEditModeEnabled={this.setIsAnyEditModeEnabled}
                    isAnyEditModeEnabled={this.state.isAnyEditModeEnabled}
                    handleToggleCompleteStage={this.handleToggleCompleteStage}
                />)
            });
        }
        tabs.push(
            {
                label: "Circuit Designs",
                id: Constants.ORDER_TAB_IDS.CIRCUIT_DESIGN_TAB_ID,
                content: this.tabContent(<CircuitDesignTab
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                    loading={(this.state.circuitDesignObjects.length !== 0) &&
                    (this.state.circuitDesignsLoading || this.state.loadingComponentIdToObjectMap)}
                    auth={this.props.auth}
                    blockers={this.state.blockers}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    isOrderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    order={this.state.order}
                    updateComponentIdToObjectMap={this.updateComponentIdToObjectMap}
                    loadData={this.loadData}
                    allProviders={this.props.providerOptions}
                    allProvidersLoading={this.props.providersLoading}
                />)
            },
            {
                label: "Order Notes",
                id: Constants.ORDER_TAB_IDS.NOTE_TAB_ID,
                content: this.tabContent(<NoteTab
                    tableName="order"
                    type="order"
                    entityId={this.state.order.orderId}
                    name={this.state.order.orderId}
                    noteIdList={this.state.order.noteIdList}
                    loadData={this.loadData}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />)
            },
            {
                label: "Attachments",
                id: Constants.ORDER_TAB_IDS.ATTACHMENTS_TAB_ID,
                content: this.tabContent(<AttachmentTab
                    auth={this.props.auth}
                    orderAttachments={this.state.orderAttachments}
                    orderAttachmentsLoading={this.state.orderAttachmentsLoading}
                    orderId={this.state.order.orderId}
                    orderCompleted={this.state.isOrderCompleted || this.state.isOrderCancelled}
                    downloadAttachment={this.downloadAttachment}
                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                    loadData={this.loadData}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />)
            },
            {
                label: "Audit Trail",
                id: Constants.ORDER_TAB_IDS.AUDIT_TAB_ID,
                content: this.tabContent(<AuditTab
                    type="order"
                    auditIdList={this.state.order.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />)
            },
        );
        return tabs;
    };

    canUserUpdatePriority = () => {
        const { serviceType } = this.state.order;
        const isBackboneOrder = HelperFunctions.isBackboneService(serviceType);
        const isInterconnectOrder = HelperFunctions.isInterconnectService(serviceType);

        return (isBackboneOrder && this.state.isUserManager)
            || (isInterconnectOrder && HelperFunctions.isUserInUpdatePermissionsGroup(this.props.user))
            || HelperFunctions.isLocalHost();
    }

    render() {
        if (this.state.loading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    loading={this.state.loading}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        if (this.state.invalidOrder) {
            return (
                <FremontErrorPage
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                <OrderBlockerModalHandler
                    circuitDesignObjects={this.state.circuitDesignObjects}
                    isOrderBlockerModalClicked={this.state.isModalClicked[
                        OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker]}
                    hideModal={() => this.hideModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker
                        }
                    })}
                    clickedBlockerId={this.state.clickedBlockerId}
                    auth={this.props.auth}
                    blockers={this.state.blockers}
                    blockerNotes={this.state.blockerNotes}
                    order={this.state.order}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    loadData={this.loadData}
                    goToComponentAction={this.goToComponent}
                    providerOptions={this.props.providerOptions}
                    providersLoading={this.props.providersLoading}
                />
                {/* Only render the handlers below if we want to show their respective modals */}
                {this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.tag] &&
                    <TagHandler
                        auth={this.props.auth}
                        hideTagModal={() => this.hideModal({
                            target: {
                                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.tag
                            }
                        })}
                        isOrderTagModalClicked={this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.tag]}
                        handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                        getAllTagItems={this.getAllTagItems}
                        order={this.state.order}
                        getOrder={this.getOrder}
                    />
                }
                {/* Only render the handlers below if we want to show their respective modals */}
                {this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange] &&
                <OrderProviderChangeHandler
                    auth={this.props.auth}
                    hideOrderProviderChangeModal={() => this.hideModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange
                        }
                    })}
                    isOrderProviderChangeModalClicked={
                        this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange]
                    }
                    order={this.state.order}
                    loadData={this.loadData}
                    loadDataOnOriginalPageLoad={this.loadDataOnOriginalPageLoad}
                    allProviders={this.props.providerOptions}
                    allProvidersLoading={this.props.providersLoading}
                />
                }
                {/* TODO this will be fixed by https://sim.amazon.com/issues/FremontNEST-2945 */}
                {this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment] &&
                    <AttachmentHandler
                        id={OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment}
                        attachments={this.state.circuitDesignAttachmentsClone}
                        entityType={Constants.CIRCUIT_DESIGN_ENTITY_TYPE}
                        attachmentFormToReactRefMap={this.state.attachmentFormToReactRefMap}
                        isAttachmentModalClicked={this.state.isModalClicked[
                            OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment]}
                        hideAttachmentModal={() => this.hideModal({
                            target: {
                                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.attachment
                            }
                        })}
                        loadData={this.loadData}
                        auth={this.props.auth}
                        handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}

                        // Circuit specific props for attachments
                        circuitDesignOptions={this.state.circuitDesignOptions}
                        serviceType={this.state.order.serviceType}
                        customerFabric={this.state.order.customerFabric}
                        blockers={this.state.blockers}
                        stageStatusMap={this.state.order.stageStatusMap}
                        orderType={this.state.order.orderType}
                    />
                }
                {/* TODO will be fixed by https://sim.amazon.com/issues/FremontNEST-2973 */}
                {this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator] &&
                    <TemplateGeneratorHandler
                        isTemplateGeneratorModalClicked={this.state.isModalClicked[
                            OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator]}
                        hideTemplateGeneratorModal={() => this.hideModal({
                            target: {
                                id: OrderValidation.ORDER_PAGE_MODAL_TYPES.templateGenerator
                            }
                        })}
                        circuitDesignObjects={this.state.circuitDesignObjects}
                        order={this.state.order}
                        componentIdToObjectMap={this.state.componentIdToObjectMap}
                        orderContact={this.state.orderContact}
                        orderContactEmail={this.state.orderContactEmail}
                    />
                }
                {/* Order Completion Modal */}
                <ConfirmationModal
                    isVisible={this.handleOrderCompletionModalPopup()}
                    loading={this.state.isOrderCompletionInProgress}
                    header="Order Completion"
                    description={
                        <FremontAlert type="warning">
                            You are about to complete an order. Once an order is completed, it will no longer be
                            editable. If you still have data to edit, hit cancel and modify that data. You will still
                            be able to update individual circuit information by submitting a change order.
                        </FremontAlert>
                    }
                    hideModal={() => this.hideModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.completion
                        }
                    })}
                    cancelButtonText="Cancel"
                    submitDisabled={!this.state.isDataLoaded || this.state.isAnyEditModeEnabled}
                    primaryButtonText={this.state.isOrderCompletionInProgress ? "Completing..." : "Complete Order"}
                    onClickFunction={this.handleSubmitOrderCompletion}
                />
                {/* Order Cancellation Modal */}
                <ConfirmationModal
                    isVisible={this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.cancel]}
                    loading={this.state.isOrderCancelInProgress}
                    header="Cancel Order"
                    description={
                        <FremontAlert type="warning">
                            Are you sure you want to cancel this order? This will remove all the circuit versions that
                            have been added to this order. They will not be able to be restored or used in a different
                            order, and any information in the circuit record will become text-only.
                        </FremontAlert>
                    }
                    hideModal={() => this.hideModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.cancel
                        }
                    })}
                    cancelButtonText="No"
                    primaryButtonText={this.state.isOrderCancelInProgress ? "Cancelling..." : "Yes, Cancel Order"}
                    onClickFunction={this.handleSubmitOrderCancel}
                />
                {/* Order Site Change Modal */}
                {/* This will only show up in Backbone Span orders where the circuits are being consumed */}
                <ConfirmationModal
                    isVisible={this.state.isModalClicked[OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange]}
                    loading={this.state.isSiteSubmissionInProgress}
                    header="Order Site Change"
                    description={
                        <FremontAlert type="warning">
                            This capacity is currently consumed and may be in use.  If you change the site, it could
                            result in production network impact. This change will also update any open parent orders.
                            Please acknowledge the risk and your ownership of the change by clicking I Accept below.
                        </FremontAlert>
                    }
                    hideModal={() => this.hideModal({
                        target: {
                            id: OrderValidation.ORDER_PAGE_MODAL_TYPES.siteChange
                        }
                    })}
                    cancelButtonText="Cancel"
                    primaryButtonText={this.state.isSiteSubmissionInProgress ? "Submitting..." : "I Accept"}
                    onClickFunction={this.submitOrderSiteChange}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    {this.state.isNCISOrder &&
                    <Container>
                        <FremontAlert
                            header="NCIS Order"
                            type="info"
                        >
                            This order has been imported from NCIS. Please note that changes
                            made here will not be reflected in NCIS.
                            <br/>
                            {"The original NCIS order is "}
                            <a target="_blank" href={this.state.order.ncisUrl}>
                                {this.state.order.ncisLabel}
                            </a>
                            {"."}
                        </FremontAlert>
                    </Container>
                    }
                    <FremontExpandableSection
                        variant="container"
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    !this.state.isOrderCancelled && !this.state.isOrderCompleted &&
                                    // TODO FIXME https://sim.amazon.com/issues/FremontNEST-3012
                                    (
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="m"
                                        >
                                            {this.state.circuitDesignObjects.some(circuit =>
                                                circuit[Constants.ATTRIBUTES.lifeCycleStage]
                                                === Constants.LIFECYCLE_STAGES.decommissioned) &&
                                                <Popover
                                                    content={"Cannot cancel order because" +
                                                    " one or more circuits have already been decommissioned."}
                                                >
                                                    <Icon
                                                        name="status-warning"
                                                        variant="subtle"
                                                    />
                                                </Popover>
                                            }
                                            <FremontButton
                                                id={OrderValidation.ORDER_PAGE_MODAL_TYPES.cancel}
                                                disabled={this.isOrderCancellationDisabled()}
                                                variant="primary"
                                                onClick={this.showModal}
                                            >
                                                Cancel Order
                                            </FremontButton>
                                        </SpaceBetween>
                                    )
                                }
                            >
                                {`Order: ${this.state.order.orderId}`}
                            </Header>
                        }
                        defaultExpanded
                    >
                        <Grid
                            gridDefinition={
                                // This grid automatically resizes itself when the screen is large enough to
                                // grant more area to the stage selection portion of the header
                                [{ colspan: { default: 5, xl: 3 } }, { colspan: { default: 7, xl: 9 } }]
                            }
                        >
                            <div className="container-border-right">
                                <ColumnLayout columns={2}>
                                    <div>
                                        <Box color="text-label">Service Type:</Box>
                                        <div>
                                            {HelperFunctions.getOrderServiceTypeCustomerFabricString(this.state.order)}
                                        </div>
                                    </div>
                                    <div>
                                        <Box color="text-label">Order Type:</Box>
                                        <div>
                                            {Constants.BACKEND_TO_FRONTEND_MAP[this.state.order.orderType]}
                                            {this.state.order.installAndDecommission && ` (Install & Decommission)`}
                                        </div>
                                    </div>
                                    <div>
                                        <Box color="text-label">Priority:</Box>
                                        {this.state.isEditingPriority ?
                                            <SpaceBetween
                                                size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                                direction="horizontal"
                                            >
                                                <FremontSelect
                                                    key="orderPrioritySelect"
                                                    options={HelperFunctions.createSelectedOptions(
                                                        Object.keys(Constants.ORDER_PRIORITY_OPTIONS)
                                                    )}
                                                    selectedOption={HelperFunctions
                                                        .createSelectedOption(
                                                            this.state.newOrderPriority
                                                        )
                                                    }
                                                    onChange={this.handlePriorityChangeEvent}
                                                    disabled={this.state.isPrioritySubmissionInProgress}
                                                />
                                                <FremontButton
                                                    key="orderPrioritySubmit"
                                                    variant="primary"
                                                    loading={this.state.isPrioritySubmissionInProgress}
                                                    onClick={this.submitOrderPriorityChange}
                                                >
                                                    {!this.state.isPrioritySubmissionInProgress &&
                                                    <Icon name="external"/>
                                                    }
                                                </FremontButton>
                                            </SpaceBetween>
                                            : this.state.order.priorityType
                                        }
                                        {!this.state.isOrderCompleted && !this.state.isOrderCancelled
                                        && this.canUserUpdatePriority() && !this.state.isAnyEditModeEnabled
                                        &&
                                        <Box float="right">
                                            <FremontButton
                                                variant="icon"
                                                iconName="edit"
                                                onClick={this.showOrderPrioritySelectionOptions}
                                            />
                                        </Box>
                                        }
                                    </div>
                                    <div>
                                        <Box color="text-label">Provider:</Box>
                                        <div>
                                            <Link
                                                href={`${Constants.ROUTES.provider}/${
                                                    this.state.order.providerName
                                                }`}
                                            >
                                                {this.state.order.providerName}
                                            </Link>
                                            {this.canShowProviderChangeModalEditButton() &&
                                            <Box float="right">
                                                <FremontButton
                                                    id={OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange}
                                                    variant="icon"
                                                    iconName="edit"
                                                    onClick={this.showModal}
                                                />
                                            </Box>
                                            }
                                        </div>
                                    </div>
                                    {this.state.order.asnId &&
                                    <div>
                                        <Box color="text-label">ASN:</Box>
                                        <div>
                                            <Link
                                                href={`${Constants.ROUTES.asn}/${
                                                    this.state.asn.asnId
                                                }`}
                                            >
                                                {this.state.asn.asnNumber}
                                            </Link>
                                            {this.canShowProviderChangeModalEditButton() &&
                                            <Box float="right">
                                                <FremontButton
                                                    id={OrderValidation.ORDER_PAGE_MODAL_TYPES.providerChange}
                                                    variant="icon"
                                                    iconName="edit"
                                                    onClick={this.showModal}
                                                />
                                            </Box>
                                            }
                                        </div>
                                    </div>
                                    }
                                    <div>
                                        <Box color="text-label">
                                            {Constants.INTERCONNECT_SERVICE_TYPES.includes(
                                                this.state.order.serviceType
                                            )
                                            && Constants.ORDER_TYPES.INSTALL === this.state.order.orderType
                                                ? "Provider Site:" : "Site A:"
                                            }
                                        </Box>
                                        {this.state.isEditingSiteA ?
                                            <Grid
                                                gridDefinition={[
                                                    { colspan: { default: 9 } },
                                                    { colspan: { default: 3 } }]}
                                                disableGutters
                                            >
                                                <FremontSelect
                                                    id={Constants.ATTRIBUTES.siteAId}
                                                    key={Constants.ATTRIBUTES.siteAId}
                                                    options={HelperFunctions.groupOptions(
                                                        this.state.siteOptions,
                                                        Constants.ATTRIBUTES.geographicRegion
                                                    )}
                                                    selectedOption={this.state.newOrderSiteAOption}
                                                    filteringType="auto"
                                                    statusType={this.state.siteOptionsLoading
                                                        ? Constants.LOADING : Constants.FINISHED}
                                                    loadingText={Constants.STATUS_TEXTS.loadingSites}
                                                    onChange={this.handleSiteChangeEvent}
                                                    disabled={this.state.isSiteSubmissionInProgress}
                                                />
                                                <FremontButton
                                                    key="orderSiteSubmit"
                                                    variant="primary"
                                                    loading={this.state.isSiteSubmissionInProgress}
                                                    onClick={this.handleOrderSiteChangeModalPopup}
                                                >
                                                    {!this.state.isSiteSubmissionInProgress &&
                                                    <Icon name="external"/>
                                                    }
                                                </FremontButton>
                                            </Grid>
                                            :
                                            this.displaySiteName(Constants.ATTRIBUTES.siteAId)
                                        }
                                        {!this.state.isOrderCompleted && !this.state.isOrderCancelled
                                        && [Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE]
                                            .includes(this.state.order.orderType)
                                        && !this.state.isAnyEditModeEnabled &&
                                        <Box float="right">
                                            <FremontButton
                                                id={Constants.ATTRIBUTES.siteAId}
                                                variant="icon"
                                                iconName="edit"
                                                onClick={this.showOrderSiteSelectionOptions}
                                            />
                                        </Box>
                                        }
                                    </div>
                                    {this.state.order.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                                    <div>
                                        <Box color="text-label">Site Z:</Box>
                                        {this.state.isEditingSiteZ ?
                                            <Grid
                                                gridDefinition={[
                                                    { colspan: { default: 9 } },
                                                    { colspan: { default: 3 } }]}
                                                disableGutters
                                            >
                                                <FremontSelect
                                                    id={Constants.ATTRIBUTES.siteZId}
                                                    key={Constants.ATTRIBUTES.siteZId}
                                                    options={HelperFunctions.groupOptions(
                                                        this.state.siteOptions,
                                                        Constants.ATTRIBUTES.geographicRegion
                                                    )}
                                                    selectedOption={this.state.newOrderSiteZOption}
                                                    filteringType="auto"
                                                    statusType={this.state.siteOptionsLoading
                                                        ? Constants.LOADING : Constants.FINISHED}
                                                    loadingText={Constants.STATUS_TEXTS.loadingSites}
                                                    onChange={this.handleSiteChangeEvent}
                                                    disabled={this.state.isSiteSubmissionInProgress}
                                                />
                                                <FremontButton
                                                    key="orderSiteSubmit"
                                                    variant="primary"
                                                    loading={this.state.isSiteSubmissionInProgress}
                                                    onClick={this.handleOrderSiteChangeModalPopup}
                                                >
                                                    {!this.state.isSiteSubmissionInProgress &&
                                                    <Icon name="external"/>
                                                    }
                                                </FremontButton>
                                            </Grid>
                                            :
                                            this.displaySiteName(Constants.ATTRIBUTES.siteZId)
                                        }
                                        {!this.state.isOrderCompleted && !this.state.isOrderCancelled
                                        && [Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE]
                                            .includes(this.state.order.orderType)
                                        && !this.state.isAnyEditModeEnabled &&
                                        <Box float="right">
                                            <FremontButton
                                                id={Constants.ATTRIBUTES.siteZId}
                                                variant="icon"
                                                iconName="edit"
                                                onClick={this.showOrderSiteSelectionOptions}
                                            />
                                        </Box>
                                        }
                                    </div>
                                    }
                                </ColumnLayout>
                            </div>
                            <div>
                                {this.displayStages()}
                            </div>
                        </Grid>
                    </FremontExpandableSection>
                    <Tabs
                        tabs={this.updateTabs()}
                        activeTabId={this.state.activeTabId}
                        onChange={this.handleTabChange}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(OrderDetailsPage);