import React from "react";
import {
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants
} from "utils/CommonComponents";
import BackboneImplementationHandler from "order/stages/install/BackboneImplementationHandler";
import CablingDecomHandler from "order/stages/decom/CablingDecomHandler";
import CablingInstallHandler from "order/stages/install/CablingInstallHandler";
import CarrierConfirmationHandler from "order/stages/decom/CarrierConfirmationHandler";
import CarrierBuildHandler from "order/stages/install/CarrierBuildHandler";
import CarrierNotificationHandler from "order/stages/decom/CarrierNotificationHandler";
import Constants from "utils/Constants";
import CostOutHandler from "order/stages/decom/CostOutHandler";
import CreateCircuitsHandler from "order/stages/install/CreateCircuitsHandler";
import DemarcAndCFAHandler from "order/stages/install/DemarcAndCFAHandler";
import FilterHandler from "order/stages/decom/FilterHandler";
import HelperFunctions from "common/HelperFunctions";
import ImplementationHandler from "order/stages/install/ImplementationHandler";
import IpAllocationHandler from "order/stages/install/IpAllocationHandler";
import IpTestingHandler from "order/stages/install/IpTestingHandler";
import NewOrderInformationHandler from "order/stages/install/NewOrderInformationHandler";
import OrderAcceptanceHandler from "order/stages/OrderAcceptanceHandler";
import PortReservationHandler from "order/stages/install/PortReservationHandler";
import ReleasePortHandler from "order/stages/decom/ReleasePortHandler";
import SelectCircuitsHandler from "order/stages/decom/SelectCircuitsHandler";
import SubmitForApprovalHandler from "order/stages/SubmitForApprovalHandler";
import TurnDownHandler from "order/stages/decom/TurnDownHandler";
import TroubleshootingHandler from "order/stages/install/TroubleshootingHandler";
import VerifyStopBillingHandler from "order/stages/decom/VerifyStopBillingHandler";
import WorkOrderExecutionHandler from "order/stages/install/WorkOrderExecutionHandler";
import TopologyHandler from "order/stages/install/TopologyHandler";

/**
 * This function handles rendering the stages that are held within a specific tab. The component receives an object
 * that contains each stage to be rendered and the order in which it should be rendered, in addition to all other
 * necessary props. The function loops through the stages object, determines which component the stage corresponds to,
 * and add the appropriate version of the component (edit mode vs. display mode) to another object, stagesToDisplay,
 * that contains the actual components to be rendered. Finally, the original stage array is sorted based upon the order
 * each stage should be displayed and the chosen components are rendered.
 */
const OrderTabRenderingHandler = (props) => {
    const stagesToDisplay = {};

    Object.values(props.stages).forEach((stageName) => {
        switch (stageName) {
        case Constants.STAGE_NAMES.topology:
            stagesToDisplay[stageName] = (
                <TopologyHandler
                    // Every order passed into the topologyHandler needs to have UUIDs to prevent against a react
                    // rendering bug described in the below method
                    order={HelperFunctions.addUuidsToTopologyMap(props.order)}
                    orderCompleted={props.orderCompleted}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    circuitDesignObjects={props.circuitDesignObjects}
                    isDataLoaded={props.isDataLoaded}
                    siteOptions={props.siteOptions}
                    fetchSiteOptions={props.fetchSiteOptions}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    setIsAnyEditModeEnabled={props.setIsAnyEditModeEnabled}
                    goToComponentAction={props.goToComponentAction}
                    handleToggleCompleteStage={props.handleToggleCompleteStage}
                />
            );
            break;
        case Constants.STAGE_NAMES.ipTesting:
            stagesToDisplay[stageName] = (
                <IpTestingHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    circuitDesignObjects={props.circuitDesignObjects}
                    isDataLoaded={props.isDataLoaded}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    goToComponentAction={props.goToComponentAction}
                />
            );
            break;
        case Constants.STAGE_NAMES.carrierBuild:
            stagesToDisplay[stageName] = (
                <CarrierBuildHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    circuitDesignObjects={props.circuitDesignObjects}
                    isDataLoaded={props.isDataLoaded}
                    goToComponentAction={props.goToComponentAction}
                />
            );
            break;
        case Constants.STAGE_NAMES.newOrderInformation:
            stagesToDisplay[stageName] = (
                <NewOrderInformationHandler
                    order={props.order}
                    asn={props.asn}
                    asnLoading={props.asnLoading}
                    orderCompleted={props.orderCompleted}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    goToComponentAction={props.goToComponentAction}
                />
            );
            break;
        case Constants.STAGE_NAMES.backboneImplementation:
            stagesToDisplay[stageName] = (
                <BackboneImplementationHandler
                    order={props.order}
                    span={props.span}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    stageInEditOrSubmitMode={props.stageInEditOrSubmitMode}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                    showAttachmentModal={props.showAttachmentModal}
                    downloadAttachment={props.downloadAttachment}
                    isDownloadingAttachment={props.isDownloadingAttachment}
                    editButtonsDisabled={props.editButtonsDisabled}
                />
            );
            break;
        case Constants.STAGE_NAMES.demarcAndCfaStage:
            stagesToDisplay[stageName] = (
                <DemarcAndCFAHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    stageInEditOrSubmitMode={props.stageInEditOrSubmitMode}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                    showAttachmentModal={props.showAttachmentModal}
                    downloadAttachment={props.downloadAttachment}
                    isDownloadingAttachment={props.isDownloadingAttachment}
                    editButtonsDisabled={props.editButtonsDisabled}
                />
            );
            break;
        case Constants.STAGE_NAMES.createCircuits:
            stagesToDisplay[stageName] = (
                <CreateCircuitsHandler
                    asn={props.asn}
                    asnLoading={props.asnLoading}
                    order={props.order}
                    circuitDesignObjects={props.circuitDesignObjects}
                    siteNames={props.siteNames}
                    loadData={props.loadData}
                    orderCompleted={props.orderCompleted}
                    goToComponentAction={props.goToComponentAction}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    auth={props.auth}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.submitForApproval:
            stagesToDisplay[stageName] = (
                <SubmitForApprovalHandler
                    order={props.order}
                    auth={props.auth}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    goToComponentAction={props.goToComponentAction}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    blockers={props.blockers}
                    showAttachmentModal={props.showAttachmentModal}
                    attachmentsLoading={props.attachmentsLoading}
                    downloadAttachment={props.downloadAttachment}
                    isDownloadingAttachment={props.isDownloadingAttachment}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    siteNames={props.siteNames}
                />
            );
            break;
        case Constants.STAGE_NAMES.selectCircuits:
            stagesToDisplay[stageName] = (
                <SelectCircuitsHandler
                    order={props.order}
                    auth={props.auth}
                    workOrder={props.workOrder}
                    span={props.span}
                    orderCompleted={props.orderCompleted}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    goToComponentAction={props.goToComponentAction}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    blockers={props.blockers}
                    siteNames={props.siteNames}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.cabling:
            // Change orders use CablingInstallHandler
            if (props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION) {
                stagesToDisplay[stageName] = (
                    <CablingDecomHandler
                        order={props.order}
                        auth={props.auth}
                        orderCompleted={props.orderCompleted}
                        circuitDesignObjects={props.circuitDesignObjects}
                        circuitDesignsLoading={props.circuitDesignsLoading}
                        componentIdToObjectMap={props.componentIdToObjectMap}
                        loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                        handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                        loadData={props.loadData}
                        goToComponentAction={props.goToComponentAction}
                        blockers={props.blockers}
                        editButtonsDisabled={props.editButtonsDisabled}
                        handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                        isDataLoaded={props.isDataLoaded}
                        showTemplateGeneratorModal={props.showTemplateGeneratorModal}
                    />
                );
            } else {
                stagesToDisplay[stageName] = (
                    <CablingInstallHandler
                        order={props.order}
                        siteNames={props.siteNames}
                        orderCompleted={props.orderCompleted}
                        asn={props.asn}
                        circuitDesignObjects={props.circuitDesignObjects}
                        circuitDesignsLoading={props.circuitDesignsLoading}
                        componentIdToObjectMap={props.componentIdToObjectMap}
                        loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                        handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                        loadData={props.loadData}
                        goToComponentAction={props.goToComponentAction}
                        blockers={props.blockers}
                        auth={props.auth}
                        showAttachmentModal={props.showAttachmentModal}
                        attachmentsLoading={props.attachmentsLoading}
                        downloadAttachment={props.downloadAttachment}
                        isDownloadingAttachment={props.isDownloadingAttachment}
                        editButtonsDisabled={props.editButtonsDisabled}
                        handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                        isDataLoaded={props.isDataLoaded}
                        showTemplateGeneratorModal={props.showTemplateGeneratorModal}
                        handleToggleCompleteStage={props.handleToggleCompleteStage}
                    />
                );
            }
            break;
        case Constants.STAGE_NAMES.troubleshooting:
            stagesToDisplay[stageName] = (
                <TroubleshootingHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                    setIsAnyEditModeEnabled={props.setIsAnyEditModeEnabled}
                    handleToggleCompleteStage={props.handleToggleCompleteStage}
                />
            );
            break;
        case Constants.STAGE_NAMES.orderAcceptance:
            stagesToDisplay[stageName] = (
                <OrderAcceptanceHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    user={props.user}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.portReservation:
            stagesToDisplay[stageName] = (
                <PortReservationHandler
                    order={props.order}
                    siteNames={props.siteNames}
                    siteOptions={props.siteOptions}
                    fetchSiteOptions={props.fetchSiteOptions}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                    handleToggleCompleteStage={props.handleToggleCompleteStage}
                />
            );
            break;
        case Constants.STAGE_NAMES.ipAllocation:
            stagesToDisplay[stageName] = (
                <IpAllocationHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                    handleToggleCompleteStage={props.handleToggleCompleteStage}
                />
            );
            break;
        case Constants.STAGE_NAMES.implementation:
            stagesToDisplay[stageName] = (
                <ImplementationHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.filter:
            stagesToDisplay[stageName] = (
                <FilterHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    auth={props.auth}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.costOut:
            stagesToDisplay[stageName] = (
                <CostOutHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    auth={props.auth}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.turnDown:
            stagesToDisplay[stageName] = (
                <TurnDownHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    auth={props.auth}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.releasePort:
            stagesToDisplay[stageName] = (
                <ReleasePortHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={props.loadingComponentIdToObjectMap}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    auth={props.auth}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.carrierConfirmation:
            stagesToDisplay[stageName] = (
                <CarrierConfirmationHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    auth={props.auth}
                    goToComponentAction={props.goToComponentAction}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.carrierNotification:
            stagesToDisplay[stageName] = (
                <CarrierNotificationHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    auth={props.auth}
                    goToComponentAction={props.goToComponentAction}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.verifyStopBilling:
            stagesToDisplay[stageName] = (
                <VerifyStopBillingHandler
                    order={props.order}
                    orderCompleted={props.orderCompleted}
                    circuitDesignObjects={props.circuitDesignObjects}
                    circuitDesignsLoading={props.circuitDesignsLoading}
                    componentIdToObjectMap={props.componentIdToObjectMap}
                    auth={props.auth}
                    goToComponentAction={props.goToComponentAction}
                    loadData={props.loadData}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    blockers={props.blockers}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        case Constants.STAGE_NAMES.workOrderExecution:
            if (Constants.INTERNAL_AMAZON_PROVIDER === props.order.providerName) {
                break;
            }
            stagesToDisplay[stageName] = (
                <WorkOrderExecutionHandler
                    order={props.order}
                    workOrder={props.workOrder}
                    span={props.span}
                    circuitDesignObjects={props.circuitDesignObjects}
                    handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                    loadData={props.loadData}
                    goToComponentAction={props.goToComponentAction}
                    blockers={props.blockers}
                    auth={props.auth}
                    editButtonsDisabled={props.editButtonsDisabled}
                    handleStageInEditOrSubmitMode={props.handleStageInEditOrSubmitMode}
                    isDataLoaded={props.isDataLoaded}
                />
            );
            break;
        default:
            break;
        }
    });

    return (
        <SpaceBetween
            direction="vertical"
            size={ComponentConstants.SPACE_BETWEEN_STAGES}
        >
            {
                // Here we sort the stages object by each stage's order in the tab. Then
                // we display each stage in the correct order
                Object.keys(props.stages).sort((a, b) => a - b).map(stageNumber => (
                    <div key={stageNumber}>
                        {stagesToDisplay[props.stages[stageNumber]]}
                    </div>
                ))
            }
        </SpaceBetween>
    );
};

export default OrderTabRenderingHandler;