import React from "react";
import { get } from "lodash";
import {
    Box,
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontInput,
    FremontSelect,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const transportGroup = [
    {
        label: Constants.TRANSPORT_REGION_OPTIONS[0],
        options: HelperFunctions.createSelectedOptionsForGroup(
            Constants.TRANSPORT_CORRIDOR_NASA_OPTIONS, Constants.TRANSPORT_REGION_OPTIONS[0]
        )
    },
    {
        label: Constants.TRANSPORT_REGION_OPTIONS[1],
        options: HelperFunctions.createSelectedOptionsForGroup(
            Constants.TRANSPORT_CORRIDOR_EMEAI_OPTIONS, Constants.TRANSPORT_REGION_OPTIONS[1]
        )
    },
    {
        label: Constants.TRANSPORT_REGION_OPTIONS[2],
        options: HelperFunctions.createSelectedOptionsForGroup(
            Constants.TRANSPORT_CORRIDOR_APAC_OPTIONS, Constants.TRANSPORT_REGION_OPTIONS[2]
        )
    }
];

export const CreateCircuitsStageDisplayMode = props => (
    <div
        className="fremont-stage-spacing"
        ref={Constants.DEPENDENCY_LINK_REFERENCES[Constants.STAGE_NAMES.createCircuits].ref}
    >
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    showAddCircuits
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                {props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE ?
                    <BackboneDisplayFields
                        order={props.order}
                        circuitDesignObjects={props.circuitDesignObjects}
                        siteNames={props.siteNames}
                    /> :
                    <SFPDisplayFields
                        order={props.order}
                        asn={props.asn}
                        asnLoading={props.asnLoading}
                    />
                }
            </div>
        </Container>
    </div>
);

export const BackboneDisplayFields = props => (
    <ColumnLayout columns={2} borders="vertical">
        <div>
            <Box color="text-label">Circuit Quantity:</Box>
            <div>{props.order[Constants.ATTRIBUTES.circuitQuantity] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">Port Size (Gbps):</Box>
            <div>{props.order[Constants.ATTRIBUTES.portSize] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">Link Handoff:</Box>
            <div>{props.order[Constants.ATTRIBUTES.linkHandoff] || "-"}</div>
        </div>
        {Constants.TT_URL_CUSTOMER_FABRICS.includes(
            props.order[Constants.ATTRIBUTES.customerFabric]
        ) &&
        <div>
            <Box color="text-label">TT URL:</Box>
            <div>{HelperFunctions.renderExternalLink(props.order[Constants.ATTRIBUTES.ttUrl]) || "-"}</div>
        </div>
        }
        <div>
            <Box color="text-label">Transport Region:</Box>
            <div>{props.circuitDesignObjects.length > 0 &&
                props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportRegion] ?
                props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportRegion] : "-"}
            </div>
        </div>
        <div>
            <Box color="text-label">Transport Corridor:</Box>
            <div>{props.circuitDesignObjects.length > 0 &&
                props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportCorridor] ?
                props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportCorridor] : "-"}
            </div>
        </div>
        {HelperFunctions.isOrderSpanOrder(props.order) &&
            <div>
                <Box color="text-label">{Constants.ATTRIBUTE_LABELS.isProtected}</Box>
                <div>{HelperFunctions.getFieldFromFirstCircuitObject(
                    props.circuitDesignObjects, Constants.ATTRIBUTES.isProtected, "-"
                )}
                </div>
            </div>
        }
        {HelperFunctions.isOrderSpanOrder(props.order) && props.circuitDesignObjects.length > 0 &&
            <div>
                <Box color="text-label">{Constants.ATTRIBUTE_LABELS.maxLatencySteadyState}</Box>
                <div>{HelperFunctions.getFieldFromFirstCircuitObject(
                    props.circuitDesignObjects, Constants.ATTRIBUTES.maxLatencySteadyState, "-"
                )}
                </div>
            </div>
        }
        {HelperFunctions.isOrderSpanOrder(props.order) && props.circuitDesignObjects.length > 0 &&
            <div>
                <Box color="text-label">{Constants.ATTRIBUTE_LABELS.maxLatencyProtectState}</Box>
                <div>{HelperFunctions.getFieldFromFirstCircuitObject(
                    props.circuitDesignObjects, Constants.ATTRIBUTES.maxLatencyProtectState, "-"
                )}
                </div>
            </div>
        }
        {HelperFunctions.isOrderSpanOrder(props.order) &&
        <div>
            <Box color="text-label">{Constants.ATTRIBUTE_LABELS.spanType}</Box>
            <div>{get(props.circuitDesignObjects[0], Constants.ATTRIBUTES.spanType, "-")}</div>
        </div>
        }
    </ColumnLayout>
);

export const SFPDisplayFields = props => (
    <ColumnLayout columns={2} borders="vertical">
        <div>
            <Box color="text-label">Circuit Quantity:</Box>
            <div>{props.order[Constants.ATTRIBUTES.circuitQuantity] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">Port Size (Gbps):</Box>
            <div>{props.order[Constants.ATTRIBUTES.portSize] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">IP Disposition:</Box>
            <div>{props.order[Constants.ATTRIBUTES.ipDisposition] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">LOA Disposition:</Box>
            <div>{props.order[Constants.ATTRIBUTES.loaDisposition] || "-"}</div>
        </div>
        <div>
            <Box color="text-label">LACP Provider:</Box>
            <div>
                {HelperFunctions.booleanToYesNo(
                    HelperFunctions.parseBoolean(props.order[Constants.ATTRIBUTES.lacpProvider])
                ) || "-"
                }
            </div>
        </div>
        <div/>
        {HelperFunctions.parseBoolean(props.order[Constants.ATTRIBUTES.lacpProvider]) &&
        <div>
            <Box color="text-label">Circuits x Net New LAG(s):</Box>
            <div>
                {HelperFunctions.displayCircuitCountAndQuantity(
                    props.order[Constants.ATTRIBUTES.netNewLagCircuitCount],
                    props.order[Constants.ATTRIBUTES.netNewLagQuantity]
                )}
            </div>
        </div>}
        {HelperFunctions.parseBoolean(props.order[Constants.ATTRIBUTES.lacpProvider]) &&
        <div>
            <Box color="text-label">Circuits x Existing LAG(s):</Box>
            <div>
                {HelperFunctions.displayCircuitCountAndQuantity(
                    props.order[Constants.ATTRIBUTES.existingLagCircuitCount],
                    props.order[Constants.ATTRIBUTES.existingLagQuantity]
                )}
            </div>
        </div>}
    </ColumnLayout>
);

export const CreateCircuitsStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateStageInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateStageInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateStageInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Design
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])}
                </Header>
            }
        >
            <div>
                <Container
                    header={
                        <Header variant="h2">
                            General Information
                        </Header>
                    }
                >
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={requiredLabel("Circuit Quantity:")}
                            errorText={props.errorTexts.circuitQuantity}
                        >
                            <FremontInput
                                id={Constants.ATTRIBUTES.circuitQuantity}
                                type={Constants.NUMBER}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.circuitQuantity)}
                                value={props.updatedOrder.circuitQuantity}
                                onInput={props.handleStageInputChange}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Port Size (Gbps):")}
                            errorText={props.errorTexts.portSize}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.portSize}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList.includes(Constants.ATTRIBUTES.portSize)}
                                options={HelperFunctions.createSelectedOptions(
                                    Constants.CIRCUIT_BANDWIDTH_OPTIONS
                                )}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder.portSize
                                )}
                                onChange={props.handleStageInputChange}
                                expandToViewport
                            />
                        </FormField>
                        {props.updatedOrder.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                        <FormField
                            label={requiredLabel("Link Handoff:")}
                            errorText={props.errorTexts.linkHandoff}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.linkHandoff}
                                options={props.updatedOrder.portSize ?
                                    HelperFunctions.createSelectedOptions(
                                        Constants.LINK_HANDOFF_OPTIONS[props.updatedOrder.portSize]
                                    ) : []}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList.includes(Constants.ATTRIBUTES.linkHandoff)
                                || !props.updatedOrder.portSize}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder.linkHandoff
                                )}
                                onChange={props.handleStageInputChange}
                                empty={Constants.EMPTY_MESSAGES.generic}
                                placeholder={!props.updatedOrder.portSize
                                    ? "Select a port size first" : "Select a link handoff"}
                                expandToViewport
                            />
                        </FormField>
                        }
                        {props.updatedOrder.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                        Constants.TT_URL_CUSTOMER_FABRICS.includes(
                            props.updatedOrder[Constants.ATTRIBUTES.customerFabric]
                        ) &&
                        <FormField
                            label={requiredLabel("TT URL:")}
                            errorText={props.errorTexts.ttUrl}
                        >
                            <FremontInput
                                id={Constants.ATTRIBUTES.ttUrl}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList.includes(Constants.ATTRIBUTES.ttUrl)}
                                value={props.updatedOrder.ttUrl}
                                onInput={props.handleStageInputChange}
                            />
                        </FormField>
                        }
                        {props.updatedOrder.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                            <FormField
                                label={requiredLabel("Transport Region & Corridor:")}
                                errorText={props.errorTexts.transportCorridor}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.transportCorridor}
                                    options={transportGroup}
                                    disabled={props.isUpdateStageInProgress
                                        || props.disabledFieldsList.includes(
                                            Constants.ATTRIBUTES.transportCorridor
                                        )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.updatedTransport.transportCorridor
                                    )}
                                    onChange={props.handleStageInputChange}
                                    placeholder="Select a transport corridor"
                                    expandToViewport
                                />
                            </FormField>
                        }
                        {HelperFunctions.isOrderSpanOrder(props.updatedOrder) &&
                            <FormField
                                label={requiredLabel(Constants.ATTRIBUTE_LABELS.isProtected)}
                                errorText={props.errorTexts.isProtected}
                            >
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.isProtected}
                                    disabled={props.isUpdateStageInProgress
                                    || props.disabledFieldsList.includes(
                                        Constants.ATTRIBUTES.isProtected
                                    )}
                                    options={HelperFunctions.createSelectedOptions(
                                        [Constants.TRUE_STRING, Constants.FALSE_STRING]
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.updatedOrder.isProtected
                                    )}
                                    onChange={props.handleStageInputChange}
                                    empty={Constants.EMPTY_MESSAGES.generic}
                                    placeholder={Constants.PLACEHOLDER_TEXTS.selectIsProtected}
                                    expandToViewport
                                />
                            </FormField>
                        }
                        {HelperFunctions.isOrderSpanOrder(props.updatedOrder) &&
                            <FormField
                                label={requiredLabel(Constants.ATTRIBUTE_LABELS.maxLatencySteadyState)}
                                errorText={props.errorTexts.maxLatencySteadyState}
                            >
                                <FremontInput
                                    id={Constants.ATTRIBUTES.maxLatencySteadyState}
                                    type={Constants.NUMBER}
                                    disabled={props.isUpdateStageInProgress}
                                    value={props.updatedOrder.maxLatencySteadyState}
                                    onInput={props.handleStageInputChange}
                                />
                            </FormField>
                        }
                        {HelperFunctions.isOrderSpanOrder(props.updatedOrder) &&
                            <FormField
                                label={requiredLabel(Constants.ATTRIBUTE_LABELS.maxLatencyProtectState)}
                                errorText={props.errorTexts.maxLatencyProtectState}
                            >
                                <FremontInput
                                    id={Constants.ATTRIBUTES.maxLatencyProtectState}
                                    type={Constants.NUMBER}
                                    disabled={props.isUpdateStageInProgress ||
                                        props.updatedOrder.isProtected === Constants.FALSE_STRING}
                                    value={props.updatedOrder.maxLatencyProtectState}
                                    onInput={props.handleStageInputChange}
                                />
                            </FormField>
                        }
                        {props.updatedOrder.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
                        <FormField
                            label={requiredLabel("IP Disposition:")}
                            errorText={props.errorTexts.ipDisposition}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.ipDisposition}
                                options={Constants.IP_DISPOSITION_OPTIONS}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ipDisposition)}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder.ipDisposition
                                )}
                                onChange={props.handleStageInputChange}
                                expandToViewport
                            />
                        </FormField>
                        }
                        {props.updatedOrder.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
                        <FormField
                            label={requiredLabel("LOA Disposition:")}
                            errorText={props.errorTexts.loaDisposition}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.loaDisposition}
                                options={Constants.LOA_DISPOSITION_OPTIONS}
                                disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.loaDisposition)}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder.loaDisposition
                                )}
                                onChange={props.handleStageInputChange}
                                expandToViewport
                            />
                        </FormField>
                        }
                        {HelperFunctions.isOrderSpanOrder(props.updatedOrder) &&
                        <FormField
                            label={requiredLabel(Constants.ATTRIBUTE_LABELS.spanType)}
                            errorText={props.errorTexts.spanType}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.spanType}
                                options={HelperFunctions.createSelectedOptions(Constants.SPAN_TYPE_OPTIONS)}
                                disabled={props.isUpdateStageInProgress}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder.spanType
                                )}
                                onChange={props.handleStageInputChange}
                                empty={Constants.EMPTY_MESSAGES.generic}
                                placeholder={Constants.PLACEHOLDER_TEXTS.selectSpanType}
                                expandToViewport
                            />
                        </FormField>
                        }
                    </ColumnLayout>
                </Container>
                {Constants.INTERCONNECT_SERVICE_TYPES.includes(props.updatedOrder.serviceType) &&
                    <SFPEditFields
                        updatedOrder={props.updatedOrder}
                        disabledFieldsList={props.disabledFieldsList}
                        isUpdateStageInProgress={props.isUpdateStageInProgress}
                        errorTexts={props.errorTexts}
                        asn={props.asn}
                        asnLoading={props.asnLoading}
                        handleStageInputChange={props.handleStageInputChange}
                    />
                }
            </div>
        </Container>
    </div>
);

export const SFPEditFields = props => (
    <Container
        header={
            <Header variant="h2">
                LAG Information
            </Header>
        }
    >
        <ColumnLayout>
            <FormField
                label={requiredLabel("LACP Provider:")}
                errorText={props.errorTexts.lacpProvider}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.lacpProvider}
                    disabled={props.isUpdateStageInProgress
                    || props.disabledFieldsList.includes(Constants.ATTRIBUTES.lacpProvider)}
                    options={Constants.GENERAL_YES_NO_OPTIONS}
                    selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                        HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                    )}
                    onChange={props.handleStageInputChange}
                    expandToViewport
                />
            </FormField>
        </ColumnLayout>
        <br/>
        <Container
            header={
                <Header variant="h2">
                    Net New LAGs
                </Header>
            }
        >
            <ColumnLayout columns={2}>
                <FormField
                    label={HelperFunctions.displayRequiredOrOptionalField(
                        "LAG Quantity:", HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                    )}
                    errorText={props.errorTexts.netNewLagQuantity}
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.netNewLagQuantity}
                        type={Constants.NUMBER}
                        disabled={props.isUpdateStageInProgress
                        || !HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                        || props.disabledFieldsList.includes(Constants.ATTRIBUTES.netNewLagQuantity)}
                        value={props.updatedOrder.netNewLagQuantity}
                        onInput={props.handleStageInputChange}
                    />
                </FormField>
                <FormField
                    label={HelperFunctions.displayRequiredOrOptionalField(
                        "Circuits per LAG:", HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                    )}
                    errorText={props.errorTexts.netNewLagCircuitCount}
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.netNewLagCircuitCount}
                        type={Constants.NUMBER}
                        disabled={props.isUpdateStageInProgress
                        || !HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                        || props.disabledFieldsList.includes(Constants.ATTRIBUTES.netNewLagCircuitCount)}
                        value={props.updatedOrder.netNewLagCircuitCount}
                        onInput={props.handleStageInputChange}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
        <br/>
        <Container
            header={
                <Header variant="h2">
                    Existing LAGs
                </Header>
            }
        >
            <ColumnLayout columns={2}>
                <FormField
                    label={HelperFunctions.displayRequiredOrOptionalField(
                        "LAG Quantity:", HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                    )}
                    errorText={props.errorTexts.existingLagQuantity}
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.existingLagQuantity}
                        type={Constants.NUMBER}
                        disabled={props.isUpdateStageInProgress
                        || !HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                        || props.disabledFieldsList.includes(Constants.ATTRIBUTES.existingLagQuantity)}
                        value={props.updatedOrder.existingLagQuantity}
                        onInput={props.handleStageInputChange}
                    />
                </FormField>
                <FormField
                    label={HelperFunctions.displayRequiredOrOptionalField(
                        "Circuits per LAG:", HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                    )}
                    errorText={props.errorTexts.existingLagCircuitCount}
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.existingLagCircuitCount}
                        type={Constants.NUMBER}
                        disabled={props.isUpdateStageInProgress
                        || !HelperFunctions.parseBoolean(props.updatedOrder.lacpProvider)
                        || props.disabledFieldsList.includes(Constants.ATTRIBUTES.existingLagCircuitCount)}
                        value={props.updatedOrder.existingLagCircuitCount}
                        onInput={props.handleStageInputChange}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
    </Container>
);