import "@amzn/awsui-global-styles/polaris.css";
import React from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ensureAuthenticated from "auth/AuthGenerator";
import { Box, Flashbar, Link } from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontAppLayout from "common/FremontAppLayout";
import "index.css";
import { v4 as uuidv4 } from "uuid";
import { AwsRum } from "aws-rum-web";
import HelperFunctions from "./common/HelperFunctions";

const APP_MOUNT_POINT = "app";
const BANNER_MOUNT_POINT = "banner";

const createUserBackendCall = async (auth) => {
    try {
        // Here we obtain the mimicked user from session storage
        const mimickedUser = window.sessionStorage.getItem(Constants.MIMICKED_USER);

        const authToPass = auth;
        // Here we assign the userId and the jwtToken so we don't have to retrieve them every time
        // we make a backend call
        authToPass.userId = authToPass.getSignInUserSession().getIdToken().payload["custom:userAlias"];
        authToPass.jwtToken = authToPass.getSignInUserSession().getIdToken().getJwtToken();
        // Here we attach the mimickedUser to the auth object. Since the auth object is passed to all components,
        // it is an easy way to pass this information to all pages
        authToPass.mimickedUserId = mimickedUser;

        authToPass.uniqueId = uuidv4();
        const csrfResponse = await new FremontBackendClient().getCsrfToken(authToPass);
        authToPass.csrfToken = csrfResponse.csrfToken;
        const userResponse = await new FremontBackendClient().createUser(authToPass);

        // Technically, the loadFremont function is used before it is defined. However, since we only call the
        // loadFremont method when sandbox mode is updated, that means the component will have already rendered
        // so no problem occurs. Lint still complains, so we disable that property in this file
        /* eslint-disable no-use-before-define */
        const render = () => {
            unmountComponentAtNode(document.getElementById(BANNER_MOUNT_POINT));
            // If the above call is successful then we render the dashboard page
            ReactDOM.render(
                <div className="awsui">
                    <BrowserRouter>
                        <FremontAppLayout
                            authToPass={authToPass}
                            userResponse={userResponse}
                            loadFremont={loadFremont}
                        />
                    </BrowserRouter>
                </div>,
                document.getElementById(APP_MOUNT_POINT)
            );
        };

        // Here we render the DOM to load the webpage from the start
        render();
    } catch (error) {
        unmountComponentAtNode(document.getElementById(BANNER_MOUNT_POINT));
        const userName = auth.getSignInUserSession().getIdToken().payload["custom:userAlias"];
        // Here we render the error message as creating user is mandatory
        ReactDOM.render(
            <div className="awsui">
                <Flashbar
                    items={
                        [{
                            type: Constants.FLASHBAR_TYPES.info,
                            dismissible: false,
                            content: (
                                <div>
                                    <Box variant="h2">
                                        There was an issue while auto creating an account for user: {userName},
                                        please try again later.
                                    </Box>
                                    <Box variant="h2">
                                        If the issue persists please open a ticket
                                        with the
                                        <Link href={Constants.FOOTER_STRINGS.fremontBugLink} color="inverted">
                                            <span> TREND-NEST </span>
                                        </Link>
                                        team.
                                    </Box>
                                    <Box variant="h4">ErrorStack:</Box>{error.message}
                                </div>
                            )
                        }]
                    }
                />
            </div>,
            document.getElementById(APP_MOUNT_POINT)
        );
    }
};

// install and initialize cloudwatch RUM web client
// https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-configure-client.html
const initRum = (domain) => {
    const config = HelperFunctions.getCloudwatchRumConfig();

    const rumConfig = {
        sessionSampleRate: config.sessionSampleRate,
        guestRoleArn: domain === Constants.DOMAINS.fremont ? config.fremontGuestRoleArn : config.lighthouseGuestRoleArn,
        identityPoolId: domain === Constants.DOMAINS.fremont ?
            config.fremontIdentityPoolId : config.lighthouseIdentityPoolId,
        endpoint: config.endpoint,
        telemetries: config.telemetries,
        allowCookies: config.allowCookies,
        enableXRay: config.enableXRay
    };

    const awsRumMonitor = new AwsRum(
        domain === Constants.DOMAINS.fremont ? config.fremontApplicationId : config.lighthouseApplicationId,
        config.applicationVersion,
        config.applicationRegion,
        rumConfig
    );

    return awsRumMonitor;
};


const loadFremont = () => {
    // initialize cloudwatch RUM web client
    try {
        // we don't want RUM in local developer environments
        if (HelperFunctions.isBetaOrGamma() || HelperFunctions.isProd()) {
            Object.values(Constants.DOMAINS).forEach(domain => initRum(domain));
        }
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }

    ensureAuthenticated().then((auth) => {
        // Here we call the createUser backend in order to create the user at the time of loading
        createUserBackendCall(auth);
    }).catch((e) => {
        unmountComponentAtNode(document.getElementById(BANNER_MOUNT_POINT));
        ReactDOM.render(
            <div className="awsui">
                <Flashbar
                    items={
                        [{
                            type: Constants.FLASHBAR_TYPES.info,
                            dismissible: false,
                            content: (
                                <div>
                                    <Box variant="h2">
                                        We are currently experiencing technical difficulties with our application.
                                        Our team is actively working to fix the issue.
                                        We hope to have this resolved soon.
                                        Thank you for your patience, please try again later.
                                    </Box>
                                    <Box variant="h4">ErrorStack:</Box>{e.toString()}
                                </div>
                            )
                        }]
                    }
                />
            </div>,
            document.getElementById(APP_MOUNT_POINT)
        );
    });
};

ReactDOM.render(
    <div className="awsui">
        <Flashbar
            items={
                [{
                    type: Constants.FLASHBAR_TYPES.info,
                    dismissible: false,
                    content: (
                        <span className="white-text">
                            <Box variant="h2" color="inherit">
                                Signing In... (If the page does not load, try refreshing the page)
                            </Box>
                        </span>
                    ),
                    loading: true
                }]
            }
        />
    </div>,
    document.getElementById(BANNER_MOUNT_POINT)
);

loadFremont();