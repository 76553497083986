import React, { Component } from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Popover,
    Header,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import { ClipLoader } from "react-spinners";
import Constants from "../utils/Constants.js";
import {
    LinkServiceButton,
    TabularDisplayComponent
} from "../components/CommonComponents";
import HelperFunctions from "../common/HelperFunctions";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import DeleteLinksModal from "../components/DeleteLinksModal";

export const LINK_DETAIL_FIELDS_LIST = [
    Constants.LINK_DETAIL_FIELDS_MAP.aEndPort,
    Constants.LINK_DETAIL_FIELDS_MAP.bEndPort,
    Constants.LINK_DETAIL_FIELDS_MAP.lifecycleState,
    Constants.LINK_DETAIL_FIELDS_MAP.readableLinkType,
    Constants.LINK_DETAIL_FIELDS_MAP.instanceVersion,
    Constants.LINK_DETAIL_FIELDS_MAP.typeVersion,
    Constants.LINK_DETAIL_FIELDS_MAP.encryptionCapability,
    Constants.LINK_DETAIL_FIELDS_MAP.encryptionIntent,
    Constants.LINK_DETAIL_FIELDS_MAP.appliedEncryption
];

export const DO_NOT_EDIT_ATTRIBUTES = [
    Constants.CONSUMPTION_ATTRIBUTES.consumesList,
    Constants.CONSUMPTION_ATTRIBUTES.consumerList,
    Constants.ATTRIBUTES.linkType,
    Constants.ATTRIBUTES.legacyCircuitDbId,
    Constants.ATTRIBUTES.user_provided_id,
    Constants.ATTRIBUTES.och_trail,
    Constants.ATTRIBUTES.ots_trail,
    Constants.ATTRIBUTES.fibre_trail,
    Constants.ATTRIBUTES.client_path_name,
    Constants.ATTRIBUTES.end_a_nsm_name,
    Constants.ATTRIBUTES.end_a_nsm_region,
    Constants.ATTRIBUTES.end_a_nsm_uuid,
    Constants.ATTRIBUTES.end_z_nsm_name,
    Constants.ATTRIBUTES.end_z_nsm_region,
    Constants.ATTRIBUTES.end_z_nsm_uuid
];

export default class LinkInformation extends Component {
    state = {
        linkDetailFields: HelperFunctions.createKeyValueListFromObject(this.props.link, LINK_DETAIL_FIELDS_LIST),
        isDeleteModalVisible: false,
        consumersForAllLinks: []
    }

    componentDidUpdate(prevProps) {
        if (prevProps.link.instanceId !== this.props.link.instanceId) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                linkDetailFields: HelperFunctions.createKeyValueListFromObject(this.props.link, LINK_DETAIL_FIELDS_LIST)
            });
        }
    }

    onDeleteButtonClick = async () => {
        this.setIsDeleteModalVisible(true);
        const client = new LinkServiceBackendClient();
        const getConsumers = item => HelperFunctions.getValueFromRecordAttributes(item, Constants.ATTRIBUTES.consumers);

        const linkConsumers = (await Promise.all(
            [this.props.link].map(item => (
                getConsumers(item) ?
                    ({ Consumers: JSON.parse(getConsumers(item)) }) :
                    client.listConsumers(Constants.LINK_INSTANCE_ID_PATTERN + item.instanceId)
            ))
        )).map(response => response.Consumers.map(id => HelperFunctions.getUuidFromFullLinkId(id)));

        const linkConsumerItems = await Promise.all(
            linkConsumers.map(consumers => client.getBatchLinkInstance(consumers))
        );
        this.setConsumersForAllLinks(linkConsumerItems);
    };

    setConsumersForAllLinks = (linkConsumerItems) => {
        let { consumersForAllLinks } = this.state;
        consumersForAllLinks = linkConsumerItems;
        this.setState({ consumersForAllLinks });
    }

    setIsDeleteModalVisible = (checkForModalVisibility) => {
        let { isDeleteModalVisible } = this.state;
        isDeleteModalVisible = checkForModalVisibility;
        this.setState({ isDeleteModalVisible });
    }

    render() {
        return (
            <div>
                {this.state.isDeleteModalVisible ? <DeleteLinksModal
                    items={[this.props.link]}
                    setIsVisible={this.setIsDeleteModalVisible}
                    consumersForAllLinks={this.state.consumersForAllLinks}
                    updateLinkHierarchy={this.props.updateLinkHierarchy}
                    handleFlashbarMessageFromChildTabs={this.props.handleFlashbarMessageFromChildTabs}
                    linkType={`${this.props.link.readableLinkType}`}
                /> : null}
                {
                    this.props.loadingLink ?
                        (
                            <div className="centerSpinner">
                                <ClipLoader
                                    color={Constants.SPINNER_ORANGE_COLOR}
                                    loading
                                    size={Constants.SPINNER_SIZES.loadingPage}
                                />
                            </div>
                        )
                        :
                        (
                            <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                                <Container
                                    header={
                                        <Header
                                            variant="h2"
                                            actions={
                                                this.props.link.readableLinkType
                                                === Constants.LINK_TYPES.routerToDWDM ?
                                                    <LinkServiceButton
                                                        variant="primary"
                                                        iconName="remove"
                                                        iconAlign="left"
                                                        onClick={this.onDeleteButtonClick}
                                                        loading={false}
                                                    >
                                                    Delete Record
                                                    </LinkServiceButton> :
                                                    null
                                            }
                                        >
                                            Link Information
                                        </Header>
                                    }
                                >
                                    <SpaceBetween
                                        direction="vertical"
                                        size={Constants.PADDING_SIZES.SPACE_BETWEEN_CONTAINER_PADDING}
                                    >
                                        <Box
                                            color="text-label"
                                            fontSize="heading-m"
                                        >
                                            <span className="custom-wrapping">
                                                {`Link ID : 
                                                ${Constants.LINK_INSTANCE_ID_PATTERN}${this.props.link.instanceId}`}
                                                <Box margin={{ left: "xxs" }} display="inline-block">
                                                    <Popover
                                                        size="small"
                                                        position="top"
                                                        triggerType="custom"
                                                        dismissButton={false}
                                                        content={
                                                            <StatusIndicator type="success">
                                                                Link ID copied
                                                            </StatusIndicator>
                                                        }
                                                    >
                                                        <LinkServiceButton
                                                            variant="inline-icon"
                                                            iconName="copy"
                                                            ariaLabel="Copy ID"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(`${Constants.LINK_INSTANCE_ID_PATTERN}${this.props.link.instanceId}`);
                                                            }}
                                                        />
                                                    </Popover>
                                                </Box>
                                            </span>
                                        </Box>

                                        <ColumnLayout columns={2} borders="vertical">
                                            <TabularDisplayComponent
                                                title="Link Details"
                                                keyValueFields={this.state.linkDetailFields}
                                            />
                                            <TabularDisplayComponent
                                                title="Attributes"
                                                keyValueFields={this.props.link.attributesToDisplay}
                                            />
                                        </ColumnLayout>
                                    </SpaceBetween>
                                </Container>
                            </SpaceBetween>
                        )}
            </div>
        );
    }
}
