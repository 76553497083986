import React, { Component, createRef } from "react";
import { Alert, Link, SpaceBetween } from "@amzn/awsui-components-react";
import LinkServiceHeader from "../common/LinkServiceHeader";
import HelperFunctions from "../common/HelperFunctions";
import Constants from "../utils/Constants";
import FremontConstants from "../../../utils/Constants";
import LinkSearchFilter, { searchTermParseString } from "./LinkSearchFilter";
import LinkHierarchy from "../link/LinkHierarchy";
import LinkHierarchyHelper from "../link/LinkHierarchyHelper";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";

export default class LinkSearchPage extends Component {
    constructor(props) {
        super(props);
        // We create a reference to the linkSearchFilter child component here. This allows us to update the state of the
        // link filter without having to rerender the linkHierarchy data which makes the page slow.
        // We reference this value to obtain the current search term the user has entered or was passed in through
        // the URL
        this.linkSearchFilterRef = createRef();
    }

    state = {
        flashbar: {
            type: "",
            text: ""
        },
        isSearchInProgress: false,
        linksInLinkTypeMap: LinkHierarchyHelper.getEmptyLinkMap(),
        isFirstTimeOnPage: true,
        tooManyLinksFound: false
    }

    componentDidMount = async () => {
        if (!this.linkSearchFilterRef.current) {
            return;
        }
        if (this.linkSearchFilterRef.current.state.searchTerm) await this.executeSearch();
    }

    getInformationalMessageComponent = () => (
        <Alert statusIconAriaLabel="Info" header="Searching By Fibre Attributes">
            Please use the
            <Link
                external
                href="https://w.amazon.com/bin/view/Networking/Fremont/Resources/ProjectMango/DWDMDashboards/Fibre/"
            >
                &nbsp;fibre dashboard
            </Link>
            &nbsp;if you are trying to search by fibre attributes such as the fibre vendor, cross
            connect, amazon span id, etc.
        </Alert>
    );

    linkServiceBackendClient = new LinkServiceBackendClient()

    /**
     * When the user hits the search button, we either change the URL to the new search term to ensure
     * the URL always contains the last search term used. If the search term is the same, we resubmit
     * the search request.
     */
    handleSearchTermSubmit = async () => {
        // window.location.href looks something like http://localhost:8080/linkSearch?searchTerm=asdf
        // We split on the `?searchTerm=` and then slice it off so we are only left with the user entered value
        const currentSearchTerm = window.location.href.split(FremontConstants.MANGO_ROUTES.linkSearch)[1]
            .slice(searchTermParseString.length);
        if (currentSearchTerm !== this.linkSearchFilterRef.current.state.searchTerm) {
            this.props.history.push(
                `${FremontConstants.MANGO_ROUTES.linkSearch}${searchTermParseString}${encodeURI(this.linkSearchFilterRef.current.state.searchTerm)}`
            );
        }

        await this.executeSearch();
    }

    /**
     * Executes search and also fetches the first 100 links in the hierarchy
     * @returns {Promise<void>}
     */
    executeSearch = async () => {
        // Clear out search results
        this.setState({
            linksInLinkTypeMap: LinkHierarchyHelper.getEmptyLinkMap()
        });
        HelperFunctions.dismissFlashbar(this, {
            isSearchInProgress: true,
            isFirstTimeOnPage: false,
            tooManyLinksFound: false
        });

        try {
            const searchLinksResponse = await this.linkServiceBackendClient.searchLinksWithHierarchies(
                this.linkSearchFilterRef.current.state.searchTerm
            );

            // After fetching the links from search, we add them to the linkTypeMap
            // First reset the linksInLinkType map
            const linksInLinkTypeMap = LinkHierarchyHelper.getEmptyLinkMap();
            // Add the search links to the hierarchy
            LinkHierarchyHelper.addLinksToLinkTypeMap(searchLinksResponse.Links, linksInLinkTypeMap);

            // add client ports to RouterToRouter links
            const routerToRouterLinksWithClientPorts = LinkHierarchyHelper.addClientPortsToRouterToRouterLinks(
                linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter],
                linksInLinkTypeMap[Constants.LINK_TYPES.routerToDWDM]
            );
            linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter] = routerToRouterLinksWithClientPorts;

            // Set the initial state of links
            this.setState({ linksInLinkTypeMap: HelperFunctions.filterNativeLinks(linksInLinkTypeMap) });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }

        this.setState({
            isSearchInProgress: false
        });
    }

    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    handleFlashbarMessageFromSearchPage = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    updateTableItems = async () => {
        const searchLinksResponse = await this.linkServiceBackendClient.searchLinksWithHierarchies(
            this.linkSearchFilterRef.current.state.searchTerm
        );
        const linksInLinkTypeMap = LinkHierarchyHelper.getEmptyLinkMap();
        LinkHierarchyHelper.addLinksToLinkTypeMap(searchLinksResponse.Links, linksInLinkTypeMap);

        // add client ports to RouterToRouter links
        const routerToRouterLinksWithClientPorts = LinkHierarchyHelper.addClientPortsToRouterToRouterLinks(
            linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter],
            linksInLinkTypeMap[Constants.LINK_TYPES.routerToDWDM]
        );
        linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter] = routerToRouterLinksWithClientPorts;

        // Set state of links after Delete action
        this.setState({ linksInLinkTypeMap: HelperFunctions.filterNativeLinks(linksInLinkTypeMap) });
    }
    render() {
        return (
            <div>
                <LinkServiceHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                        <LinkSearchFilter
                            ref={this.linkSearchFilterRef}
                            searchTermFromUrl={this.props.history.location.search}
                            primaryHeaderText="Search Term"
                            isSearchInProgress={this.state.isSearchInProgress}
                            handleSearchTermSubmit={this.handleSearchTermSubmit}
                            informationalMessageComponent={this.getInformationalMessageComponent()}
                            showCopyUrlPopover
                            isClientCutsheetEnabled={
                                this.state.linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter] &&
                                this.state.linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter].length > 0
                            }
                            isLineCutsheetEnabled={
                                this.state.linksInLinkTypeMap[Constants.LINK_TYPES.muxToMux] &&
                                this.state.linksInLinkTypeMap[Constants.LINK_TYPES.muxToMux].length > 0
                            }
                            showIspCutsheetLinkButtons
                        />
                        <LinkHierarchy
                            editable={false}
                            expanded
                            linksInLinkTypeMap={this.state.linksInLinkTypeMap}
                            isSearchInProgress={this.state.isSearchInProgress}
                            isFirstTimeOnPage={this.state.isFirstTimeOnPage}
                            tooManyLinksFound={this.state.tooManyLinksFound}
                            updateTableItems={this.updateTableItems}
                            isDeleteEnabled
                            handleFlashbarMessageFromSearchPage={this.handleFlashbarMessageFromSearchPage}
                            isSearchPage
                        />
                    </SpaceBetween>
                </div>
            </div>
        );
    }
}