import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontSelect,
    requiredLabel,
    optionalLabel,
    ComponentConstants
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

export const NewOrderInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Service Type:</Box>
                        <div>{HelperFunctions.getOrderServiceTypeCustomerFabricString(props.order)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Order Type:</Box>
                        <div>
                            {Constants.BACKEND_TO_FRONTEND_MAP[props.order[Constants.ATTRIBUTES.orderType]]}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Business Need:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.businessNeed]}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Required Completion Date:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.requiredCompletionDate]}</div>
                    </div>
                    {props.order[Constants.ATTRIBUTES.peeringDBInternetExchangeName] &&
                    <div>
                        <Box color="text-label">Order Type:</Box>
                        <div>
                            {Constants.BACKEND_TO_FRONTEND_MAP[props.order[Constants.ATTRIBUTES.orderType]]}
                        </div>
                    </div>
                    }
                    {props.order[Constants.ATTRIBUTES.transitType] &&
                    <div>
                        <Box color="text-label">Business Need:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.businessNeed]}</div>
                    </div>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric) &&
                    <div>
                        <Box color="text-label">Fiber Type:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.fiberType] || "-"}</div>
                    </div>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric) &&
                    <div>
                        <Box color="text-label">Connector Type:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.connectorType] || "-"}</div>
                    </div>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric) &&
                    <div>
                        <Box color="text-label">LH Design SIM:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.lhDesignSIM] || "-"}</div>
                    </div>
                    }
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const NewOrderInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    New Order Information
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {requiredLabel("Service Type:")}
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.serviceTypeEditExplanation)
                                }
                            </SpaceBetween>
                        }
                        errorText={props.errorTexts[Constants.ATTRIBUTES.serviceType]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.serviceType}
                            disabled
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(Constants.SERVICE_TYPES)
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder[Constants.ATTRIBUTES.serviceType]
                            )}
                            onChange={props.handleStageInputChange}
                            expandToViewport
                        />
                    </FormField>
                    <FormField
                        label={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {requiredLabel("Order Type:")}
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.orderTypeEditExplanation)
                                }
                            </SpaceBetween>
                        }
                        errorText={props.errorTexts[Constants.ATTRIBUTES.orderType]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.orderType}
                            options={HelperFunctions.createSelectedOptionsWithFrontEndLabels(
                                Object.values(Constants.ORDER_TYPES)
                            )}
                            disabled
                            selectedOption={HelperFunctions.createSelectedOptionWithFrontEndLabel(
                                props.updatedOrder[Constants.ATTRIBUTES.orderType]
                            )}
                            onChange={props.handleStageInputChange}
                            expandToViewport
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Business Need:")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.businessNeed]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES[Constants.ATTRIBUTES.businessNeed]}
                            disabled={props.isUpdateInProgress
                            || props.disabledFieldsList.includes(Constants.ATTRIBUTES.businessNeed)
                            || OrderValidation.getBusinessNeedOptions(
                                props.updatedOrder.orderType, props.updatedOrder.serviceType
                            ).length <= 1}
                            options={OrderValidation.getBusinessNeedOptions(
                                props.updatedOrder.orderType, props.updatedOrder.serviceType
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder[Constants.ATTRIBUTES.businessNeed]
                            )}
                            onChange={props.handleStageInputChange}
                            expandToViewport
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Required Completion Date:")}
                        errorText={
                            props.errorTexts[Constants.ATTRIBUTES.requiredCompletionDate]
                        }
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.requiredCompletionDate}
                            placeholder={props.isUpdateInProgress ?
                                "" : Constants.DATE_FORMAT}
                            disabled={props.isUpdateInProgress
                            || props.disabledFieldsList.includes(Constants.ATTRIBUTES.requiredCompletionDate)}
                            value={props.updatedOrder[Constants.ATTRIBUTES.requiredCompletionDate]
                                ? props.updatedOrder[Constants.ATTRIBUTES.requiredCompletionDate] : ""}
                            onChange={props.handleStageInputChange}
                            onInput={props.handleStageInputChange}
                        />
                    </FormField>
                    {props.updatedOrder.serviceType === Constants.SERVICE_TYPES.INTERNET_EXCHANGE
                    && props.updatedOrder.orderType === Constants.ORDER_TYPES.INSTALL &&
                    <FormField
                        label={requiredLabel("Internet Exchange Name:")}
                        errorText={props.errorTexts.peeringDBInternetExchangeName}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.peeringDBInternetExchangeName}
                            placeholder={props.isUpdateInProgress ? "" : "Select Internet Exchange Name"}
                            disabled={props.isUpdateInProgress
                            || props.disabledFieldsList
                                .includes(Constants.ATTRIBUTES.peeringDBInternetExchangeName)}
                            options={HelperFunctions.createSelectedOptions(
                                Constants.INTERNET_EXCHANGE_NAMES
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder[Constants.ATTRIBUTES.peeringDBInternetExchangeName]
                            )}
                            onChange={props.handleStageInputChange}
                            filteringType="auto"
                            noMatch="No Internet Exchanges matching your search"
                            expandToViewport
                        />
                    </FormField>
                    }
                    {props.updatedOrder.serviceType === Constants.SERVICE_TYPES.IP_TRANSIT &&
                    <FormField
                        label={requiredLabel("Transit Type:")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.businessNeed]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.transitType}
                            disabled={props.isUpdateInProgress
                            || props.disabledFieldsList.includes(Constants.ATTRIBUTES.transitType)}
                            options={HelperFunctions.createSelectedOptions(Constants.TRANSIT_TYPES)}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder[Constants.ATTRIBUTES.transitType]
                            )}
                            onChange={props.handleStageInputChange}
                            expandToViewport
                        />
                    </FormField>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.updatedOrder.customerFabric) &&
                    <FormField
                        label={requiredLabel("Fiber Type:")}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.fiberType}
                            options={Constants.FIBER_TYPE_OPTIONS}
                            disabled={props.isUpdateStageInProgress
                            || props.disabledFieldsList.includes(
                                Constants.ATTRIBUTES.fiberType
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder.fiberType
                            )}
                            onChange={props.handleStageInputChange}
                            placeholder="Select fiber type"
                            expandToViewport
                        />
                    </FormField>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.updatedOrder.customerFabric) &&
                    <FormField
                        label={requiredLabel("Connector Type:")}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.connectorType}
                            options={Constants.CONNECTOR_TYPE_OPTIONS}
                            disabled={props.isUpdateStageInProgress
                            || props.disabledFieldsList.includes(
                                Constants.ATTRIBUTES.connectorType
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedOrder.connectorType
                            )}
                            onChange={props.handleStageInputChange}
                            placeholder="Select connector type"
                            expandToViewport
                        />
                    </FormField>
                    }
                    {Constants.PATH_CUSTOMER_FABRICS.includes(props.updatedOrder.customerFabric) &&
                    <FormField
                        label={optionalLabel("LH Design SIM:")}
                        errorText={props.errorTexts.lhDesignSIM}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.lhDesignSIM}
                            disabled={props.isUpdateStageInProgress
                            || props.disabledFieldsList
                                .includes(Constants.ATTRIBUTES.lhDesignSIM)}
                            value={props.updatedOrder.lhDesignSIM}
                            onInput={props.handleStageInputChange}
                            placeholder="Enter a design SIM"
                        />
                    </FormField>
                    }
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
