import React, { Component } from "react";
import {
    Box,
    Tabs
} from "@amzn/awsui-components-react/polaris";
import AsnTab from "asn/AsnTab";
import AttachmentTab from "attachment/AttachmentTab";
import AuditTab from "audit/AuditTab";
import ContactTab from "contact/ContactTab";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import NoteTab from "note/NoteTab";
import OrderTab from "order/OrderTab";
import ProviderServiceInformation from "providerService/ProviderServiceInformation";
import ProviderServiceValidation from "providerService/ProviderServiceValidation";
import { withRouter } from "react-router-dom";

/**
 * ProviderServiceDetailsPage acts as the providerService info landing page and displays all
 * of the providerService information and its related info.
 */
class ProviderServiceDetailsPage extends Component {
    static PROVIDER_SERVICE_INFO_TAB_ID = "details";
    static PROVIDER_SERVICE_CONTACT_TAB_ID = "providerServiceContacts";
    static PROVIDER_SERVICE_ASN_TAB_ID = "providerServiceAsns";
    static PROVIDER_SERVICE_ORDER_TAB_ID = "providerServiceOrders";
    static PROVIDER_SERVICE_NOTE_TAB_ID = "providerServiceNotes";
    static PROVIDER_SERVICE_AUDIT_TAB_ID = "providerServiceAudits";
    static PROVIDER_SERVICE_ATTACHMENT_TAB_ID = "providerServiceAttachments";

    state = {
        activeTabId: "details",
        flashbar: {
            type: "",
            text: ""
        },
        contactsLoading: false,
        contactOptions: [],
        contactIdToNameMap: {},
        escalationPathObjects: [],
        // A shallow copy of the escalationPathObjects array is needed so that any changes made on the edit pane
        // that are not submitted do not transfer over to the details page
        escalationPathObjectsShallowCopy: [],
        contactObjects: [],
        // A shallow copy of the contactObjects array is need for the same reason. If a user add or deletes a contact
        // on an edit form and then clicks cancel, we do not want to display the changes they made
        contactObjectsShallowCopy: [],
        isPageLoading: true,
        isSpinnerShown: true,
        updatedProviderService: {},
        hasUpdateProviderServiceSubmittedOnce: false,
        isUpdateProviderServiceInfoEditClicked: false,
        isUpdateProviderServiceInfoInProgress: false,
        providerServiceAttachments: [],
        isDownloadingAttachment: false,
        providerService: {
            providerName: "",
            serviceType: "",
            attachmentIdMap: {}
        },
        providerServiceErrorTexts: ProviderServiceValidation.EMPTY_PROVIDER_SERVICE_ERROR_TEXTS
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        } else {
            await this.fetchProviderServiceInfo();
            document.title = `${this.state.providerService.providerName}'s ${this.state.providerService.serviceType}`;
            await this.loadAttachments();
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    /**
     * Returns a list of escalation levels with corresponding contacts to be displayed on the escalation path table
     * @returns {{escalationLevelObjects: *[], escalationLevel: number}[]}
     */
    getEscalationPathItemsForTable = () => Constants.ESCALATION_PATH_LEVELS.map(escalationLevel => ({
        escalationLevel,
        escalationLevelObjects: this.state.escalationPathObjects.filter(escalationPathObject =>
            escalationPathObject.level === escalationLevel)
    }));

    FremontBackendClient = new FremontBackendClient();

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    loadAttachments = async () => {
        const attachmentIdSet =
            new Set(this.extractAttachmentIdsFromMap(this.state.providerService.attachmentIdMap));
        const providerServiceAttachments = await this.fetchBatchAttachments(attachmentIdSet);
        this.setState({
            providerServiceAttachments,
            isPageLoading: false
        });
    }

    fetchBatchAttachments = async (attachmentIdSet) => {
        const attachmentList = [];
        if (attachmentIdSet.size === 0) return attachmentList;
        try {
            const batchAttachmentObjectResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ATTACHMENT, Array.from(attachmentIdSet.keys()), this.props.auth
            );
            batchAttachmentObjectResponse.attachments.forEach((attachment) => {
                const attachmentClone = HelperFunctions.deepClone(attachment);
                attachmentList.push(attachmentClone);
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        return attachmentList;
    };

    /**
     * This method is used for fetching all of the providerService information
     */
    fetchProviderServiceInfo = async () => {
        try {
            this.setState({ isPageLoading: true });
            const providerServiceObjectResponse = await this.FremontBackendClient.getProviderServiceInfo(
                this.props.match.params.providerServiceId, this.props.auth
            );

            // Obtaining each contact object that is a part of the escalationPath
            const batchGetContactsResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT,
                Object.keys(providerServiceObjectResponse.providerService.escalationPath), this.props.auth
            );

            // Defining contactIdToNameMap because it is used in multiple function calls in setState below
            const contactIdToNameMap = new Map();
            batchGetContactsResponse.contacts.map(contact =>
                contactIdToNameMap.set(contact.contactId, `${contact.firstName} ${contact.lastName}`));

            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                contactIdToNameMap,
                escalationPathObjects: this.createEscalationPathObjects(
                    providerServiceObjectResponse.providerService.escalationPath, contactIdToNameMap,
                    providerServiceObjectResponse.providerService.escalationPriority
                ),
                isPageLoading: false,
                providerService: providerServiceObjectResponse.providerService
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isPageLoading: false });
        }
    };

    fetchContactOptions = async () => {
        // Need to change state before we begin making any backend calls so that edit page appears immediately
        this.changeEditState();
        // We only perform the logic to obtain additional contacts if no contactOptions exist
        if (this.state.contactOptions.length === 0) {
            try {
                this.setState({ contactsLoading: true });
                const editableContactIdToNameMap = this.state.contactIdToNameMap;
                // Need to obtain the provider object so that we can get all of the contacts
                // associated with the provider
                const providerObjectResponse = await this.FremontBackendClient.getProviderInfo(
                    this.state.providerService.providerName, this.props.auth
                );

                // Any contact objects that were in the escalationPath were put into the contactIdToNameMap,
                // so we do not want to make a duplicate call to the backend to obtain information we already have
                const contactIds = providerObjectResponse.provider.contactIdList.filter(contactId =>
                    !editableContactIdToNameMap.has(contactId));

                // If the provider has more contacts whose information we have not obtained, we make a batch call
                // to get the information
                if (contactIds.length > 0) {
                    const batchGetContactsResponse = await this.FremontBackendClient.getBatch(
                        Constants.BATCH_ENTITIES.CONTACT, contactIds, this.props.auth
                    );

                    // Here we add newly obtained contacts to the (editable) contactIdToNameMap
                    batchGetContactsResponse.contacts.map(contact =>
                        editableContactIdToNameMap.set(contact.contactId, `${contact.firstName} ${contact.lastName}`));
                }

                // Here we create the contact options from the contactIdToNameMap
                const contactOptions = [];
                editableContactIdToNameMap.forEach((value, key) =>
                    contactOptions.push({ label: value, value: key }));
                const contactObjects = this.createContactObjects(
                    this.state.providerService.contactIdList, editableContactIdToNameMap
                );
                this.setState({
                    contactIdToNameMap: editableContactIdToNameMap,
                    contactsLoading: false,
                    contactObjects,
                    contactObjectsShallowCopy: contactObjects,
                    contactOptions
                });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, { contactsLoading: false });
            }
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     * This method creates an array of contact objects when a raw contactIdList and contactIdToNameMap
     * are passed in
     */
    createContactObjects = (contactIdList, contactIdToNameMap) => {
        const contactObjects = [];
        if (contactIdList.length > 0) {
            const sortedContactIds = [];
            contactIdList.forEach(contactId =>
                sortedContactIds.push({
                    contactId,
                    contactName: contactIdToNameMap.get(contactId)
                }));
            sortedContactIds.sort((a, b) => a.contactName.localeCompare(b.contactName));
            sortedContactIds.map((contactObject, i) =>
                contactObjects.push({
                    id: `contact${i + 1}`,
                    selectedOption:
                        {
                            label: contactObject.contactName,
                            value: contactObject.contactId
                        },
                    errorText: ""
                }));
        } else {
            contactObjects.push(ProviderServiceValidation.BLANK_CONTACT_OBJECT);
        }
        return contactObjects;
    };

    /**
     * This method creates an array of escalationPath objects when a raw escalation path map and contactIdToNameMap
     * are passed in
     */
    createEscalationPathObjects = (escalationPathMap, contactIdToNameMap, escalationPriorityMap) => {
        const escalationPathObjects = [];

        if (Object.keys(escalationPathMap).length > 0) {
            Object.entries(escalationPathMap).map(entry =>
                // The first step is to turn the array of entries (a two-dimensional array) into an array of objects
                // with names that are easy to read. The Object.entries function returns a two dimensional array. Each
                // inner array has a length of two and stores the key as the first element and the value as the second
                // element. In the case of the escalationPathMap, the key is the contactId and the value is the
                // escalationLevel. We assign the appropriate indexes to each object entry name so the rest of the
                // function is easy to read
                ({ contactId: entry[Constants.MAP_INDEXES.keyIndex], level: entry[Constants.MAP_INDEXES.valueIndex] }))
                // Here we sort the array of objects based upon the level value so that we can display the
                // escalation path in ascending order
                .sort((a, b) => (a.level < b.level ? -1 : 1)).forEach((contactIdLevelObject, i) =>
                    escalationPathObjects.push(
                        {
                            id: `escalationPath${i + 1}`,
                            selectedOption:
                                {
                                    label: contactIdToNameMap.get(contactIdLevelObject.contactId),
                                    value: contactIdLevelObject.contactId
                                },
                            level: contactIdLevelObject.level,
                            priority: escalationPriorityMap ?
                                escalationPriorityMap.get(contactIdLevelObject.contactId) : "",
                            contactNameErrorText: "",
                            levelErrorText: ""
                        }
                    ));
        } else {
            escalationPathObjects.push(ProviderServiceValidation.BLANK_ESCALATION_PATH_OBJECT);
        }
        return escalationPathObjects;
    };

    /**
     **************************************************************************************************
     * UPDATE UTILITY FUNCTIONS
     **************************************************************************************************
     */

    /**
     * Changes the providerService info container to edit mode to edit the request object
     */
    handleUpdateProviderServiceEdit = async () => {
        this.handleFlashbarClose();
        if (!this.state.isUpdateProviderServiceInfoEditClicked) {
            await this.fetchContactOptions();
        } else {
            this.changeEditState();
        }
    };

    /**
     * This function alters state in a manner that moves the details page to and from edit mode
     */
    changeEditState = () => {
        this.setState({
            isUpdateProviderServiceInfoEditClicked: !this.state.isUpdateProviderServiceInfoEditClicked,
            hasUpdateProviderServiceSubmittedOnce: false,
            providerServiceErrorTexts: ProviderServiceValidation.EMPTY_PROVIDER_SERVICE_ERROR_TEXTS,
            updatedProviderService: this.state.providerService,
            isPageLoading: false,
            // When the edit button is clicked, we create the shallow copies of the escalationPath and contact objects.
            // This allows the contact and escalationPath selection fields to be dynamic on the edit page, but any
            // changes that are not submitted do not carry over to the detail page
            escalationPathObjectsShallowCopy: this.state.escalationPathObjects,
            contactObjectsShallowCopy: this.state.contactObjects
        });
    };

    /**
     This function handles change of inputs to the providerService information container fields.
     */
    handleUpdateProviderServiceInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.providerService = HelperFunctions.deepClone(this.state.updatedProviderService);
        input.providerServiceErrorTexts = HelperFunctions.deepClone(this.state.providerServiceErrorTexts);
        // We assign the name input.escalationPathObjects to the shallow copy because the providerServiceValidation
        // validateInput function uses the name escalationPathObjects to assign changes
        input.escalationPathObjects = HelperFunctions.deepClone(this.state.escalationPathObjectsShallowCopy);
        // Same as above
        input.contactObjects = HelperFunctions.deepClone(this.state.contactObjectsShallowCopy);

        const output = ProviderServiceValidation.validateInput(input);

        this.setState({
            updatedProviderService: output.providerService,
            providerServiceErrorTexts: output.providerServiceErrorTexts,
            // All changes made to the escalationPathObjects field returned from validating the input are assigned
            // to the shallow copy. This is what makes the detail page dynamically change with input
            escalationPathObjectsShallowCopy: output.escalationPathObjects,
            // Same as above
            contactObjectsShallowCopy: output.contactObjects
        });
    };

    /**
     * Handles the addition or subtraction of new escalationPath or contact objects
     */
    handleAdditionalInput = (evt) => {
        const input = {};
        input.objectChanged = evt.target.id;
        input.escalationPathObjects = this.state.escalationPathObjectsShallowCopy;
        input.contactObjects = this.state.contactObjectsShallowCopy;

        const output = ProviderServiceValidation.handleAdditionalInput(input);

        this.setState({
            contactObjectsShallowCopy: output.contactObjects,
            escalationPathObjectsShallowCopy: output.escalationPathObjects
        });
    };

    /**
     * Handles subtracting an input field from either the the escalationPathObjectsShallowCopy or contactObject field.
     * Removes the field whose close button was pressed
     */
    handleSubtractSpecificObject = (evt) => {
        if (evt.target.id.includes("contact")) {
            this.setState({
                contactObjectsShallowCopy: HelperFunctions.subtractSpecificObjectHelper(
                    this.state.contactObjectsShallowCopy,
                    evt.target.id,
                    Constants.DYNAMIC_INPUT_TYPES.contact
                )
            });
        }
        if (evt.target.id.includes("escalationPath")) {
            this.setState({
                escalationPathObjectsShallowCopy: HelperFunctions.subtractSpecificObjectHelper(
                    this.state.escalationPathObjectsShallowCopy,
                    evt.target.id,
                    Constants.DYNAMIC_INPUT_TYPES.escalationPath
                )
            });
        }
    };

    downloadAttachment = async (attachmentId) => {
        this.setState({ isDownloadingAttachment: true });
        try {
            const getAttachmentResponse = await this.FremontBackendClient.getAttachmentInfo(attachmentId,
                this.props.auth);

            // I wanted to use XMLHttpRequest, since that is what we use to upload the files, but even the presence of
            // the Content-Disposition didn't help in downloading the file. So the workaround here is creating an <a>
            // element and "clicking" it to download in the browser. The <a> element is simply an anchor element that
            // contains and href element. We assign our presigned url as the href of this newly created element and
            // thats all it takes to download it.
            // More on it not working with XMLHttpRequest: https://stackoverflow.com/a/22738657
            fetch(getAttachmentResponse.presignedUrl).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.download = getAttachmentResponse.attachment.fileName;
                    anchorElement.click();
                    anchorElement.remove();

                    this.setState({ isDownloadingAttachment: false });
                });
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this,
                { message: "Unable to download the attachment." }, { isDownloadingAttachment: false });
        }
    };

    /**
     * This function determines whether or not the Add Additional EscalationPath button should be enabled or not.
     * The add escalation path button is disabled if any field has no value, or if no more contacts can be selected.
     * We know that no more contacts can be selected if the number of escalationPathObjects is equal to the number of
     * contact options.
     */
    disableAddEscalationPathButton = () => this.mapFunctionToEscalationPathObjectsShallowCopyArray(escalationPath =>
        // Ensuring that no fields have empty values
        escalationPath.level === "" || Object.entries(escalationPath.selectedOption).length === 0) ||
        // If the number of escalationPathObjects is equal to the number of contact options,
        // no more escalationPathObjects can be created
        (this.state.escalationPathObjectsShallowCopy.length === this.state.contactOptions.length);

    /**
     * This function determines whether or not the Add Additional Contact button should be enabled or not.
     * The add contact button is disabled if any contact name field is blank, or if no more contacts can be selected.
     * We know that no more contacts can be selected if the number of contactPathObjects is equal to the number of
     * contact options.
     */
    disableAddContactButton = () => this.mapFunctionToContactObjectsShallowCopyArray(contact =>
        // Ensuring that no fields have empty values
        Object.entries(contact.selectedOption).length === 0) ||
        // If the number of contactObjects is equal to the number of contact options,
        // no more contactObjects can be created
        (this.state.contactObjectsShallowCopy.length === this.state.contactOptions.length);

    /**
     * This function takes a mapped function and applied it to every element of the contactObjects array
     */
    mapFunctionToContactObjectsShallowCopyArray = mappedFunction =>
        this.state.contactObjectsShallowCopy.some(mappedFunction);

    /**
     * This function takes a mapped function and applied it to every element of the escalationPathObjects array
     */
    mapFunctionToEscalationPathObjectsShallowCopyArray = mappedFunction =>
        this.state.escalationPathObjectsShallowCopy.some(mappedFunction);

    refreshAttachments = async () => {
        await this.fetchProviderServiceInfo();
        await this.loadAttachments();
    }

    /**
     * Send updated providerService info to backend when the submit button is clicked
     */
    handleUpdateProviderServiceSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isUpdateProviderServiceInfoInProgress: true });
        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.providerServiceErrorTexts).some(errorText => errorText) ||
            this.mapFunctionToContactObjectsShallowCopyArray(contactObject => contactObject.errorText) ||
            this.mapFunctionToEscalationPathObjectsShallowCopyArray(escalationPathObject =>
                escalationPathObject.contactNameErrorText || escalationPathObject.levelErrorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasUpdateProviderServiceSubmittedOnce: true,
                    isUpdateProviderServiceInfoInProgress: false
                }
            );
            return;
        }
        const updatedProviderService = HelperFunctions.deepClone(this.state.updatedProviderService);

        // Clearing out the escalationPath field so that a newly chosen escalationPath can be added
        Object.keys(updatedProviderService.escalationPath).map(escalationPathObject =>
            delete updatedProviderService.escalationPath[escalationPathObject]);

        // Filters out any blank entries by removing any escalationPathObjects that have either blank contact name
        // or a blank level field. Then creates appropriate map object to pass to the back end
        this.state.escalationPathObjectsShallowCopy.filter(escalationPathObject =>
            Object.entries(escalationPathObject.selectedOption).length > 0 && escalationPathObject.level !== "")
            .map(escalationPathObject => Object.assign(updatedProviderService.escalationPath,
                { [escalationPathObject.selectedOption.value]: escalationPathObject.level }));

        const escalationPriority = {};
        this.state.escalationPathObjectsShallowCopy.filter(escalationPathObject =>
            Object.entries(escalationPathObject.selectedOption).length > 0
                && escalationPathObject.priority !== "")
            .map(escalationPathObject => Object.assign(escalationPriority,
                { [escalationPathObject.selectedOption.value]: escalationPathObject.priority }));

        updatedProviderService.escalationPriority = escalationPriority;

        // Filters out any blank contacts and returns the all the contactIds to the contactIdList
        updatedProviderService.contactIdList = this.state.contactObjectsShallowCopy
            .filter(contactObject => Object.entries(contactObject.selectedOption).length > 0)
            .map(contactObject => contactObject.selectedOption.value);

        try {
            const response = await this.FremontBackendClient.updateProviderServiceInfo(updatedProviderService,
                this.props.auth);

            // Resets all input fields to original state if request is successful
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                escalationPathObjects: this.createEscalationPathObjects(response.escalationPath,
                    this.state.contactIdToNameMap, response.escalationPrioriy),
                contactObjects: this.createContactObjects(response.contactIdList, this.state.contactIdToNameMap),
                isUpdateProviderServiceInfoEditClicked: false,
                isUpdateProviderServiceInfoInProgress: false,
                hasUpdateProviderServiceSubmittedOnce: false,
                providerService: response,
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                hasUpdateProviderServiceSubmittedOnce: false,
                isUpdateProviderServiceInfoInProgress: false
            });
        }
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     **************************************************************************************************
     * TAB CHANGE HANDLERS
     **************************************************************************************************
     */

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        }
    };

    /**
     This function updates the tabs of the provider details page
     */
    updateTabs = () => (
        [
            {
                label: "Details",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_INFO_TAB_ID,
                content: <ProviderServiceInformation
                    providerService={this.state.providerService}
                    updatedProviderService={this.state.updatedProviderService}
                    isUpdateProviderServiceInfoEditClicked={this.state.isUpdateProviderServiceInfoEditClicked}
                    isUpdateProviderServiceInfoInProgress={this.state.isUpdateProviderServiceInfoInProgress}
                    providerServiceErrorTexts={this.state.hasUpdateProviderServiceSubmittedOnce ?
                        this.state.providerServiceErrorTexts
                        : ProviderServiceValidation.EMPTY_PROVIDER_SERVICE_ERROR_TEXTS}
                    hasUpdateProviderServiceSubmittedOnce={this.state.hasUpdateProviderServiceSubmittedOnce}
                    handleUpdateProviderServiceEdit={this.handleUpdateProviderServiceEdit}
                    handleUpdateProviderServiceInputChange={this.handleUpdateProviderServiceInputChange}
                    handleUpdateProviderServiceSubmit={this.handleUpdateProviderServiceSubmit}
                    addEscalationPathButtonDisabled={this.disableAddEscalationPathButton()}
                    addContactButtonDisabled={this.disableAddContactButton()}
                    handleAdditionalInput={this.handleAdditionalInput}
                    handleSubtractSpecificObject={this.handleSubtractSpecificObject}
                    escalationPathObjects={this.state.escalationPathObjects}
                    escalationPathObjectsShallowCopy={this.state.escalationPathObjectsShallowCopy}
                    escalationPathItems={this.getEscalationPathItemsForTable()}
                    contactsLoading={this.state.contactsLoading}
                    contactOptions={this.state.contactOptions}
                    contactObjectsShallowCopy={this.state.contactObjectsShallowCopy}
                />
            },
            {
                label: "Service Contacts",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_CONTACT_TAB_ID,
                content: <ContactTab
                    providerName={this.state.providerService.providerName}
                    contactIdList={this.state.providerService.contactIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No contacts currently exist for this service.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any contacts for this service.</Box>
                    }
                />
            },
            {
                label: "Service ASNs",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_ASN_TAB_ID,
                content: <AsnTab
                    providerName={this.state.providerService.providerName}
                    asnIdList={this.state.providerService.asnIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No ASNs currently exist for this service.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any ASNs for this service.</Box>}
                />
            },
            {
                label: "Service Orders",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_ORDER_TAB_ID,
                content: <OrderTab
                    providerName={this.state.providerService.providerName}
                    orderIdList={this.state.providerService.orderIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    searchTitle="Search in Service Orders"
                    emptyTableMessage={
                        <Box variant="h2">No orders currently exist for this service.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any orders for this service.</Box>}
                />
            },
            {
                label: "Service Notes",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_NOTE_TAB_ID,
                content: <NoteTab
                    tableName="providerService"
                    type="service"
                    entityId={this.state.providerService.providerServiceId}
                    name={this.state.providerService.serviceType}
                    noteIdList={this.state.providerService.noteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Attachments",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_ATTACHMENT_TAB_ID,
                content: <AttachmentTab
                    auth={this.props.auth}
                    orderAttachments={this.state.providerServiceAttachments}
                    orderAttachmentsLoading={this.state.isPageLoading}
                    orderId={this.state.providerService.providerServiceId}
                    parentEntityIdList={[this.state.providerService.providerServiceId]}
                    downloadAttachment={this.downloadAttachment}
                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    orderCompleted={false}
                    disableAddAttachmentsButton={false}
                    noAttachmentsMessage={Constants.EMPTY_TABLE_MESSAGES.noProviderServiceAttachments}
                    entityType={Constants.PROVIDER_SERVICE_ENTITY_TYPE}
                    attachmentOptions={Constants.VALID_PROVIDER_SERVICE_ATTACHMENT_TYPES}
                    loadData={this.refreshAttachments}
                />
            },
            {
                label: "Audit Trail",
                id: ProviderServiceDetailsPage.PROVIDER_SERVICE_AUDIT_TAB_ID,
                content: <AuditTab
                    type="service"
                    auditIdList={this.state.providerService.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            }
        ]
    );

    extractAttachmentIdsFromMap = attachmentIdMap => Object.values(attachmentIdMap)
        .reduce((prev, current) => prev.concat(current), []);

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            (
                <div>
                    <FremontHeader
                        history={this.props.history}
                        flashbarText={this.state.flashbar.text}
                        flashbarType={this.state.flashbar.type}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                        <Box variant="h1">
                            {this.state.providerService.providerName
                                .charAt(this.state.providerService.providerName.length - 1)
                                .toLowerCase() === "s"
                                ? [
                                    `${this.state.providerService.providerName}'`,
                                    `${this.state.providerService.serviceType} Service`
                                ].join(" ")
                                : [
                                    `${this.state.providerService.providerName}'s`,
                                    `${this.state.providerService.serviceType} Service`
                                ].join(" ")
                            }
                        </Box>
                        <Tabs
                            tabs={this.updateTabs()}
                            activeTabId={this.state.activeTabId}
                            variant="default"
                            onChange={this.handleTabChange}
                        />
                    </div>
                </div>
            )
        );
    }
}

export default withRouter(ProviderServiceDetailsPage);