import React, { Component } from "react";
import {
    NewOrderInfoStageDisplayMode,
    NewOrderInfoStageEditMode
} from "order/stages/install/NewOrderInformationInformation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

export default class NewOrderInformationHandler extends Component {
    state={
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        updatedOrder: this.props.order,
        errorTexts: OrderValidation.BUSINESS_DEVELOPER_NEW_ORDER_INFO_ERROR_TEXTS,
        isUpdateInProgress: false
    };

    FremontBackendClient = new FremontBackendClient();

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            hasBeenSubmittedOnce: false,
            updatedOrder: HelperFunctions.deepClone(this.props.order),
            errorTexts: OrderValidation.BUSINESS_DEVELOPER_NEW_ORDER_INFO_ERROR_TEXTS,
            isUpdateInProgress: false
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = this.state.errorTexts;

        const output = OrderValidation.validateInput(input);

        this.setState({
            updatedOrder: output.order,
            errorTexts: output.orderErrorTexts
        });
    };

    handleStageSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isUpdateInProgress: true });
        if (Object.values(this.state.errorTexts).some(errorText => !!errorText)) {
            this.props.handleFlashBarMessagesFromChildTabs(
                false,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                false
            );
            this.setState({ isUpdateInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        try {
            await this.FremontBackendClient.updateOrderInfo(this.state.updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Resets all input fields to original state if request is successful
            this.setState({
                isEditClicked: false,
                isUpdateInProgress: false,
                hasBeenSubmittedOnce: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isEditClicked: false,
                isUpdateInProgress: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <NewOrderInfoStageDisplayMode
                    stageName={Constants.STAGE_NAMES.newOrderInformation}
                    order={this.props.order}
                    asn={this.props.asn}
                    asnLoading={this.props.asnLoading}
                    orderCompleted={this.props.orderCompleted}
                    handleStageEditClick={this.handleStageEditClick}
                    editButtonsDisabled={this.props.editButtonsDisabled}
                    goToComponentAction={this.props.goToComponentAction}
                />
                :
                <NewOrderInfoStageEditMode
                    stageName={Constants.STAGE_NAMES.newOrderInformation}
                    asn={this.props.asn}
                    asnLoading={this.props.asnLoading}
                    updatedOrder={this.state.updatedOrder}
                    disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                        this.props.order.stageStatusMap)}
                    hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                    isUpdateInProgress={this.state.isUpdateInProgress}
                    errorTexts={this.state.errorTexts}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageInputChange={this.handleStageInputChange}
                    handleStageSubmit={this.handleStageSubmit}
                />
        );
    }
}