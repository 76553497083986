import React from "react";
import {
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import { ComponentConstants } from "utils/CommonComponents";

export default class ContactValidation {
    static DEFAULT_CONTACT_ERROR_TEXTS = {
        firstName: Constants.ERROR_STRINGS.blankInput,
        lastName: Constants.ERROR_STRINGS.blankInput,
        role: Constants.ERROR_STRINGS.blankInput,
        title: "",
        email: Constants.ERROR_STRINGS.blankInput,
        billingContact: "",
        internal: "",
        status: "",
        salutation: "",
        description: "",
        team: "",
        contactType: "",
        department: "",
        level: "",
        addressLineOne: "",
        addressLineTwo: "",
        addressLineCity: "",
        addressLineState: "",
        addressLineZipCode: "",
        addressLineCountry: "",
        providerNameList: Constants.ERROR_STRINGS.blankProviderErrorText,
        providerServiceIdList: "",
        homePhone: "",
        cellPhone: "",
        workPhone: "",
        assistantName: "",
        assistantPhone: "",
        notes: ""
    };

    static EMPTY_CONTACT_ERROR_TEXTS = {
        firstName: "",
        lastName: "",
        role: "",
        title: "",
        email: "",
        billingContact: "",
        internal: "",
        status: "",
        salutation: "",
        description: "",
        team: "",
        contactType: "",
        department: "",
        level: "",
        addressLineOne: "",
        addressLineTwo: "",
        addressLineCity: "",
        addressLineState: "",
        addressLineZipCode: "",
        addressLineCountry: "",
        providerNameList: "",
        providerServiceIdList: "",
        homePhone: "",
        cellPhone: "",
        workPhone: "",
        assistantName: "",
        assistantPhone: "",
        notes: ""
    };

    static CONTACT_SALUTATION_OPTIONS = [
        {
            label: "None",
            value: ""
        },
        {
            label: "Mr.",
            value: "Mr."
        },
        {
            label: "Ms.",
            value: "Ms."
        },
        {
            label: "Dr.",
            value: "Dr."
        },
        {
            label: "Mrs.",
            value: "Mrs."
        },
        {
            label: "Mx.",
            value: "Mx."
        }
    ];

    static CONTACT_ROLE_OPTIONS = [
        {
            label: "Sales",
            value: "Sales"
        },
        {
            label: "NOC/Operations",
            value: "NOC/Operations"
        },
        {
            label: "Billing",
            value: "Billing"
        },
        {
            label: "Escalations",
            value: "Escalations"
        },
        {
            label: "Service Deployment",
            value: "Service Deployment"
        },
        {
            label: "Account Management",
            value: "Account Management"
        },
        {
            label: "Technical Support",
            value: "Technical Support"
        },
        {
            label: "Business Development",
            value: "Business Development"
        },
        {
            label: "Product Management",
            value: "Product Management"
        }
    ];

    static CONTACT_TYPE_OPTIONS = [
        {
            label: "None",
            value: ""
        },
        {
            label: "Account Manager",
            value: "Account Manager"
        },
        {
            label: "AWS Sales",
            value: "AWS Sales"
        },
        {
            label: "BizDev",
            value: "BizDev"
        },
        {
            label: "BizDev - Management",
            value: "BizDev - Management"
        },
        {
            label: "Business Analyst",
            value: "Business Analyst"
        },
        {
            label: "Client Services",
            value: "Client Services"
        },
        {
            label: "Corporate",
            value: "Corporate"
        },
        {
            label: "DCEO",
            value: "DCEO"
        },
        {
            label: "DCEO Colo Manager",
            value: "DCEO Colo Manager"
        },
        {
            label: "DCEO Manager",
            value: "DCEO Manager"
        },
        {
            label: "DCO",
            value: "DCO"
        },
        {
            label: "DCO Manager",
            value: "DCO Manager"
        },
        {
            label: "General Manager",
            value: "General Manager"
        },
        {
            label: "Legal",
            value: "Legal"
        },
        {
            label: "Logistics",
            value: "Logistics"
        },
        {
            label: "Network",
            value: "Network"
        },
        {
            label: "Operations",
            value: "Operations"
        },
        {
            label: "Performance Manager",
            value: "Performance Manager"
        },
        {
            label: "Regional Manager",
            value: "Regional Manager"
        },
        {
            label: "Risk",
            value: "Risk"
        },
        {
            label: "RSM",
            value: "RSM"
        },
        {
            label: "Safety",
            value: "Safety"
        },
        {
            label: "Sales",
            value: "Sales"
        },
        {
            label: "Security",
            value: "Security"
        },
        {
            label: "SOC",
            value: "SOC"
        },
        {
            label: "Technical",
            value: "Technical"
        },
        {
            label: "TPM",
            value: "TPM"
        },
        {
            label: "Systems & Network BizDev",
            value: "Systems & Network BizDev"
        },
        {
            label: "Systems & Network",
            value: "Systems & Network"
        },
        {
            label: "BizDev (EM)",
            value: "BizDev (EM)"
        },
        {
            label: "Datacenter Engineering",
            value: "Datacenter Engineering"
        },
        {
            label: "BizDev (Edge)",
            value: "BizDev (Edge)"
        },
        {
            label: "Cluster Security Manager",
            value: "Cluster Security Manager"
        },
        {
            label: "Regional Security Manager",
            value: "Regional Security Manager"
        },
        {
            label: "Cabling Infrastructure",
            value: "Cabling Infrastructure"
        },
        {
            label: "NSI",
            value: "NSI"
        },
        {
            label: "Energy",
            value: "Energy"
        },
        {
            label: "Account Manager",
            value: "Account Manager"
        },
        {
            label: "Billing",
            value: "Billing"
        },
        {
            label: "Escalations",
            value: "Escalations"
        },
        {
            label: "Service Delivery",
            value: "Service Delivery"
        },
        {
            label: "Service Manager",
            value: "Service Manager"
        },
        {
            label: "Technical Support",
            value: "Technical Support"
        }
    ];

    static SITE_COLUMN_DEFINITIONS = [
        {
            id: "providerName",
            header: "Provider Name",
            description: "Name of provider",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "regionsSupported",
            header: () => (
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    Regions Supported
                    {HelperFunctions.renderCustomTooltip(
                        Constants.TOOLTIP_LINKS.regionsSupported,
                        Constants.TOOLTIP_STRINGS.regionsSupported
                    )}
                </SpaceBetween>
            ),
            description: "Regions supported for this provider",
            cell: item => item[Constants.ATTRIBUTES.regionsSupported]
        },
        {
            id: "countriesSupported",
            header: () => (
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    Countries Supported
                    {HelperFunctions.renderCustomTooltip(
                        Constants.TOOLTIP_LINKS.countriesSupported,
                        Constants.TOOLTIP_STRINGS.countriesSupported
                    )}
                </SpaceBetween>
            ),
            description: "Countries supported for this provider",
            cell: item => item[Constants.ATTRIBUTES.countriesSupported]
        }
    ];

    static SERVICE_COLUMN_DEFINITIONS = [
        {
            id: "providerName",
            header: "Provider Name",
            description: "Name of the provider this service is attached to",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "serviceType",
            header: "Service Type",
            description: "The service provided by this particular provider service",
            cell: item => <Link href={`${Constants.ROUTES.providerService}/${item.providerServiceId}`}>{item.serviceType}</Link>
        },
        {
            id: "status",
            header: "Service Status",
            description: "Provider Service Status",
            cell: item => item.status
        }
    ];

    static validateInput = (input) => {
        const inputtedData = input;
        // Here we fetch the input
        const attributeId = inputtedData.evt.target.id;
        let inputValue;

        if (attributeId.includes(Constants.ATTRIBUTES.regionsSupported) ||
            attributeId.includes(Constants.ATTRIBUTES.countriesSupported)) {
            const splitString = inputtedData.evt.target.id.split(":");
            inputtedData.contact[Constants.ATTRIBUTES.siteMap][splitString[Constants.MAP_INDEXES.valueIndex]][
                splitString[Constants.MAP_INDEXES.keyIndex]] = inputtedData.evt.detail.selectedOptions
                .map(option => option.label);
            return inputtedData;
        }
        if (["role", "salutation", "status", "contactType", "level"].includes(attributeId)) {
            inputValue = inputtedData.evt.detail.selectedOption.value;
        } else if (attributeId === "billingContact") {
            inputValue = inputtedData.evt.detail.selectedOption.value;
        } else {
            inputValue = inputtedData.evt.detail.value;
        }
        // do not allow empty strings because this will cause an error on the backend
        // the exemptions are all being set somewhere else
        if (!inputValue && !(attributeId === "billingContact")) {
            delete inputtedData.contact[attributeId];
        } else {
            inputtedData.contact[attributeId] = inputValue;
        }

        // required fields cannot be blank
        if (attributeId === "firstName" || attributeId === "lastName" ||
            attributeId === "role" || attributeId === "email") {
            if (!inputValue) {
                inputtedData.contactErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else if (attributeId === "firstName" || attributeId === "lastName" || attributeId === "title") {
                inputtedData.contactErrorTexts[attributeId] = HelperFunctions.validateLettersAndSpaces(inputValue);
            } else if (attributeId === "email") {
                inputtedData.contactErrorTexts[attributeId] = HelperFunctions.validateEmail(inputValue);
            } else {
                inputtedData.contactErrorTexts[attributeId] = "";
            }
        } else if (!inputValue) {
            // optional fields can be blank, but otherwise must be validated
            inputtedData.contactErrorTexts[attributeId] = "";
        } else if (attributeId === "homePhone" || attributeId === "cellPhone" ||
            attributeId === "workPhone" || attributeId === "assistantPhone") {
            inputtedData.contactErrorTexts[attributeId] =
                HelperFunctions.validatePhoneOrFaxNumber(inputValue, Constants.PHONE_OR_FAX.phone);
        } else {
            inputtedData.contactErrorTexts[attributeId] = "";
        }
        return inputtedData;
    };

    static contactInputChange = (evt, contactParam, contactErrorTextsParam) => {
        const input = {};
        input.evt = evt;
        input.contact = HelperFunctions.deepClone(contactParam);
        input.contactErrorTexts = HelperFunctions.deepClone(contactErrorTextsParam);
        return ContactValidation.validateInput(input);
    };

    static serviceOptionsChange = (evt, prevServiceOptions) => {
        const providerName = evt.target.id;
        const serviceOptions = HelperFunctions.deepClone(prevServiceOptions);
        serviceOptions[providerName].selectedOptions = evt.detail.selectedOptions;
        return serviceOptions;
    };

    static setupServiceOptions = async (provider, auth, backend) => {
        const serviceOption = {};
        serviceOption.id = provider.providerName;
        serviceOption.value = provider.providerName;
        // Get the associated provider service objects
        const providerServiceResponse = await backend.getBatch(
            Constants.BATCH_ENTITIES.PROVIDER_SERVICE, provider.providerServiceIdList, auth
        );

        serviceOption.options = providerServiceResponse.providerServices.map(
            providerService => ({
                label: providerService.serviceType,
                value: providerService.providerServiceId
            })
        );
        serviceOption.selectedOptions = [];
        return serviceOption;
    };

    static createContactRequest = (contact, serviceOptions) => {
        const contactToSubmit = HelperFunctions.deepClone(contact);
        // create list of services to pass to backend
        const serviceSet = new Set();
        contactToSubmit.providerNameList
            .forEach(providerName => serviceOptions[providerName].selectedOptions
                .forEach(option => serviceSet.add(option.value)));
        contactToSubmit.providerServiceIdList = Array.from(serviceSet);
        return contactToSubmit;
    }
}
