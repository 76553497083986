import Constants from "../utils/Constants";
import HelperFunctions from "../common/HelperFunctions";

export default class LinkHierarchyHelper {
    static getEmptyLinkMap = () => Object.assign(
        {},
        ...Object.keys(HelperFunctions.getLinkHierarchyOrder())
            .map(linkType => ({
                [LinkHierarchyHelper.getReadableLinkTypeFromTypeIdAndStage(linkType)]: []
            }))
    );

    static fetchLinkHierarchy = async (
        linkServiceBackendClient, linksInLinkTypeMap, link
    ) => {
        const linkHierarchyResponse = await linkServiceBackendClient.getLinkHierarchy(
            Constants.LINK_INSTANCE_ID_PATTERN + link.instanceId
        );

        LinkHierarchyHelper.addLinksToLinkTypeMap(linkHierarchyResponse.Links, linksInLinkTypeMap);

        const routerToRouterLinksWithClientPorts = await LinkHierarchyHelper.addClientPortsToRouterToRouterLinks(
            linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter],
            linksInLinkTypeMap[Constants.LINK_TYPES.routerToDWDM]
        );

        return {
            ...linksInLinkTypeMap,
            [Constants.LINK_TYPES.routerToRouter]: routerToRouterLinksWithClientPorts
        };
    }

    static addClientPortsToRouterToRouterLinks = (routerToRouterLinks, routerToDWDMLinks) =>
        routerToRouterLinks.map((link) => {
            const consumesLinkIds = LinkHierarchyHelper.getConsumesLinkIds(link);
            const consumesLinks = routerToDWDMLinks.filter(lnk => consumesLinkIds.includes(lnk.instanceId));

            const [aEndDwdmLinkId, aEndClientPort] =
                    LinkHierarchyHelper.getRouterToDwdmInfo(link.aEndPort, consumesLinks);
            const [bEndDwdmLinkId, bEndClientPort] =
                    LinkHierarchyHelper.getRouterToDwdmInfo(link.bEndPort, consumesLinks);

            return {
                ...link,
                aEndDwdmLinkId,
                aEndClientPort,
                bEndDwdmLinkId,
                bEndClientPort
            };
        });

    static getRouterToDwdmInfo = (fabricInterface, consumesLinks) => {
        const matchedLinkFromAEnd = consumesLinks.find(link => link.aEndPort === fabricInterface);
        if (matchedLinkFromAEnd) {
            return [matchedLinkFromAEnd.instanceId, matchedLinkFromAEnd.bEndPort];
        }

        const matchedLinkFromBEnd = consumesLinks.find(link => link.bEndPort === fabricInterface);

        return !matchedLinkFromBEnd ?
            [null, null] :
            [matchedLinkFromBEnd.instanceId, matchedLinkFromBEnd.aEndPort];
    };

    // TODO add empty links should display error button
    static addLinksToLinkTypeMap = (linksToAdd, linksInLinkTypeMap) => {
        linksToAdd.forEach((link) => {
            if (!linksInLinkTypeMap[link.readableLinkType]) {
                Object.assign(linksInLinkTypeMap, { [link.readableLinkType]: [] });
            }
            // Only insert the link if it does not already exist in the list
            if (!linksInLinkTypeMap[link.readableLinkType].some(
                existingLink => existingLink.instanceId === link.instanceId
            )) {
                linksInLinkTypeMap[link.readableLinkType].push(link);
            }
        });
    }

    static getConsumesLinkIds = link =>
        LinkHierarchyHelper.parseLinkIdsFromAttribute(link, Constants.CONSUMPTION_ATTRIBUTES.consumesList)

    static parseLinkIdsFromAttribute = (link, attributeKeyToFind) => {
        const ids = [];
        const matchingAttribute = link.attributesToDisplay.find(attribute =>
            attribute.key === attributeKeyToFind);
        if (matchingAttribute) {
            const links = JSON.parse(matchingAttribute.value)
                .map(linkId => HelperFunctions.getIdentifierFromLinkInstance(linkId));
            ids.push(...links);
        }

        // Ensure there are no duplicates
        return HelperFunctions.unique(ids);
    }

    static getReadableLinkTypeFromTypeIdAndStage = (linkType) => {
        let environmentKey = Constants.STAGES.alpha;
        if (HelperFunctions.isDevelopmentStack()) {
            environmentKey = Constants.STAGES.alpha;
        } else if (HelperFunctions.isGamma()) {
            environmentKey = Constants.STAGES.gamma;
        } else if (HelperFunctions.isProd()) {
            environmentKey = Constants.STAGES.prod;
        }

        // Return the readable link type or the ID if we do not have a value in the hardcoded map
        return Constants.READABLE_LINK_TYPE_MAP[environmentKey][linkType] || linkType;
    }

    static getTypeIdFromLink(linkType) {
        let environmentKey;
        if (HelperFunctions.isDevelopmentStack()) {
            environmentKey = Constants.STAGES.alpha;
        } else if (HelperFunctions.isGamma()) {
            environmentKey = Constants.STAGES.gamma;
        } else if (HelperFunctions.isProd()) {
            environmentKey = Constants.STAGES.prod;
        } else {
            environmentKey = Constants.STAGES.alpha; // Default to alpha if no match
        }

        const environmentMap = Constants.READABLE_LINK_TYPE_MAP[environmentKey] || {};
        const flippedMap = Object.entries(environmentMap).reduce((acc, [key, value]) => {
            acc[value] = key;
            return acc;
        }, {});

        return flippedMap[linkType] || linkType;
    }
}