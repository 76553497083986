export default class Constants {
    static FREMONT_PAGE_WIDTH_CLASS = "fremont-page-width";
    static LINK_INSTANCE_ID_PATTERN = "amzn1.network.link.instance.";
    static SEPARATOR = "___";
    static FLASHBAR_TYPES = {
        success: "success",
        error: "error",
        info: "info",
        warning: "warning"
    };

    static LIGHTHOUSE_ROUTES = {
        ispPatchPanelManagement: "/patchpanels"
    }

    // Paddings for LightHouse
    static PADDING_SIZES = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_SECTIONS: "m",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static COMPONENT_CONSTANTS = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static PATCH_PANEL_CONFIG_FIELDS = {
        ID: "PanelId",
        LOCATION_A: "PatchPanelLocationA",
        LOCATION_Z: "PatchPanelLocationZ",
        PORT_COUNT: "PortCount",
        STARTING_PORT_A: "StartingPortLocationA",
        STARTING_PORT_Z: "StartingPortLocationZ",
        BYPASS_VALIDATIONS: "BypassValidations"
    };

    static ATTRIBUTES_TYPES = {
        boolean: "Boolean",
        integer: "Integer",
        string: "String"
    }

    static CONSUMPTION_ATTRIBUTES = {
        consumesList: "consumes",
        consumerList: "consumers"
    };

    static ISP_CUTSHEET_TYPE = {
        LINE: "LINE",
        CLIENT: "CLIENT"
    }

    static ISP_TABLE_PRIMARY_KEYS = {
        CLIENT: "router_to_router_link_instance_id",
        LINE: "mux_to_mux_link_instance_id"
    }

    static ISP_ATTRIBUTES = {
        a_lever_location: "a_lever_location",
        z_lever_location: "z_lever_location",
        a_lever_hostname: "a_lever_hostname",
        z_lever_hostname: "z_lever_hostname",
        a_location: "a_location",
        z_location: "z_location",
        a_line_dwdm_device_location: "a_line_dwdm_device_location",
        z_line_dwdm_device_location: "z_line_dwdm_device_location",
        a_pluggable: "a_pluggable",
        z_pluggable: "z_pluggable",
        a_trunk_lever_optic_type: "a_trunk_lever_optic_type",
        z_trunk_lever_optic_type: "z_trunk_lever_optic_type",
        a_osp_panel_location: "a_osp_panel_location",
        z_osp_panel_location: "z_osp_panel_location",
        a_osp_panel_ports: "a_osp_panel_ports",
        z_osp_panel_ports: "z_osp_panel_ports"
    }

    static PORT_VALUES_TYPES = {
        SINGLE: "single",
        PAIR: "pair"
    }
}