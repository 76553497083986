import React from "react";
import {
    Alert,
    Box,
    Container,
    ColumnLayout,
    FormField,
    Header,
    Link,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react/polaris";
import {
    FremontAlert,
    FremontButton,
    FremontExpandableSection,
    FremontInput,
    FremontSelect,
    requiredLabel,
    useCollectionDefault,
    ComponentConstants
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const generateSubmitForApprovalColumnDefinitions = (serviceType, customerFabric, providerName) => {
    let columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.submitForApproval], item.blockers
            )
        },
        {
            id: "loaA",
            header: "LOA A",
            description: "Link the LOA A file for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdA]
                ? <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdA])}
                />
                : "")
        }
    ];

    if ((Constants.SERVICE_TYPES.BACKBONE === serviceType && Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)
            && Constants.INTERNAL_AMAZON_PROVIDER !== providerName)
            || Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
        columnDefinitions.push({
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: "Provider Circuit ID",
            description: "Provider circuit of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.COMPONENT_NAMES.providerCircuitA] :
                    <FremontInput
                        id={`${Constants.COMPONENT_NAMES.providerCircuitA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[Constants.COMPONENT_NAMES.providerCircuitA]}
                        onInput={item.handleStageInputChange}
                        disabled={item.isUpdateStageInProgress}
                    />
            )
        });
    }

    if (Constants.SERVICE_TYPES.BACKBONE === serviceType) {
        columnDefinitions.push({
            id: "loaZ",
            header: "LOA Z",
            description: "Link the LOA Z file for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdZ]
                ? <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdZ])}
                />
                : "")
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Bandwidth for this circuitDesign",
            width: Constants.COLUMN_WIDTHS.bandwidth,
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth]
        },
        {
            id: "completionNotice",
            header: "Completion Notice",
            description: "Link the completion notice file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.completionNoticeId]
                ? <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.completionNoticeId])}
                />
                : "")
        });

        // If we're looking at Backbone Span customer fabric, we want to show the testing fields too
        if (Constants.CUSTOMER_FABRICS.BACKBONE_SPAN === customerFabric) {
            columnDefinitions.push({
                id: "RFC",
                header: "RFC Testing",
                description: "Link the RFC Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.RFCAttachmentId] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.RFCAttachmentId])}
                />)
            },
            {
                id: "BERT",
                header: "BERT Testing",
                description: "Link the BERT Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.BERTAttachmentId] &&
                    <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.BERTAttachmentId])}
                    />)
            });
        }

        columnDefinitions.push(
            {
                id: "kmz",
                header: "KMZ",
                description: "Link the KMZ file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.kmzAttachmentId]
                    ? <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.kmzAttachmentId])}
                    />
                    : "")
            }
        );
    }
    if (Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        // Remove loaA, loaZ and completionNotice for Paths. SIM 3247.
        columnDefinitions = columnDefinitions
            .filter(column => !["loaA", "loaZ", "completionNotice"].includes(column.id));
    }
    return columnDefinitions;
};

/**
 * We show the rejection notice only if we are in the Submit for Approval stage
 * with a previous rejection
 *
 * @param {*} props
 * @returns
 */
const renderRejectionNotice = ({
    latestRejectionNote,
    stageName,
    order
}) => (
    latestRejectionNote
    && HelperFunctions.isStageInProgress(order.stageStatusMap[stageName]) ?
        <FremontAlert
            type="warning"
            header={`Order rejected by ${latestRejectionNote.createdBy} at ${HelperFunctions.formatDateAndTime(latestRejectionNote.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT)}:`}
        >
            <span className="pre-wrap">{latestRejectionNote.noteBody}</span>
        </FremontAlert> : ""
);

export const SubmitStageDisplayMode = (props) => {
    const { items, collectionProps } = useCollectionDefault(props.circuitItems);

    return (
        <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
            <Container
                header={
                    <StageHeader
                        order={props.order}
                        circuitItems={props.circuitItems}
                        stageName={props.stageName}
                        showAttachmentModal={
                            Constants.ORDER_TYPES.DECOMMISSION !== props.order.orderType
                            && props.showAttachmentModal
                        }
                        disableAttachmentButton={props.circuitItems.length === 0
                        || props.orderCompleted || props.editButtonsDisabled}
                        disableEditButton={
                            props.orderCompleted || props.editButtonsDisabled || props.circuitDesignsLoading
                        }
                        handleStageEditClick={
                            Constants.ORDER_TYPES.DECOMMISSION !== props.order.orderType
                            && props.handleStageEditClick
                        }
                        disableSubmitForApproval={props.disableSubmitForApproval || props.editButtonsDisabled
                        || (props.order.requiredFieldsCompletedMap[
                            Constants.STAGE_NAMES.submitForApproval]
                            !== Constants.HAS_NOT_BEEN_SUBMITTED_FOR_APPROVAL
                            && props.order.requiredFieldsCompletedMap[Constants.STAGE_NAMES.submitForApproval]
                            !== Constants.HAS_NOT_BEEN_SUBMITTED_FOR_APPROVAL_OR_APPROVED
                        )}
                        loadingSubmitForApproval={props.isUpdateStageInProgress}
                        handleStageSubmitForApproval={props.handleStageSubmitForApproval}
                        goToComponentAction={props.goToComponentAction}
                    />
                }
            >
                { renderRejectionNotice(props) }
                <div>
                    {HelperFunctions.isOrderPathOrder(props.order) &&
                    <Box margin={{ bottom: "xs" }}>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">
                            <Box variant="span" margin={{ right: "xxs" }}>
                                Path Name:
                            </Box>
                            {HelperFunctions.circuitsHaveMultiplePaths(props.circuitItems) &&
                            HelperFunctions.renderCustomTooltip(null,
                                Constants.TOOLTIP_STRINGS.pathNameFieldExplanation)
                            }
                        </Box>
                        <div>
                            { props.pathName ? props.pathName : "-" }
                        </div>
                    </Box>
                    }
                    <FremontExpandableSection
                        variant="container"
                        header={
                            <Header variant="h2">
                                Circuits
                            </Header>
                        }
                        defaultExpanded={!HelperFunctions.isStageCompleted(props.order
                            .stageStatusMap[props.stageName])}
                    >
                        <Table
                            {...collectionProps}
                            columnDefinitions={generateSubmitForApprovalColumnDefinitions(
                                props.order.serviceType,
                                props.order.customerFabric,
                                props.order.providerName
                            )}
                            loadingText="Loading circuit designs"
                            loading={props.circuitDesignsLoading}
                            items={items}
                            resizableColumns
                            wrapLines
                            empty={
                                <Box textAlign="center">
                                    <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                        <b>
                                            {Constants.EMPTY_TABLE_MESSAGES.noCircuits}
                                        </b>
                                    </Box>
                                </Box>
                            }
                        />
                    </FremontExpandableSection>
                </div>
            </Container>
        </div>
    );
};

export const SubmitStageEditMode = (props) => {
    const { items, collectionProps } = useCollectionDefault(props.circuitItems);
    return (
        <div className="fremont-stage-spacing">
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <FremontButton
                                    id={props.stageName}
                                    variant="normal"
                                    onClick={props.handleStageEditClick}
                                    disabled={props.isUpdateStageInProgress}
                                >
                                    Cancel
                                </FremontButton>
                                <FremontButton
                                    id={props.stageName}
                                    loading={props.isUpdateStageInProgress}
                                    variant="primary"
                                    onClick={props.handleStageSubmit}
                                >
                                    {props.isUpdateStageInProgress ? "Submitting..." : "Submit"}
                                </FremontButton>
                            </SpaceBetween>
                        }
                    >
                        Submit for Approval
                        {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                            .stageStatusMap[props.stageName])}
                    </Header>
                }
            >
                {/* We show the rejection notice only
             if we are in the Submit for Approval stage with a previous rejection */}
                {props.latestRejectionNote
                && HelperFunctions.isStageInProgress(props.updatedOrder.stageStatusMap[props.stageName]) ?
                    <FremontAlert
                        type="warning"
                        header={`Order rejected by ${props.latestRejectionNote.createdBy} at ${HelperFunctions.formatDateAndTime(props.latestRejectionNote.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT)}:`
                        }
                    >
                        <span className="pre-wrap">{props.latestRejectionNote.noteBody}</span>
                    </FremontAlert>
                    : ""}
                <div>
                    {HelperFunctions.isOrderPathOrder(props.updatedOrder) &&
                    <Box margin={{ bottom: "xs" }}>
                        <FormField
                            label={requiredLabel("Path Name:")}
                            errorText={props.errorTexts.pathName}
                        >
                            { HelperFunctions.isProd() &&
                                <FremontInput
                                    id={Constants.ATTRIBUTES.pathName}
                                    placeholder={props.isUpdateStageInProgress
                                    || props.disabledFieldsList.includes(Constants.ATTRIBUTES.pathId)
                                        ? "" : "Enter path name"}
                                    value={props.updatedOrder.pathName}
                                    disabled={props.isUpdateStageInProgress
                                        || props.disabledFieldsList.includes(Constants.ATTRIBUTES.pathId)}
                                    onInput={props.handleStageOrderInputChange}
                                />
                            }
                            {!HelperFunctions.isProd() &&
                                <ColumnLayout columns={2}>
                                    <SpaceBetween size="m">
                                        <FremontSelect
                                            id={Constants.ATTRIBUTES.pathName}
                                            placeholder="Select a path name"
                                            disabled={props.isUpdateStageInProgress
                                                || props.disabledFieldsList.includes(Constants.ATTRIBUTES.pathId)}
                                            options={props.validPaths
                                                .map(path => ({ label: path.path_name, value: String(path.id) }))}
                                            selectedOption={
                                                HelperFunctions.createSelectedOption(props.updatedOrder.pathName)
                                            }
                                            filteringType="auto"
                                            onChange={props.handleStageOrderInputChange}
                                            statusType={props.fetchingValidPaths ? Constants.LOADING
                                                : Constants.FINISHED}
                                            loadingText={Constants.STATUS_TEXTS.loadingValidPaths}
                                            empty="No paths found."
                                        />
                                        <Alert
                                            statusIconAriaLabel="Info"
                                            header="The list of valid path names is maintained by the Cinnamon service."
                                        />
                                    </SpaceBetween>
                                </ColumnLayout>
                            }
                        </FormField>
                    </Box>
                    }
                    <FremontExpandableSection
                        variant="container"
                        header={
                            <Header variant="h2">
                                Circuits
                            </Header>
                        }
                        defaultExpanded
                    >
                        <Table
                            {...collectionProps}
                            columnDefinitions={generateSubmitForApprovalColumnDefinitions(
                                props.updatedOrder.serviceType,
                                props.updatedOrder.customerFabric,
                                props.updatedOrder.providerName
                            )}
                            items={items}
                            resizableColumns
                            wrapLines
                            empty={
                                <Box textAlign="center">
                                    <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                        <b>
                                            {Constants.EMPTY_TABLE_MESSAGES.noCircuits}
                                        </b>
                                    </Box>
                                </Box>
                            }
                            trackBy="circuitDesignId"
                            selectionType="multi"
                            onSelectionChange={props.handleSelectedFromTable}
                            selectedItems={props.circuitItems.filter(
                                circuit => props.massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId)
                            )}
                        />
                    </FremontExpandableSection>
                </div>
            </Container>
        </div>
    );
};